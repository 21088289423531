import React from 'react';
import DynamicTableColumns from './components/DynamicTableColumns';
import { FilterOptions, FilterOrders } from '../../hooks/useFilters';
import MainTableWaitColumnConfig from "./components/MainTableWaitColumnConfig";
import { permissionsGroup } from '../priviledge/PriviledgeProvider';

export interface PaginationProps {
    pageCount: number,
    currentPage: number,
    pageSize: number,
    totalCount: number,
    handlePagination: Function,
    handlePerPage: Function,
}

interface CustomTableProps {
    id: string;
    data: any[];
    columnOrder?: string[];
    columnsNotShown?: string[];
    isLoading?: boolean;
    filters: FilterOptions;
    updateFilters: Function;
    updateFilterOrder: Function;
    defaultOrder: FilterOrders | undefined;
    pagination?: boolean;
    paginationData?: PaginationProps;
    overrideColumns?: { key: string; columnNameKey?: string | null; permissions?: { group: permissionsGroup; permission: string }[]; render: Function }[];
    additionalColumns?: { id: string; header: string; render: Function; enableSorting?: boolean }[];
    actions?: { label: string; permissions?: { group: permissionsGroup; permission: string }; render: Function }[];
    multiActions?: { label: string; permissions?: { group: permissionsGroup; permission: string }; render: Function }[];
    handleMultipleDelete?: Function;
    search?: boolean;
    filter?: boolean;
    toggleFilters?: Function;
    children?: React.ReactNode;
    largeTable?: boolean;
}

const CustomTable: React.FC<CustomTableProps> = (
    {
        id,
        data,
        columnOrder,
        columnsNotShown,
        isLoading,
        overrideColumns,
        additionalColumns,
        actions,
        multiActions,
        handleMultipleDelete,
        filters,
        updateFilters,
        updateFilterOrder,
        defaultOrder,
        pagination = true,
        paginationData,
        search = true,
        filter = true,
        toggleFilters,
        children,
        largeTable = false,
    }
) => {

    const { configuredColumns } = DynamicTableColumns({
        id,
        data,
        columnsNotShown,
        overrideColumns,
        additionalColumns,
        actions,
        columnOrder
    });

    return (
        <>
            {configuredColumns !== null && (
                <MainTableWaitColumnConfig
                    id={id}
                    data={data}
                    defaultColumnConfig={configuredColumns}
                    filters={filters}
                    multiActions={multiActions}
                    handleMultipleDelete={handleMultipleDelete}
                    isLoading={isLoading}
                    pagination={pagination}
                    paginationData={paginationData}
                    search={search}
                    defaultOrder={defaultOrder}
                    updateFilterOrder={updateFilterOrder}
                    updateFilters={updateFilters}
                    toggleFilters={toggleFilters}
                    filter={filter}
                    children={children}
                    largeTable={largeTable}
                />
            )}
        </>
    );
};

export default CustomTable;