import cn from "../../../utils/classNames";
import { Button, Collapse, Loader, Text, Tooltip } from "rizzui";
import { FaRegCalendar, FaRegClipboard } from "react-icons/fa";
import WidgetCard from "../../../components/card/WidgetCard";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { PiCaretDownBold } from "react-icons/pi";
import useClientConsultanciesHistory from "../../../hooks/api-calls/contract/useConsultanciesContracts";
import { menuRoutes } from "../../../router/menu";

interface ClientConsultancyHistoryProps {
    clientId: string;
    formik: any;
}
const ClientConsultancyHistory = ({ clientId, formik }: ClientConsultancyHistoryProps) => {

    const { updateClientId, getClientConsultanciesList, modifyLimitClientConsultancy, isMoreClientConsultancies, isLoadingClientConsultancies } = useClientConsultanciesHistory({ client: clientId, active: true },5);

    useEffect(() => {
        if (clientId) updateClientId(clientId);
    }, [clientId]);
    
    const [isLoading, setIsLoading] = useState(false);
    
    async function updateConsultancyFormData(consultancyId: string) {
        let consultancy = getClientConsultanciesList(consultancyId);
        formik.setFieldValue('consultancyId', consultancy[0]?.id);
        formik.setFieldValue('consultancyName', consultancy[0]?.title);
        formik.setFieldValue('consultancyDescription', consultancy[0]?.description);
        formik.setFieldValue('consultancyEmail', consultancy[0]?.email);
        toast.info('Asesoria copiada correctamente');
        await new Promise((resolve) => setTimeout(resolve, 500));
        setIsLoading(false);
    }

    function ConsultancyHistoryContent(arrayData: any) {
        return (
            <div className="space-y-2 p-0.5">
                {arrayData.map((activity: any) => (
                    <div
                        key={activity.id}
                        className="group cursor-pointer space-y-1 rounded-lg bg-gray-50 p-4 transition-shadow hover:shadow dark:bg-gray-100"
                    >
                        <div className="flex flex-col xl:flex-row flex-nowrap justify-between gap-8">
                            <div className="w-full">
                                <div className="flex items-center justify-between">
                                    <Text className="font-semibold group-hover:underline"
                                        onClick={() => window.open(`${menuRoutes.consultancies.path}?filter_filters=%7B"active"%3Atrue%2C"search_text"%3A"${activity.title}"%7D&filter_order=%5B%5D&page=1&limit=50`, '_blank')}
                                    >
                                        {activity.title}
                                    </Text>
                                    <div className="flex flex-row flex-nowrap items-center gap-2">
                                        <FaRegCalendar />
                                        <Text className="text-gray-400 text-nowrap">
                                            {activity.createdAt}
                                        </Text>
                                    </div>
                                </div>
                                <div className="flex items-center mt-2">
                                    <span className="text-gray-400">Email:&nbsp;</span>
                                    <Text className="ms-1.5 text-sm font-medium capitalize text-gray-700">{activity?.email ?? "N/A"}</Text>
                                </div>
                            </div>
                            <Tooltip size="sm" content="Copiar Asesoria" placement="top" color="invert">
                                <Button variant="outline"
                                    className={cn('m-auto bg-primary-500 text-white hover:text-white hover:bg-primary-700', isLoading && 'bg-gray-400')}
                                    disabled={isLoading}
                                    onClick={() => {
                                        setIsLoading(true);
                                        updateConsultancyFormData(activity.id);
                                    }}
                                >
                                    {!isLoading ? <FaRegClipboard /> : <Loader />}
                                </Button>
                            </Tooltip>

                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={cn("my-3")}>
            <Collapse
                className="py-3"
                defaultOpen={false}
                header={({ open, toggle }) => (
                    <button
                        type="button"
                        onClick={toggle}
                        className="flex w-full cursor-pointer items-center justify-between text-base font-semibold text-gray-900"
                    >
                        <div>
                            Asesorias
                            <span className="text-gray-400">
                                &nbsp;&nbsp;
                                {getClientConsultanciesList().length >= 1 ? 
                                `(${getClientConsultanciesList().length})` : '(0)'}
                            </span>
                        </div>
                        <PiCaretDownBold
                            strokeWidth={3}
                            className={cn(
                                "h-3.5 w-3.5 -rotate-90 text-gray-500 transition-transform duration-200 rtl:rotate-90",
                                open && "rotate-0 rtl:rotate-0"
                            )}
                        />
                    </button>
                )}
            >
                <WidgetCard title="" headerClassName="hidden" className="border-transparent p-2 lg:p-3">
                    { !isLoadingClientConsultancies && getClientConsultanciesList().length >= 1 ? (
                        ConsultancyHistoryContent(getClientConsultanciesList())
                    ) : (
                        <div className="flex items-center justify-center h-18">
                            {isLoadingClientConsultancies ? <Loader variant="spinner" /> : <Text className="text-gray-400">Sin Resultados</Text>}
                        </div>
                    )}
                    <div className="w-full flex flex-row gap-2 justify-center">
                        <Button
                            variant="outline"
                            className={cn("border-gray-300 w-[45%] mt-2", !isMoreClientConsultancies && 'hidden')}
                            onClick={() => modifyLimitClientConsultancy(5)}
                        >
                            <Text className="text-gray-400">Ver Más</Text>
                        </Button>
                        <Button
                            variant="outline"
                            className={cn("border-gray-300 w-[45%] mt-2", getClientConsultanciesList().length <= 5 && 'hidden')}
                            onClick={() => modifyLimitClientConsultancy(-5)}
                        >
                            <Text className="text-gray-400">Ver Menos</Text>
                        </Button>
                    </div>
                </WidgetCard>
            </Collapse>
        </div>
    )
}

export default ClientConsultancyHistory;