import { useCallback, useEffect, useState } from 'react';
import { StateService } from '../../services/state/stateService';
import { entities } from '../../pages/states/StateForm';
import { State } from '../../type/entities/state-type';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage states fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de estados.
----------------------------------------------------------------------------------------*/

const useStates = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchStates = useCallback(async () => {
        try {
            const service = new StateService();
            const response = await service.listStates(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: State) => ({
                    value: data.id,
                    label: entities.find((entity) => entity.value === data.entity)?.label + ' - ' + data.name,
                    entityLabel: entities.find((entity) => entity.value === data.entity)?.label,
                    color: data.color,
                    statusOrder: data.statusOrder,
                    initialStatus: data.initialStatus,
                    entity: data.entity,
                    order: data.statusOrder
                }));
                mappedData.sort((a: any, b:any) => {
                    if (a.entityLabel < b.entityLabel) return -1;
                    if (a.entityLabel > b.entityLabel) return 1;
                    if (a.order < b.order) return -1;
                    if (a.order > b.order) return 1;
                    return 0;
                });
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching states:', error);
        }
    }, [filters]);

    useEffect(() => {
        fetchStates();
    }, []);

    const getStatesList = (entity?: string) => {
        if (entity) return data.filter((state: any) => state.entity === entity);
        return data;
    };

    const addStatesFilter = (key: string, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                [key]: value
            }
        }));
    };
    return { fetchStates, getStatesList, addStatesFilter };
}

export default useStates;