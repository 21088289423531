// src/store/slices/studentSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface studentState {
    selectedView: string;
}

const initialState: studentState = {
    selectedView: '/info',
};

const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<string>) {
            state.selectedView = action.payload;
        },
    },
});

export const { setSelectedView } = studentSlice.actions;
export default studentSlice.reducer;