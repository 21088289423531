import React, { useCallback, useState } from 'react';
import Page from '../../../layout/Page/Page';
import CustomTable from '../../../components/table/CustomTable';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import { ActionIcon, Switch, Tooltip } from 'rizzui';
import { Link } from 'react-router-dom';
import { MdOutlineModeEditOutline } from "react-icons/md";
import DeletePopover from '../../../components/buttons/DeletePopover';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { menuRoutes } from '../../../router/menu';
import { toast } from 'sonner';
import { RoleService } from '../../../services/role/roleService';
import { RolesApiResponse } from '../../../type/entities/role-type';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import RolesFilters from './RolesFilters';
import useHandleErrors from '../../../hooks/useHandleErrors';

interface RolesListProps { }

const columnOrder = [
    'id',
    'name',
    'description',
    'active',
    'color',
    'createdAt',
    'updatedAt',
];

const RolesList: React.FC<RolesListProps> = ({ }) => {

    const { user } = useSelector((state: RootState) => state.auth);
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new RoleService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listRoles(filters);
        return response.getResponseData() as RolesApiResponse;
    }, [filters]));

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleRoleStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el rol" : "Se ha activado el rol");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteRole(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Rol eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await service.deleteMultiRoles(ids)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Roles eliminados correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <Page container='fluid'>
            {loading
                ? <Loader height='70vh' />
                : (
                    <CustomTable
                        id={'roles-table'}
                        data={orderedData}
                        columnOrder={columnOrder}
                        columnsNotShown={['company', 'inmutable']}
                        overrideColumns={[
                            {
                                key: 'color',
                                render: (row: any) => {
                                    if (!row.row.original?.color) return null;
                                    return (
                                        <div
                                            key={row.row.original.id + row.row.original?.color}
                                            style={{
                                                backgroundColor: row.row.original?.color,
                                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                                width: "35px",
                                                height: "35px",
                                            }}
                                            className={'rounded-full mr-3 p-3'}
                                        ></div>
                                    );
                                },
                            },
                            {
                                key: 'active',
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={row.row.original.active ? 'Desactivar rol' : 'Activar rol'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Switch
                                                id={row.row.original.id}
                                                checked={row.row.original.active}
                                                disabled={changingStatus.includes(row.row.original.id) || row.row.original.id === user?.id}
                                                onChange={() => toggleStatus(row.row.original.id, row.row.original.active)}
                                                switchKnobClassName='bg-secondary'
                                            />
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        actions={[
                            {
                                label: 'Edit',
                                permissions: { group: 'roles', permission: 'edit_roles' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar rol'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link to={`${menuRoutes.roles.path}/${row.id}/edit`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Delete',
                                permissions: { group: 'roles', permission: 'delete_roles' },
                                render: (row: any) => {
                                    if (row.id === user?.id) return null;
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Eliminar'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>
                                                <DeletePopover
                                                    title={`Eliminar rol`}
                                                    description={`¿Estás seguro de que deseas eliminar a ${row.name}?`}
                                                    onDelete={() => handleDelete(row.id)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        handleMultipleDelete={handleMultiDelete}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as RolesApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                )}
            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Roles'}>
                <RolesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default RolesList;