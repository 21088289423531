import { useEffect, useState } from 'react';
import { QuotationGroupService } from '../../services/quotation-group/quotationGroupService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage quotation groups fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de grupos de cotización.
----------------------------------------------------------------------------------------*/

const useQuotationGroups = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchQuotationGroups = async () => {
        try {
            const service = new QuotationGroupService();
            const response = await service.listQuotationGroups(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching quotation groups:', error);
        }
    };

    useEffect(() => {
        fetchQuotationGroups();
    }, [filters]);

    const updateQuotationGroupsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getQuotationGroupsList = () => {
        return data;
    };

    return { fetchQuotationGroups, getQuotationGroupsList, updateQuotationGroupsHookFilters };
}

export default useQuotationGroups;