import React from 'react';
import { Modal, Textarea, Tooltip } from 'rizzui';
import RequiredInput from '../../../components/forms/InputHOC';
import { FaCalendarAlt } from 'react-icons/fa';
import { IoCloseCircleOutline, IoTrashBinOutline } from 'react-icons/io5';
import DeletePopover from '../../../components/buttons/DeletePopover';
import { toast } from 'sonner';


interface HolidaysModalProps {
    isOpen: boolean
    setOpen: (val: boolean) => void
    handleSubmit: (val: any, update: boolean) => void
    handleRemove: (id: any) => void
    selectedSlotData: any,
    selectedEvent?: any
}

const HolidaysModal: React.FC<HolidaysModalProps> = ({ isOpen, setOpen, handleSubmit, handleRemove, selectedSlotData, selectedEvent }) => {

    const [startDate, setStartDate] = React.useState<Date | null>(new Date(selectedEvent ? selectedEvent.start : selectedSlotData.start.setHours(23, 59, 59, 999)));
    const [concept, setConcept] = React.useState<string>(selectedEvent ? selectedEvent.title : '');

    const createNewDay = () => {
        if (!startDate || !concept) {
            toast.error('Debes completar todos los campos');
            return;
        }
        const newDay = {
            id: selectedEvent ? selectedEvent.id : startDate + concept,
            date: startDate?.toISOString().split('T')[0],
            concept: concept
        }
        setConcept('');
        handleSubmit(newDay, selectedEvent !== null);
    }

    return (
        <Modal isOpen={isOpen} onClose={() => setOpen(false)} size={'md'}>
            <div className='flex flex-col p-4 gap-6 relative'>
                <IoCloseCircleOutline className="absolute top-4 right-4 text-4xl text-gray-400 m-auto cursor-pointer" onClick={() => setOpen(false)} />
                {selectedEvent && (
                    <DeletePopover
                        title={`Eliminar Día Festivo`}
                        description={`¿Estás seguro de que deseas eliminar el Festivo "${selectedEvent.title}"?`}
                        onDelete={() => handleRemove(selectedEvent.id)}
                        className='absolute top-4 left-4 text-4xl text-gray-400 m-auto cursor-pointer'
                        placement='right'
                        Icon={<IoTrashBinOutline className='text-3xl text-red-500' />}
                    />
                )}
                <div className='flex flex-col justify-center p-2'>
                    <FaCalendarAlt className="text-4xl text-primary m-auto" />
                    <h4 className="text-2xl text-center">{selectedEvent ? 'Editar Día Festivo' : 'Introduce el nuevo día festivo'}</h4>
                </div>
                <RequiredInput
                    required={true}
                    id='start_date'
                    type="date"
                    label="Fecha del Día Festivo"
                    className="col-span-12 "
                    value={startDate?.toISOString().split('T')[0]}
                    onChange={(value: any) => {
                        if (value.target.value) setStartDate(new Date(value.target.value))
                    }}
                />

                <Textarea
                    id="comments"
                    label="Comentario"
                    placeholder="Escribe un comentario"
                    rows={3}
                    className="col-span-12 [&>label>span]:font-medium "
                    value={concept}
                    onChange={(e: any) => setConcept(e.target.value)}
                />

                <div className="flex justify-center p-3 gap-4">
                    <button className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-300" onClick={() => createNewDay()}>
                        {selectedEvent ? 'Editar' : 'Crear'}
                    </button>
                    <button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={() => setOpen(false)}>
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default HolidaysModal;