import { Outlet } from "react-router-dom";
import SettingsSidebar from "./SettingsSidebar";
import { SettingsProvider } from "./provider/SettingsProvider";
import SettingsComponents from "./SettingsComponents";

const SettingsIndexWrapper = () => {
    return (
        <SettingsProvider>
            <div className="flex flex-grow flex-col 3xl:pt-4 4xl:px-10 animate-slide-down">
                <div className="page-container flex flex-col lg:flex-row flex-nowrap h-[calc(100vh-110px)] w-full">
                    <div className="sidebar w-full lg:w-[20vw] overflow-hidden ">
                        <SettingsSidebar />
                    </div>
                    <div className="content w-full h-full lg:w-[80vw] p-4 me-8">
                        <Outlet />
                        <SettingsComponents />
                    </div>
                </div>
            </div>
        </SettingsProvider>
    )
}

export default SettingsIndexWrapper;