import React from 'react';
import FormGroup from '../../../layout/shared/form-group';
import CustomSelect from '../../../components/forms/CustomSelect';
import RequiredInput from '../../../components/forms/InputHOC';
import { Button, cn } from 'rizzui';
import { FaEuroSign, FaRegTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import useProducts from '../../../hooks/api-calls/useProducts';
import classNames from 'classnames';
import { paymentMethods } from '../TutorForm';
import { FaPercent } from 'react-icons/fa6';

interface TutorProductsFormProps {
    formik: any;
    product: { id: string, price: string, paymentMethod: string, deductions: string };
    setProduct: (product: { id: string, price: string, paymentMethod: string, deductions: string }) => void;
}

const TutorProductsForm: React.FC<TutorProductsFormProps> = ({ formik, product, setProduct }) => {

    const { getProductsList } = useProducts();

    return (
        <FormGroup
            title="Productos"
            description="Productos que el tutor ofrece"
            className={classNames('pt-6', { 'hidden': formik.values.tutorType === '' })}
            titleCols="@md:col-span-2"
            childCols="@md:col-span-10 md:grid-cols-1"
        >
            {formik.values.products.map((productItem: any, index: number) => {
                return (
                    <div key={index} className={cn("grid grid-cols-2 md:grid-cols-6 gap-4 pb-4", index !== formik.values.products.length - 1 && "border-b border-gray-200")}>
                        <CustomSelect
                            id={`products[${index}].id`}
                            required
                            label="Producto"
                            options={getProductsList()}
                            onChange={(e: any) => {
                                const updatedProducts = formik.values.products.map((product: any, i: number) => {
                                    if (i === index) {
                                        return { ...product, id: e.value };
                                    }
                                    return product;
                                });
                                formik.setFieldValue('products', updatedProducts);
                            }}
                            value={{ value: productItem.id, label: getProductsList().find((p: any) => p.value === productItem.id)?.label }}
                            containerClassName='col-span-2'
                            error={formik.errors.products && formik.errors.products[index]?.id}
                        />

                        {formik.values.tutorType === 'external' && (
                            <RequiredInput
                                id={`products[${index}].price`}
                                type="number"
                                label="Precio"
                                suffix={<FaEuroSign />}
                                value={productItem.price}
                                error={formik.errors.products && formik.errors.products[index]?.price}
                                formik={formik}
                            />
                        )}

                        {formik.values.tutorType === 'external' && (
                            <CustomSelect
                                id={`products[${index}].paymentMethod`}
                                required
                                label="Método de pago"
                                options={paymentMethods}
                                onChange={formik.handleChange}
                                value={{ value: productItem.paymentMethod, label: paymentMethods.find((p: any) => p.value === productItem.paymentMethod)?.label }}
                                error={formik.errors.products && formik.errors.products[index]?.paymentMethod}
                            />
                        )}

                        {formik.values.tutorType === 'external' && (
                            <RequiredInput
                                id={`products[${index}].deductions`}
                                min={0}
                                max={100}
                                type="number"
                                label="Retenciones"
                                suffix={<FaPercent />}
                                value={productItem.deductions}
                                error={formik.errors.products && formik.errors.products[index]?.deductions}
                                formik={formik}
                            />
                        )}

                        <div className="flex justify-end items-end">
                            <Button
                                color="danger"
                                size='sm'
                                onClick={() => {
                                    const updatedProducts = formik.values.products.filter((product: any, i: number) => i !== index);
                                    formik.setFieldValue('products', updatedProducts);
                                }}
                            >
                                <FaRegTrashAlt />
                            </Button>
                        </div>
                    </div>
                )
            })}

            <div className="grid grid-cols-2 md:grid-cols-6 gap-4 mb-4">
                <CustomSelect
                    id='productsId'
                    isClearable
                    required
                    label="Producto"
                    options={getProductsList()}
                    onChange={(e: any) => { setProduct({ id: e?.value, price: '', paymentMethod: '', deductions: '' }) }}
                    value={{ value: product.id, label: getProductsList().find((p: any) => p.value === product.id)?.label }}
                    containerClassName='col-span-2'
                />

                {formik.values.tutorType === 'external' && (
                    <RequiredInput
                        id='price'
                        type="number"
                        label="Precio"
                        suffix={<FaEuroSign />}
                        onChange={(e: any) => setProduct({ id: product.id, price: e.target.value, paymentMethod: product.paymentMethod, deductions: product.deductions })}
                        formik={formik}
                    />
                )}

                {formik.values.tutorType === 'external' && (
                    <CustomSelect
                        id='paymentMethod'
                        isClearable
                        required
                        label="Método de pago"
                        options={paymentMethods}
                        onChange={(e: any) => setProduct({ id: product.id, price: product.price, paymentMethod: e?.value, deductions: product.deductions })}
                        value={{ value: product.paymentMethod, label: paymentMethods.find((p: any) => p.value === product.paymentMethod)?.label }}
                    />
                )}

                {formik.values.tutorType === 'external' && (
                    <RequiredInput
                        id='deductions'
                        type="number"
                        label="Retenciones"
                        formik={formik}
                        suffix={<FaPercent />}
                        onChange={(e: any) => setProduct({ id: product.id, price: product.price, paymentMethod: product.paymentMethod, deductions: e.target.value })}
                    />
                )}

                <div className="flex justify-end items-end">
                    <Button
                        color='secondary'
                        size='sm'
                        disabled={product.id === '' || (formik.values.tutorType === 'external' && (product.price === '' || product.paymentMethod === '' || product.paymentMethod === undefined || product.deductions === ''))}
                        onClick={() => {
                            formik.setFieldValue('products', [...formik.values.products, product]);
                            setProduct({ id: '', price: '', paymentMethod: '', deductions: '' });
                        }}
                    >
                        <MdAdd className='h-5 w-5' />
                    </Button>
                </div>
            </div>
        </FormGroup>
    );
};

export default TutorProductsForm;