import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import CustomTable from '../../../components/table/CustomTable';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import { ActionIcon, Tooltip } from 'rizzui';
import { Link } from 'react-router-dom';
import { MdOutlineModeEditOutline } from "react-icons/md";
import DeletePopover from '../../../components/buttons/DeletePopover';
import moment from 'moment';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { menuRoutes } from '../../../router/menu';
import { toast } from 'sonner';
import { InvoicesApiResponse } from '../../../type/entities/invoice-type';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import InvoicesFilters from './InvoicesFilters';
import { InvoiceService } from '../../../services/invoice/invoiceService';
import useHandleErrors from '../../../hooks/useHandleErrors';
import useInvoiceStates from '../../../hooks/api-calls/useInvoiceStates';
import StatusDropdown from '../../../components/forms/StatusDropdown';
import { fixNumber } from '../../../utils/formatNumber';
import { HiOutlineEye } from 'react-icons/hi2';

interface InvoicesListProps { }

const columnOrder = [
    'id',
    'number',
    'totalPrice',
    'taxableAmount',
    'tax',
    'totalTaxesAmount',
    'totalProducts',
    'currentStatus',
    'corporation',
    'invoiceDate',
    'paymentDate',
    'expirationDate',
    'createdAt',
    'updatedAt',
    'invoiceDates',
];

const InvoicesList: React.FC<InvoicesListProps> = ({ }) => {

    const { user } = useSelector((state: RootState) => state.auth);
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new InvoiceService();

    const { getInvoiceStatesList } = useInvoiceStates({ active: true });
    const { handleErrors } = useHandleErrors();
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listInvoices(filters);
        return response.getResponseData() as InvoicesApiResponse;
    }, [filters]));

    const handleStateChange = async (invoiceId: string, newStateId: string, comment: string) => {
        try {
            const response = await service.changeInvoiceState(invoiceId, newStateId, comment);
            const responseData = response.getResponseData();

            if (responseData && responseData.success) {
                toast.success("Estado de la factura actualizado correctamente");
                refetch();
            } else if (responseData) {
                toast.error(responseData.message);
                refetch();
            }
        } catch (error: any) {
            handleErrors(error);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteInvoice(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Se ha eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await service.deleteMultiInvoices(ids)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Se han eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <Page container='fluid'>
            {loading
                ? <Loader height='70vh' />
                : (
                    <CustomTable
                        id={'invoices-table'}
                        data={orderedData}
                        columnOrder={columnOrder}
                        columnsNotShown={['company', 'lines', 'invoiceDate', 'paymentDate', 'expirationDate', 'invoiceDates']}
                        additionalColumns={[
                            {
                                id: 'invoiceDates',
                                header: 'Fechas Factura',
                                render: (row: any) => {
                                    const invoiceDate = row.row.original?.invoiceDate?.date;
                                    const paymentDate = row.row.original?.paymentDate?.date;
                                    const expirationDate = row.row.original?.expirationDate?.date;
                                    return (
                                        <ul className='gap-x-4'>
                                            <li className='flex flex-nowrap pb-1'>
                                                <h6 className='text-nowrap text-gray-400'>Fecha de Factura:&nbsp;</h6>
                                                {invoiceDate ? moment(invoiceDate).format('DD/MM/YYYY') : 'N/A'}
                                            </li>
                                            <li className='flex flex-nowrap pb-1'>
                                                <h6 className='text-nowrap text-gray-400'>Fecha de Pago:&nbsp;</h6>
                                                {paymentDate ? moment(paymentDate).format('DD/MM/YYYY') : 'N/A'}
                                            </li>
                                            <li className='flex flex-nowrap pb-1'>
                                                <h6 className='text-nowrap text-gray-400'>Fecha de Vencimiento:&nbsp;</h6>
                                                {expirationDate ? moment(expirationDate).format('DD/MM/YYYY') : 'N/A'}
                                            </li>
                                        </ul>
                                    );
                                }
                            },
                        ]}
                        overrideColumns={[
                            {
                                key: 'number',
                                columnNameKey: 'invoiceNumber',
                                render: (row: any) => {
                                    return row.row.original?.number || 'N/A';
                                },
                            },
                            {
                                key: 'totalPrice',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.totalPrice ? `${fixNumber(row.row.original?.totalPrice)}€` : 'N/A'}
                                        </div>
                                    )
                                },
                            },
                            {
                                key: 'taxableAmount',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.taxableAmount ? `${fixNumber(row.row.original?.taxableAmount)}€` : 'N/A'}
                                        </div>
                                    )
                                },
                            },
                            {
                                key: 'currentStatus',
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={row.row.original?.currentStatus?.description}
                                            placement="top"
                                            color="invert"
                                        >
                                            <StatusDropdown
                                                entityId={row.row.original?.id}
                                                currentState={row.row.original?.currentStatus}
                                                statesOptions={getInvoiceStatesList(row.row.original?.currentStatus?.id)}
                                                handleStateChange={handleStateChange}
                                                title="Esta acción modificará el estado de la factura"
                                            />
                                        </Tooltip>
                                    );
                                }
                            },
                            {
                                key: 'corporation',
                                render: (row: any) => {
                                    return row.row.original?.corporation?.name || 'N/A';
                                },
                            },
                            {
                                key: 'tax',
                                render: (row: any) => {
                                    return (
                                        row.row.original?.tax ? `${row.row.original?.tax}%` : 'N/A'
                                    );
                                },
                            },
                            {
                                key: 'totalTaxesAmount',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.totalTaxesAmount ? `${fixNumber(row.row.original?.totalTaxesAmount)}€` : 'N/A'}
                                        </div>
                                    )

                                },
                            },
                            {
                                key: 'totalProducts',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.totalProducts ? `${row.row.original?.totalProducts}` : 'N/A'}
                                        </div>
                                    )
                                },
                            },
                            {
                                key: 'createdAt',
                                render: (row: any) => {
                                    const date = row.row.original?.createdAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                            {
                                key: 'updatedAt',
                                render: (row: any) => {
                                    const date = row.row.original?.updatedAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                        ]}
                        actions={[
                            {
                                label: 'View',
                                permissions: { group: 'invoices', permission: 'get_invoices' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Ver detalles'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link
                                                to={`${menuRoutes.invoices.path}/${row.id}/profile${menuRoutes.invoices.profile.activityHistory}`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <HiOutlineEye className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Edit',
                                permissions: { group: 'invoices', permission: 'edit_invoices' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar factura'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link to={`${menuRoutes.invoices.path}/${row.id}/edit`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Delete',
                                permissions: { group: 'invoices', permission: 'delete_invoices' },
                                render: (row: any) => {
                                    if (row.id === user?.id) return null;
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Eliminar'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>
                                                <DeletePopover
                                                    title={`Eliminar factura`}
                                                    description={`¿Estás seguro de que deseas eliminar a ${row.number}?`}
                                                    onDelete={() => handleDelete(row.id)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        handleMultipleDelete={handleMultiDelete}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as InvoicesApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Facturas'}>
                <InvoicesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default InvoicesList;