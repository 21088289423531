// src/store/slices/invoiceProfileSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InvoiceProfileState {
    selectedView: string;
}

const initialState: InvoiceProfileState = {
    selectedView: '/info',
};

const invoiceProfileSlice = createSlice({
    name: 'invoiceProfile',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<string>) {
            state.selectedView = action.payload;
        },
    },
});

export const { setSelectedView } = invoiceProfileSlice.actions;
export default invoiceProfileSlice.reducer;