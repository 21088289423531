import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { FilterOptions } from '../../hooks/useFilters';

const USER_ENDPOINT = '/users';

export class UserService extends RestServiceConnection {

    listUsers = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getUserById = async (userId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/get',
            data: { userId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleUserStatus = async (userId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/toggle',
            data: { userId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    addUserDocument = async (data: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/add-document',
            data: data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    createUser = async (user: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/create',
            data: user,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editUser = async (user: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/edit',
            data: user,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editUserImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('userId', id);
        formData.append('profileImg', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/add-image',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        }, true);
        return this;
    }

    deleteUserImg = async (userId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/delete-image',
            data: { userId }
        }, true);
        return this;
    }

    deleteUser = async (userId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/delete',
            data: { userId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiUsers = async (userIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/delete-multi',
            data: { userIds }
        }, true);
        return this;
    }

    editUserPermissions = async (user: string, company: string | null, permissions: number[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/edit-permissions',
            data: {
                userId: user,
                company: company,
                permissions: permissions
            }
        }, true);
        return this;
    }

    resetUserPermissions = async (user: string, company?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/reset-permissions',
            data: {
                userId: user,
                company: company
            }
        }, true);
        return this;
    }

    changePassword = async (userId: string, password: string, passwordConfirm: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/change-password',
            data: { userId, password, passwordConfirm }
        }, true);
        return this;
    }

    loginAsUser = async (userId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/login-as-user',
            data: { userId }
        }, true);
        return this;
    }

    listUserDocuments = async (filters: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/list-documents',
            data: filters,
        }, true);
        return this;
    }

    getUserDocuments = async (userId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/get-documents',
            data: { userId },
        }, true);
        return this;
    }

    listChangelogs = async (userId: string, filters?: any) => {        
        filters.filter_filters.user = userId;
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/list-user-changes-log',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    listRolesByUser = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/list-user-roles',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    manageCompanyConfiguration = async (configurationParameters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/manage-configuration',
            data: configurationParameters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getCompanyConfiguration = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: USER_ENDPOINT + '/get-company-configuration',
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getContractStats = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: USER_ENDPOINT + '/contract-stats',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}