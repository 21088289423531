import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import Page from "../../layout/Page/Page";
import FormFooter from "../_layout/_footers/form-footer";
import FormGroup from "../../layout/shared/form-group";
import RequiredInput from "../../components/forms/InputHOC";
import { useFormik } from "formik";
import CustomSelect from "../../components/forms/CustomSelect";
import { Select, Switch, Tooltip } from "rizzui";
import { validateCifNif } from "../../utils/validatorFunctions";
import IBAN from 'iban';
import { Corporation } from "../../type/entities/corporation-type";
import { CorporationService } from "../../services/corporation/corporationService";
import useFetch from "../../hooks/useFetch";
import { toast } from "sonner";
import useCorporations from "../../hooks/api-calls/useCorporations";
import { Loader } from "../../components/loader/SpinnerLogo";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import useHandleErrors from "../../hooks/useHandleErrors";
import ButtonPopover from "../../components/buttons/ButtonPopover";

interface CreateFormProps {
    id: string | undefined;
    setNodeId: (value: string | undefined) => void;
    nodeStatus: boolean;
    setNodeStatus: (value: boolean) => void;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    refetch: () => void;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
    corporationFunction: yup.string().required('Campo obligatorio'),
    cif: yup.string().test('valid-cif-nif', 'El formato de CIF/NIF no es válido', (value) => {
        if (value !== undefined && value.length > 0) {
            return validateCifNif(value);
        } else {
            return true;
        }
    }).required('Campo obligatorio'),
    iban: yup.string().test('iban', 'El formato de IBAN no es válido', (value: any) => IBAN.isValid(value)),
    registeredOffice: yup.string().required('Campo obligatorio'),
    parentCorporationId: yup.string().nullable(),
});

export const corporationFunctionOptions = [
    { value: 'delivering', label: 'Impartidora' },
    { value: 'organizing', label: 'Organizadora' },
];

const CorporationForm: React.FC<CreateFormProps> = ({ id, setNodeId, nodeStatus, setNodeStatus, isOpen, setIsOpen, refetch }) => {

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { getCorporationsList } = useCorporations({ active: true });
    const service = new CorporationService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = await service.getCorporationById(id as string);
        return response.getResponseData() as Corporation;
    }, [id]));

    const mode = data ? 'Editar' : 'Crear';

    const submit = async (values: any) => {
        setIsLoading(true);

        if (mode === 'Crear') {
            try {
                const response = await service.createCorporation(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Empresa creada correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            try {
                const response = await service.editCorporation(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Empresa editada correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const toggleState = async (id: string, state: boolean) => {
        try {
            const response = (await service.toggleCorporationStatus(id, state)).getResponseData();
            if (response.success) {
                setNodeStatus(!state);
                toast.success(`Empresa ${!state ? 'activada' : 'desactivada'} correctamente`);
                refetch();
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            handleErrors(error);
        }
    };

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteCorporation(id)).getResponseData();
        if (response.success) {
            toast.success('Empresa eliminada correctamente');
            setIsOpen(false);
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const formik = useFormik({
        initialValues: {
            corporationId: id ?? '',
            name: data?.name ?? '',
            corporationFunction: data?.corporationFunction ?? '',
            cif: data?.cif ?? '',
            iban: data?.iban ?? '',
            registeredOffice: data?.registeredOffice ?? '',
            parentCorporationId: data?.parentCorporation?.id ?? null,
        },
        validationSchema: schema,
        validateOnBlur: false,
        //validateOnChange: false,
        onSubmit: values => { submit(values); },
    });

    useEffect(() => {
        if (data) {
            formik.setValues({
                corporationId: data.id,
                name: data.name,
                corporationFunction: data.corporationFunction,
                cif: data.cif,
                iban: data.iban,
                registeredOffice: data.registeredOffice,
                parentCorporationId: data.parentCorporation?.id ?? null,
            });
        }
    }, [data]);

    useEffect(() => {
        if (id === undefined) {
            formik.resetForm();
        }
    }, [id]);

    if (isOpen && id && !data) return <Loader />;

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-10 grid gap-3 @2xl:gap-9 @3xl:gap-11 font-medium">
                        {id && isOpen && (
                            <div className='grid grid-cols-2 gap-10 pt-2'>
                                <div className="col-start-2 flex justify-end">
                                    <Tooltip
                                        size="sm"
                                        content={nodeStatus ? 'Desactivar empresa' : 'Activar empresa'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <Switch
                                                id={id}
                                                label={nodeStatus ? 'Activa' : 'Inactiva'}
                                                checked={nodeStatus ? true : false}
                                                onChange={() => toggleState(id, nodeStatus)}
                                                switchKnobClassName='bg-secondary'
                                            />
                                        </div>
                                    </Tooltip>

                                    {userCan('delete_corporation', 'corporation') && (
                                        <ButtonPopover
                                            title='Eliminar empresa'
                                            description='Esto eliminará todo lo que dependa de esta empresa'
                                            onClick={() => handleDelete(id)}
                                            variant='text'
                                            mobileView
                                            btnClassName=" px-4 py-2 gap-2"
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        <FormGroup
                            title="Información General"
                            description="Datos de la empresa"
                            className="pt-4"
                        >
                            <RequiredInput
                                id='name'
                                label="Nombre"
                                formik={formik}
                            />

                            <RequiredInput
                                id='cif'
                                label="CIF/NIF"
                                formik={formik}
                            />

                            <CustomSelect
                                id='corporationFunction'
                                label="Función"
                                className="[&>label>span]:font-medium"
                                value={corporationFunctionOptions.find((g: any) => g.value === formik.values.corporationFunction)}
                                options={corporationFunctionOptions}
                                onChange={(e: any) => { formik.setFieldValue('corporationFunction', e?.value); }}
                                placeholder="Selecciona una opción"
                                error={ formik.touched ? (formik.errors.corporationFunction as string ?? undefined) : undefined } 
                                required
                            />

                            <CustomSelect
                                isSearchable
                                isClearable
                                id={'parentCorporationId'}
                                label="Empresa Padre"
                                value={{ value: formik.values.parentCorporationId, label: getCorporationsList()?.find((g: any) => g.value === formik.values.parentCorporationId)?.label }}
                                options={getCorporationsList()}
                                onChange={(e: any) => { formik.setFieldValue('parentCorporationId', e?.value); }}
                                placeholder="Selecciona una opción"
                            />

                            <RequiredInput
                                id='iban'
                                label="IBAN"
                                formik={formik}
                            />

                            <RequiredInput
                                id='registeredOffice'
                                label="Domilicio Social"
                                formik={formik}
                            />
                        </FormGroup>
                    </div>
                </form>
            </div>
        )
    };

    return (
        <>
            {isOpen && (
                <>
                    <Page container="fluid">
                        {(mode === "Editar" && data) && getContent()}
                        {mode === "Crear" && getContent()}
                        <FormFooter
                            customClassName={"border-0 py-1"}
                            customBg={"bg-transparent"}
                            submitBtnText={mode + ' Empresa'}
                            handleCancelBtn={() => { setIsOpen(false); setNodeId(undefined); formik.resetForm(); }}
                            handleSubmitBtn={formik.submitForm} isLoading={isLoading}
                        />
                    </Page>
                </>
            )}
        </>
    )
};

export default CorporationForm;