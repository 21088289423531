import { useCallback, useEffect, useState } from 'react';
import { ClientService } from '../../services/client/clientService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage clients fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de clientes.
----------------------------------------------------------------------------------------*/

const useClients = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchClients = useCallback(async () => {
        try {
            const service = new ClientService();
            const response = await service.listClients({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; companyName: string; cifNif: string; }) => ({
                    value: data.id,
                    label: data.companyName + ' (' + data.cifNif + ')',
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching clients:', error);
        }
    }, []);

    useEffect(() => {
        fetchClients();
    }, []);

    const updateClientsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getClientsList = () => {
        return data;
    };

    return { fetchClients, getClientsList, updateClientsHookFilters };
}

export default useClients;