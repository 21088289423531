import { useCallback, useEffect, useState } from 'react';
import UserProfileLayout from '../../UserProfileLayout';
import { useParams } from 'react-router-dom';
import { UserService } from '../../../../../services/user/userService';
import useFetch from '../../../../../hooks/useFetch';
import { UserApiResponse } from '../../../../../type/entities/user-type';
import FormGroup from '../../../../../layout/shared/form-group';
import { ShowPermissionAccordion } from '../../../../../components/ui/PermissionsAccordion';
import { Permission, PermissionGroup, PermissionsApiResponse, RolePermission } from '../../../../../type/entities/role-type';
import { PermissionService } from '../../../../../services/auth/permissionService';
import cn from '../../../../../utils/classNames';
import { Loader } from '../../../../../components/loader/SpinnerLogo';
import { Input, Text } from 'rizzui';

const UserPermissions = () => {

    const { id = '' } = useParams<{ id: string }>();
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [groupSearch, setGroupSearch] = useState<string>('');
    const [filteredPermissionsData, setFilteredPermissionsData] = useState<PermissionGroup[] | null>(null);

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new UserService()).getUserById(id);
        return response.getResponseData() as UserApiResponse;
    }, [id]));

    const [permissionsData, permissionsLoading] = useFetch(useCallback(async () => {
        const response = await (new PermissionService()).getPermissions();
        return response.getResponseData() as PermissionsApiResponse;
    }, []));

    useEffect(() => {
        if (data) setSelectedPermissions(data?.userPermissions.map((permission: RolePermission) => permission.permission.id));
    }, [data]);

    /**
     * Filter groups by groupSearch, if groupSearch is empty, return all groups
     */
    useEffect(() => {
        if (permissionsData) {
            if (groupSearch === '') {
                setFilteredPermissionsData(permissionsData);
            } else {
                const filteredGroups = permissionsData.filter((group: PermissionGroup) => { return group.label.toLowerCase().includes(groupSearch.toLowerCase()) });
                setFilteredPermissionsData(filteredGroups);
            }
        }
    }, [permissionsData, groupSearch]);

    return (
        <UserProfileLayout>
            <div className="@container">
                <div>
                    <div className="mt-6 mb-4 flex items-center">
                        <Text className="rizzui-input-label block text-sm font-medium me-3">Buscar grupo:</Text>
                        <Input
                            id="groupSearch"
                            value={groupSearch}
                            onChange={(e) => setGroupSearch(e.target.value)}
                        />
                    </div>
                    {(filteredPermissionsData && !permissionsLoading)
                        ? filteredPermissionsData.map((permissionGroup: PermissionGroup) => {
                            const atLeastOneSelected = permissionGroup.permissions.some((permission: Permission) => selectedPermissions.includes(permission.id));

                            return (
                                <FormGroup
                                    key={`index-${permissionGroup.id}`}
                                    title={permissionGroup.label}
                                    //description='Lista de permisos asignados al usuario'
                                    className='pt-6 mt-6 border-t border-gray-200'
                                    childClassName='col-span-full grid gap-4 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 @4xl:col-span-8 @4xl:gap-5 xl:gap-7'
                                >
                                    <div key={permissionGroup.id} className={cn([atLeastOneSelected ? '' : 'hidden absolute', "col-span-full"])}>
                                        <ShowPermissionAccordion
                                            group={permissionGroup}
                                            selectAll={[]}
                                            selectedPermissions={selectedPermissions}
                                        />
                                    </div>
                                </FormGroup>
                            )
                        })
                        : <Loader height='50vh' />
                    }
                </div>
            </div>
        </UserProfileLayout>
    );
};

export default UserPermissions;