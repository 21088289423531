import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/quotation-group';

export class QuotationGroupService extends RestServiceConnection {

    listQuotationGroups = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getQuotationGroupById = async (quotationGroupId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { quotationGroupId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createQuotationGroup = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editQuotationGroup = async (entity: any) => {
        entity.quotationGroupId = entity.id;
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteQuotationGroup = async (quotationGroupId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { quotationGroupId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiQuotationGroups = async (quotationGroupIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { quotationGroupIds },
        }, true);
        return this;
    }

    updateQuotationGroupOrder = async (quotationGroupId: string, action: 'up' | 'down') => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-quotation-group-order',
            data: { quotationGroupId, action },
        }, true);
        return this;
    }

    toggleQuotationGroup = async (quotationGroupId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { quotationGroupId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}