import React from 'react';
import PageHeader from '../../layout/shared/page-header';
import HorizontalFormBlockWrapper from '../../components/forms/HorizontalFormBlockWrapper';
import RequiredInput from '../../components/forms/InputHOC';
import { menuRoutes } from '../../router/menu';
import { useSettingsProvider } from './provider/SettingsProvider';
import FormFooter from '../_layout/_footers/form-footer';
import { HiOutlineInformationCircle } from 'react-icons/hi2';

const ContractsSettingsPage: React.FC = () => {

    const { formik, hasCompanyConfigChanges, isLoading, companyConfig, updateCompanyConfig, setShowModal, discardCompanyConfigChanges } = useSettingsProvider();

    const pageHeader = {
        title: 'Ajustes en Contratos',
        breadcrumb: [
            { name: 'Inicio', href: menuRoutes.dashboard.path },
            { name: 'Configuración' },
            { name: 'Contratos', href: menuRoutes.settings.path + menuRoutes.settings.contracts },
        ]
    }

    return (
        <div className='h-full overflow-auto scrollbar '>
            <PageHeader
                title={pageHeader.title}
                breadcrumb={pageHeader.breadcrumb}
                className='border-b-4 border-dotted border-grey-500 top-0'
            />
            <HorizontalFormBlockWrapper
                title="Creación de los Contratos"
                description="Para personalizar los campos, ten en cuenta que las variables se encuentra de la siguiente manera: {{VARIABLE}}."
                descriptionClassName="max-w-[70%]"
                childrenWrapperClassName="@3xl:grid-cols-1 max-w-[100%] w-full"
            >
                <div className='grid grid-cols-12 pb-4 gap-4'>
                    <RequiredInput
                        required={true}
                        type={'number'}
                        id="daysPeriodToEndContract"
                        label="Tiempo BORRADOR"
                        className='col-span-12 md:col-span-4 lg:col-span-3 xl:col-span-3 3xl:col-span-2'
                        suffix='Días'
                        value={companyConfig?.daysPeriodToEndContract}
                        onChange={(e) => {
                            updateCompanyConfig('daysPeriodToEndContract', e.target.value);
                            formik.setFieldValue('daysPeriodToEndContract', e.target.value );
                        }}
                        error={formik.errors.daysPeriodToEndContract}
                    />
                    <RequiredInput
                        required={true}
                        id="contractCodeFormat"
                        contentTooltipText={'Para personalizar el nombre, utiliza las siguientes variables: {{YEAR}}, {{MONTH}} Y {{NUMBER}}.'}
                        label={<>Nombre del Contrato &nbsp;<HiOutlineInformationCircle size={20} color={'primary'}/></>}
                        className='col-span-12 lg:col-span-3'
                        placeholder='{{YEAR}}{{MONTH}}{{NUMBER}}'
                        value={companyConfig?.contractCodeFormat}
                        onChange={(e) => {
                            updateCompanyConfig('contractCodeFormat', e.target.value);
                            formik.setFieldValue('contractCodeFormat', e.target.value);
                        }}
                        error={formik.errors.contractCodeFormat}
                    />
                </div>
            </HorizontalFormBlockWrapper>
            {hasCompanyConfigChanges && (
                <FormFooter
                    customClassName={"w-full"}
                    submitBtnText={"Aplicar Cambios"}
                    cancelBtnText={"Descartar Cambios"}
                    handleCancelBtn={() => discardCompanyConfigChanges()}
                    handleSubmitBtn={() => setShowModal(true)}
                    isLoading={isLoading}
                    isDisabled={Object.keys(formik.errors).length > 0}
                />
            )}
        </div>
    );
};

export default ContractsSettingsPage;