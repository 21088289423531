import React, { useEffect } from "react";
import { useTanStackTable } from "../../../hooks/useTanStackTable";
import { FilterOptions } from "../../../hooks/useFilters";
import { useDirection } from "../../../hooks/use-direction";
import { useSelector } from "react-redux";
import { getColumnVisibility } from "../../../redux/tableSlice";
import MainTable from "./MainTable";
import TableToolbar from "./TableToolbar";
import TablePagination from "./TablePagination";

type MainTableWaitColumnConfigProps = {
    id: string;
    defaultColumnConfig: any[];
    data: any[];
    filters: FilterOptions;
    multiActions?: any;
    handleMultipleDelete?: Function;
    isLoading?: boolean;
    pagination?: boolean;
    paginationData?: any;
    search?: boolean;
    defaultOrder?: any;
    updateFilterOrder: Function;
    updateFilters: Function;
    toggleFilters?: Function;
    filter?: boolean;
    children?: React.ReactNode;
    largeTable?: boolean;
}

const fallbackData: any[] = []

const MainTableWaitColumnConfig: React.FC<MainTableWaitColumnConfigProps> = (
    {
        id,
        defaultColumnConfig,
        data,
        filters,
        multiActions,
        handleMultipleDelete,
        isLoading,
        pagination,
        paginationData,
        search,
        defaultOrder,
        updateFilterOrder,
        updateFilters,
        toggleFilters,
        filter = true,
        children,
        largeTable = false,
    }) => {

    const columnVisibility = useSelector((state: any) => {
        const stateData = state.table;
        const columnVisibility = getColumnVisibility(stateData, id);
        return columnVisibility;
    });

    const { direction } = useDirection();

    const { table, setData, tableData } = useTanStackTable<any>({
        tableData: data ?? fallbackData,
        columnConfig: defaultColumnConfig,
        options: {
            initialState: {
                pagination: {
                    pageIndex: filters.page <= 1 ? 0 : filters.page - 1,
                    pageSize: filters.limit,
                },
            },
            meta: { handleMultipleDelete, multiActions },
            enableColumnResizing: true,
            columnResizeDirection: direction as any,
            columnResizeMode: 'onChange',
        },
    });

    useEffect(() => {
        if (Array.isArray(data)) {
            setData(data);
        }
        if (data === undefined || data === null) {
            setData([]);
        }
    }, [data]);

    useEffect(() => {
        if (table?.getRowModel().rows.length === 0) return;
        Object.keys(columnVisibility).forEach((key) => {
            const isVisible = columnVisibility[key];
            const column = table.getColumn(key);
            if (column) column.toggleVisibility(isVisible);
        });
    }, [tableData]);

    return (
        <>
            <TableToolbar tableId={id} table={table} filters={filters} search={search} updateFilters={updateFilters} toggleFilters={toggleFilters} filter={filter} children={children} />
            <MainTable table={table} isLoading={isLoading} variant={'elegant'} updateFilterOrder={updateFilterOrder} defaultOrder={defaultOrder} largeTable={largeTable} />
            {pagination && <TablePagination table={table} paginationData={paginationData} />}
        </>
    );
}

export default MainTableWaitColumnConfig;