import React, { useCallback, useEffect } from 'react';
import useFilters from '../../hooks/useFilters';
import useFetch from '../../hooks/useFetch';
import { Text } from 'rizzui';
import { getIconByExtension } from '../../hooks/useGetIconByExtension';
import useHandleDownloadDocument from '../../hooks/useDownloadDocument';
import { Loader } from '../loader/SpinnerLogo';
import { DocumentsService } from '../../services/document/documentsService';
import { TutorService } from '../../services/tutor/tutorService';

interface RecentTutorDocumentsProps {
    updateList: boolean;
    setUpdateList: (value: boolean) => void;
}

const RecentTutorDocuments: React.FC<RecentTutorDocumentsProps> = ({ updateList, setUpdateList }) => {

    const { filters } = useFilters({}, [{ field: 'created_at', order: 'desc' }], 1, 15);
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const tutorService = new TutorService();
    const documentService = new DocumentsService();

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await tutorService.listMyDocuments(filters);
        return response.getResponseData();
    }, [filters]));

    useEffect(() => {
        if (updateList) {
            refetch();
            setUpdateList(false);
        }
    }, [updateList]);

    if (loading) return <Loader />;

    return (
        <>
            <Text className='pb-4 text-lg font-semibold'>Recientes</Text>

            {data && data.data.length > 0 && data.data.map((document: any) => (
                <div
                    key={document.document.id}
                    className="p-2 flex items-center justify-between mb-1 cursor-pointer rounded hover:bg-sky-100"
                    onClick={() => handleDownloadDocumentNewTab(documentService.downloadTutorDocument, document.document.id, document.document.originalName, document.document.extension)}
                >
                    <div className="flex items-center">
                        <span className="text-2xl mr-3">
                            {getIconByExtension(document.document?.extension)}
                        </span>
                        <div>
                            <p className="text-sm text-gray-800">{document.document.originalName}</p>
                            <p className="text-sm text-gray-500">Directorio: {document.document.subdirectory}</p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default RecentTutorDocuments;