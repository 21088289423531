import { useCallback, useEffect, useState } from 'react';
import { ContractGroupService } from '../../services/contract/contractGroupService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage contract groups fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de grupos de contratos.
----------------------------------------------------------------------------------------*/

const useContractGroups = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState(initialFilters);

    const fetchContractGroups = useCallback(async () => {
        try {
            const service = new ContractGroupService();
            const response = await service.listContractGroups({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching contract groups:', error);
        }
    }, []);

    useEffect(() => {
        fetchContractGroups();
    }, []);

    const updateContractGroupsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getContractGroupsList = () => {
        return data;
    };

    return { fetchContractGroups, getContractGroupsList, updateContractGroupsHookFilters };
}

export default useContractGroups;