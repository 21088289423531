import React, { useCallback } from 'react';
import TutorProfileLayout from '../../TutorProfileLayout';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import { Checkbox, Input, Textarea } from 'rizzui';
import { PiEnvelopeSimple } from 'react-icons/pi';
import FormGroup from '../../../../../layout/shared/form-group';
import cn from '../../../../../utils/classNames';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import { TutorService } from '../../../../../services/tutor/tutorService';
import { TutorApiResponse } from '../../../../../type/entities/tutor-type';
import { availability, paymentMethods, tutorType } from '../../../TutorForm';
import PhoneInput from '../../../../../components/forms/PhoneInputWrapper';
import { FaEuroSign, FaPercent } from 'react-icons/fa6';

interface TutorInfoProps { }

const TutorInfo: React.FC<TutorInfoProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const service = new TutorService();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getTutorById(id);
        return response.getResponseData() as TutorApiResponse;
    }, [id]));

    return (
        <TutorProfileLayout>
            <div className="@container">
                <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">
                    <FormGroup
                        title="Datos Personales"
                        description="Información personal del tutor"
                        className='pt-3 pb-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-5"
                    >
                        <Input
                            disabled
                            label="Nombre"
                            placeholder="First Name"
                            value={data?.name ?? ""}
                            className="flex-grow"
                        />
                        <Input
                            disabled
                            label="Apellidos"
                            placeholder="Last Name"
                            value={data?.lastName ?? ""}
                            className="flex-grow"
                        />
                        <Input
                            disabled
                            label="DNI"
                            placeholder="12345678Z"
                            value={data?.nifCif ?? ""}
                            className="flex-grow"
                        />
                        <Input
                            prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                            type="email"
                            label="Email"
                            placeholder="info@attiva.es"
                            value={data?.email ?? ""}
                            className='flex-grow md:col-span-2'
                            disabled
                        />
                        <Input
                            disabled
                            label="IBAN"
                            placeholder="ES1234567891234567891234"
                            value={data?.iban ?? ""}
                            className="flex-grow"
                        />
                        <PhoneInput
                            id="telephone"
                            disabled
                            label="Teléfono"
                            placeholder="123456789"
                            value={data?.telephone ?? ""}
                            className="flex-grow"
                        />
                        <PhoneInput
                            id="telephoneSecondary"
                            disabled
                            label="Teléfono secundario"
                            placeholder="123456789"
                            value={data?.telephoneSecondary ?? ""}
                            className="flex-grow"
                        />
                        <CustomSelect
                            id="tutorType"
                            label="Tipo de Tutor"
                            value={{
                                value: data?.tutorType,
                                label: tutorType.find((type) => type.value === data?.tutorType)?.label,
                            }}
                            isDisabled
                        />
                        <CustomSelect
                            isMulti
                            id="availability"
                            label="Disponibilidad"
                            value={{
                                value: data?.availability,
                                label: data?.availability.map((value: any) =>
                                    availability.find((type) => type.value === value)?.label
                                ),
                            }}
                            isDisabled
                        />
                        <Input
                            disabled
                            label="Usuario de la Plataforma"
                            placeholder="username"
                            value={data?.platformUser ?? ""}
                            className="flex-grow"
                        />
                        <Input
                            disabled
                            hidden={false}
                            label="Contraseña de la Plataforma"
                            placeholder="********"
                            value={data?.platformPassword ?? ""}
                            className="flex-grow"
                        />
                    </FormGroup>

                    <FormGroup
                        title="Cursos"
                        description="Cursos que el tutor puede impartir"
                        className={cn('pt-3 pb-4', data?.products?.length === 0 && "hidden")}
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-3"
                    >
                        {data?.products?.map((product: any, index: number) => (
                            <div key={product.id} className={cn("grid grid-cols-1 md:grid-cols-3 gap-3 pe-5", (index !== data.products.length - 1 && (index + 1) % 3 !== 0) && "border-e border-gray-200")}>
                                <Input
                                    disabled
                                    label="Producto"
                                    placeholder="Producto"
                                    value={product.product.name}
                                    className="md:col-span-2"
                                />
                                <Input
                                    disabled
                                    label="Precio"
                                    placeholder="Precio"
                                    suffix={<FaEuroSign />}
                                    value={product.pricePerStudent}
                                />
                                <CustomSelect
                                    id="paymentMethod"
                                    label="Método de Pago"
                                    value={{
                                        value: product.paymentMethod,
                                        label: paymentMethods.find((type) => type.value === product.paymentMethod)?.label,
                                    }}
                                    containerClassName='md:col-span-2'
                                    isDisabled
                                />
                                <Input
                                    disabled
                                    label="Retenciones"
                                    placeholder="Retenciones"
                                    suffix={<FaPercent />}
                                    value={product.deductions}
                                />
                            </div>
                        ))}
                    </FormGroup>

                    <FormGroup
                        title="Otros datos"
                        description="Información adicional de la cuenta"
                        className='pt-3 pb-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-5"
                    >
                        <Input
                            disabled
                            label="Dirección"
                            placeholder="Calle de la forja, 123"
                            value={data?.address ?? ""}
                            className="flex-grow md:col-span-2"
                        />
                        <Input
                            disabled
                            label="Población"
                            placeholder="Córdoba"
                            value={data?.town ?? ""}
                            className="flex-grow"
                        />
                        <Input
                            disabled
                            label="Provincia"
                            placeholder="Madrid"
                            value={data?.province ?? ""}
                            className="flex-grow"
                        />
                        <Input
                            disabled
                            label="Código Postal"
                            placeholder="12345"
                            value={data?.postalCode ?? ""}
                            className="flex-grow"
                        />
                        <Textarea
                            id="comments"
                            disabled
                            label="Comentarios"
                            rows={3}
                            className="col-span-1 md:col-span-4 [&>label>span]:font-medium"
                            value={data?.comments}
                        />
                        <Checkbox
                            id="collaborationSignature"
                            disabled
                            label="Firma de Colaboración"
                            className='md:mt-10'
                            checked={data?.collaborationSignature}
                        />
                    </FormGroup>
                </div>
            </div>
        </TutorProfileLayout>
    );
};

export default TutorInfo;