import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  id: string;
  token: string;
  name: string;
  lastName: string;
  email: string;
  roles: string[];
  profileImage: string;
  commercialNetwork?: string | null;
  companyName?: string | null;
}

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
  error: string | null;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<AuthState>) => {
      state = {
        ...action.payload
      }
      return state;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      return state;
    },
  }
});

export const loginThunk = createAsyncThunk('user/login', async (userData: any, { dispatch }) => {
  dispatch(login(userData));
});

export const logoutThunk = createAsyncThunk('user/logout', async (_, { dispatch }) => {
  dispatch(logout());
});

export const { logout, login } = authSlice.actions;

export default authSlice.reducer;