import React from 'react';
import FormGroup from '../../../layout/shared/form-group';
import CustomSelect from '../../../components/forms/CustomSelect';
import RequiredInput from '../../../components/forms/InputHOC';
import { Button } from 'rizzui';
import { FaEuroSign, FaRegTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import useProducts from '../../../hooks/api-calls/useProducts';
import classNames from 'classnames';

interface ComplementsFormProps {
    formik: any;
    complement: { productId: string, name: string, amount: string };
    setComplement: (complement: { productId: string, name: string, amount: string }) => void;
}

const ComplementsForm: React.FC<ComplementsFormProps> = ({ formik, complement, setComplement }) => {
    const { getProductsList } = useProducts({ active: true });

    return (
        <FormGroup
            title="Complementos"
            description="Productos asociados al producto principal"
            className='pt-6'
            titleCols="@md:col-span-2"
            childCols="@md:col-span-10"
        >
            <div className={classNames({ 'border-gray-500': formik.values.complements.length > 0 })}>
                {formik.values.complements.map((productItem: any, index: number) => {
                    return (
                        <div key={index} className="grid grid-cols-5 gap-4 mb-4">
                            <CustomSelect
                                id={`complements[${index}].id`}
                                required
                                label="Producto"
                                options={getProductsList().filter((p: any) => p.value !== formik.values.productId)}
                                onChange={(e: any) => {
                                    const updatedProducts = formik.values.complements.map((product: any, i: number) => {
                                        if (i === index) {
                                            return { ...product, id: e.value };
                                        }
                                        return product;
                                    });
                                    formik.setFieldValue('complements', updatedProducts);
                                }}
                                value={{ value: productItem.productId, label: getProductsList().find((p: any) => p.value === productItem.productId)?.label }}
                                containerClassName='col-span-2'
                                error={formik.errors.complements && formik.errors.complements[index]?.id}
                            />

                            <RequiredInput
                                id={`complements[${index}].name`}
                                label="Nombre"
                                formik={formik}
                                value={productItem.name}
                                error={formik.errors.complements && formik.errors.complements[index]?.name}
                            />

                            <RequiredInput
                                id={`complements[${index}].amount`}
                                type="number"
                                label="Importe"
                                formik={formik}
                                value={productItem.amount}
                                error={formik.errors.complements && formik.errors.complements[index]?.amount}
                                suffix={<FaEuroSign />}
                            />

                            <div className="flex justify-end items-end">
                                <Button
                                    color="danger"
                                    size='sm'
                                    onClick={() => {
                                        const updatedProducts = formik.values.complements.filter((product: any, i: number) => i !== index);
                                        formik.setFieldValue('complements', updatedProducts);
                                    }}
                                >
                                    <FaRegTrashAlt />
                                </Button>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className='col-span-3'>
                <div className="grid grid-cols-5 gap-4 mb-4">
                    <CustomSelect
                        id='complementId'
                        isClearable
                        required
                        label="Producto"
                        options={getProductsList().filter((p: any) => p.value !== formik.values.productId)}
                        onChange={(e: any) => { setComplement({ productId: e?.value, name: '', amount: '' }) }}
                        value={{ value: complement.productId, label: getProductsList().find((p: any) => p.value === complement.productId)?.label }}
                        containerClassName='col-span-2'
                    />

                    <RequiredInput
                        id='name'
                        label="Nombre"
                        formik={formik}
                        onChange={(e: any) => setComplement({ productId: complement.productId, name: e.target.value, amount: complement.amount })}
                        value={complement.name}
                    />

                    <RequiredInput
                        id='amount'
                        type="number"
                        label="Importe"
                        suffix={<FaEuroSign />}
                        formik={formik}
                        onChange={(e: any) => setComplement({ productId: complement.productId, name: complement.name, amount: e.target.value })}
                        value={complement.amount}
                    />

                    <div className="flex justify-end items-end">
                        <Button
                            color='secondary'
                            size='sm'
                            disabled={complement.productId === '' || (formik.values.tutorType === 'external' && complement.amount === '')}
                            onClick={() => {
                                formik.setFieldValue('complements', [...formik.values.complements, complement]);
                                setComplement({ productId: '', name: '', amount: '' });
                            }}
                        >
                            <MdAdd className='h-5 w-5' />
                        </Button>
                    </div>
                </div>
            </div>
        </FormGroup>
    );
};

export default ComplementsForm;