export const translations: any = {
    id: 'ID',
    name: 'Nombre',
    originalName: 'Nombre',
    lastName: 'Apellidos',
    user: 'Usuario',
    email: 'Email',
    address: 'Dirección',
    cif: 'CIF',
    commercialNetwork: 'Red comercial',
    accessThrowCommercialNetworkIp: 'Acceso IP',
    userRoles: 'Rol',
    department: 'Departamento',
    logo: 'Logo',
    profileImg: 'Imagen de perfil',
    active: 'Activo',
    createdAt: 'Creación',
    date: 'Fecha',
    totalAmount: 'Precio',
    currentContractStatusDate: 'Fecha Estado',
    currentContractStatus: 'Estado Contrato',
    currentContractProductStatus: 'Producto Contrato',
    currentContractInvoicingStatus: 'Facturación Contrato',
    currentContractIncidenceStatus: 'Incidencia Contrato',
    currentIncidenceStatus: 'Estado Incidencia',
    currentInvoicingStatus: 'Estado Factura',
    currentProductStatus: 'Estado Producto',
    product: 'Producto',
    clientProvince: 'Provincia Cliente',
    clientCifNif: 'CIF/NIF Cliente',
    updatedAt: 'Actualización',
    lastLogin: 'Último acceso',
    lastLoginAt: 'Último acceso',
    invoiceNumber: 'Nº factura',
    expirationDate: 'Fecha de expiración',
    paymentDate: 'Fecha de pago',
    invoiceDate: 'Fecha de factura',
    totalPrice: 'Precio total',
    totalProducts: 'Total productos',
    tax: 'IVA',
    totalTaxesAmount: 'Total Impuestos',
    taxableAmount: 'Base Imponible',
    paymentAt: 'Fecha de pago',
    corporation: 'Empresa',
    entityType: 'Entidad',
    documentType: 'Tipo de documento',
    subdirectory: 'Directorio',
    currentStatus: 'Estado',
    status: 'Estado',
    company: 'Organización',
    description: 'Descripción',
    statusOrder: 'Orden',
    productType: 'Tipo de producto',
    draftStatus: 'Borrador',
    initialStatus: 'Inicial',
    satisfactoryStatus: 'Satisfactorio',
    unsatisfactoryStatus: 'Insatisfactorio',
    clientInvoiceStatus: 'Factura cliente',
    tutorInvoiceStatus: 'Factura tutor',
    internalInvoiceStatus: 'Factura interna',
    code: 'Código',
    weeklyHours: 'Horas semanales',
    amount: 'Importe',
    nifCif: 'NIF/CIF',
    telephone: 'Teléfono',
    telephoneSecondary: 'Tfno. secundario',
    comments: 'Comentarios',
    documents: 'Documentos',
    availability: 'Disponibilidad',
    platformUser: 'Usuario de plataforma',
    postalCode: 'Código postal',
    province: 'Provincia',
    town: 'Localidad',
    tutorType: 'Tipo de tutor',
    gender: 'Género',
    birthDate: 'Fecha de nacimiento',
    genderViolenceVictim: 'Víctima de violencia de género',
    terrorismVictim: 'Víctima de terrorismo',
    client: 'Cliente',
    other: 'Otros',
    companyName: 'Razón social',
    clientCompanyName: 'Razón social',
    cifNif: 'CIF/NIF',
    cnae: 'CNAE',
    currentCredits: 'Créditos',
    personResponsibleName: 'Nombre (responsable)',
    personResponsibleNif: 'NIF (responsable)',
    lastComment: 'Último comentario',
    comment: 'Comentario',
    credits: 'Créditos',
    entityOrder: 'Orden',
    modifiedAt: 'Fecha de modificación',
    contact: 'Persona de contacto',
};

export const imageFields = [
    'logo',
    'profileImg',
];

export const translateColumnName = (key: any) => {
    // Convierte camelCase a "Camel Case"
    const formattedKey = key.replace(/([A-Z])/g, ' $1').trim();

    // Retorna la traducción si existe, o el nombre formateado
    return translations[key] || formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
};