import { useCallback, useEffect, useState } from 'react';
import { ContractService } from '../../services/contract/contractService';

/*--------------------------------------------------------------------------------------------
* EN: Hook to manage product complements fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de complementos de producto.
---------------------------------------------------------------------------------------------*/

const useProductComplements = () => {

    const [data, setData] = useState<any[]>([]);

    const fetchProductComplements = useCallback(async (productId: string) => {
        try {
            const response = await (new ContractService).getProductComplements(productId);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData) {
                const mappedData = fetchedData?.map((data: { id: string; name: string; amount: number }) => ({
                    value: data.id,
                    label: data.name,
                    amount: data.amount,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching product complements:', error);
        }
    }, []);

    const getProductComplementsList = () => {
        return data;
    };

    return { fetchProductComplements, getProductComplementsList };
}

export default useProductComplements;