import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ContractProfileState {
    selectedView: string;
}

const initialState: ContractProfileState = {
    selectedView: '/info',
};

const contractProfileSlice = createSlice({
    name: 'contractProfile',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<string>) {
            state.selectedView = action.payload;
        },
    },
});

export const { setSelectedView } = contractProfileSlice.actions;
export default contractProfileSlice.reducer;