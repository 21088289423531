import { RestServiceConnection } from "../restServiceConnection";

const CNAE_ENDPOINT = '/cnae';

export class CNAEService extends RestServiceConnection {

    public async getGroups() {
        this.response = await this.makeRequest({
            method: 'GET',
            url: CNAE_ENDPOINT + '/groups',
        }, true);
        return this;
    }

    public async getSubGroups(subgroup: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CNAE_ENDPOINT + '/subgroups',
            data: { subgroup }
        }, true);
        return this;
    }

    public async getCategories(category: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CNAE_ENDPOINT + '/categories',
            data: { category }
        }, true);
        return this;
    }

    public async getActivities(activity: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CNAE_ENDPOINT + '/activities',
            data: { activity }
        }, true);
        return this;
    }

    public async searchCNAEs(searchText: string) {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CNAE_ENDPOINT + '/search',
            data: { searchText }
        }, true);
        return this;
    }
}