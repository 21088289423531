import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    AreaChart,
    Area,
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
} from 'recharts';
import WidgetCard from '../../../../../components/card/WidgetCard';
import TutorProfileLayout from '../../TutorProfileLayout';

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

const radarData = [
    {
        subject: 'Contratos',
        A: 120,
        B: 100,
        fullMark: 150,
    },
    {
        subject: 'Clientes',
        A: 98,
        B: 130,
        fullMark: 150,
    },
    {
        subject: 'Facturas',
        A: 130,
        B: 86,
        fullMark: 150,
    },
    {
        subject: 'Productos',
        A: 75,
        B: 100,
        fullMark: 150,
    },
    {
        subject: 'Ventas',
        A: 85,
        B: 90,
        fullMark: 150,
    },
    {
        subject: 'Beneficios',
        A: 65,
        B: 85,
        fullMark: 150,
    },
];

interface TutorStatsProps { }

const TutorStats: React.FC<TutorStatsProps> = ({ }) => {
    return (
        <TutorProfileLayout>
            <div className="@container">
                <div className='grid grid-cols-2 gap-4'>
                    <div>
                        <WidgetCard title={'Estadísticas'}>
                            <LineChart width={700} height={300} data={data} className='pt-5'>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="pv"
                                    stroke="#8884d8"
                                    activeDot={{ r: 8 }}
                                />
                                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                            </LineChart>
                        </WidgetCard>

                        <WidgetCard title={'Contratos'}>
                            <AreaChart
                                width={700}
                                height={300}
                                data={data}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                className='pt-5'
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                                <Area type="monotone" dataKey="amt" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                            </AreaChart>
                        </WidgetCard>
                    </div>

                    <WidgetCard title={'Radar de ventas'}>
                        <RadarChart cx={300} cy={250} outerRadius={150} width={600} height={500} data={radarData}>
                            <PolarGrid />
                            <PolarAngleAxis dataKey="subject" />
                            <PolarRadiusAxis />
                            <Radar name="Rafael Cabezas" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                            <Radar name="Pedro Rodríguez" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                            <Legend />
                        </RadarChart>
                    </WidgetCard>
                </div>
            </div>
        </TutorProfileLayout>
    );
};

export default TutorStats;