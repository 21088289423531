import React from 'react';
import PageHeader from '../../layout/shared/page-header';
import HorizontalFormBlockWrapper from '../../components/forms/HorizontalFormBlockWrapper';
import RequiredInput from '../../components/forms/InputHOC';
import { menuRoutes } from '../../router/menu';
import { useSettingsProvider } from './provider/SettingsProvider';
import FormFooter from '../_layout/_footers/form-footer';

const InvoicingsSettingsPage: React.FC = () => {

    const {formik, isLoading, companyConfig, hasCompanyConfigChanges, updateCompanyConfig, setShowModal, discardCompanyConfigChanges} = useSettingsProvider();

    const pageHeader = {
        title: 'Ajustes en Facturas',
        breadcrumb: [
            { name: 'Inicio', href: menuRoutes.dashboard.path },
            { name: 'Configuración' },
            { name: 'Facturas', href: menuRoutes.settings.path + menuRoutes.settings.invoices },
        ]
    }
    return (
        <div className='h-full overflow-auto scrollbar '>
            <PageHeader
                title={pageHeader.title}
                breadcrumb={pageHeader.breadcrumb}
                className='border-b-4 border-dotted border-grey-500 top-0'
            />
            <HorizontalFormBlockWrapper
                title="Formato de las Facturas"
                description="Para personalizar el nombre, ten en cuenta que las variables se encuentra de la siguiente manera: {{VARIABLE}}."
                descriptionClassName="max-w-[50%]"
                childrenWrapperClassName="@3xl:grid-cols-1 max-w-[50%] w-full"
            >
                <div className='pb-4'>
                    <RequiredInput
                        required={true}
                        id="invoiceName"
                        label="Nombre de la Factura"
                        value={companyConfig?.invoiceCodeFormat}
                        onChange={(e) => {
                            updateCompanyConfig('invoiceCodeFormat', e.target.value);
                            formik.setFieldValue('invoiceCodeFormat', e.target.value);
                        }}
                        error={formik.errors.invoiceCodeFormat}
                    />
                </div>
            </HorizontalFormBlockWrapper>
            {hasCompanyConfigChanges && (
                <FormFooter
                    customClassName={"w-full"}
                    submitBtnText={"Aplicar Cambios"}
                    cancelBtnText={"Descartar Cambios"}
                    handleCancelBtn={() => discardCompanyConfigChanges()}
                    handleSubmitBtn={() => setShowModal(true)}
                    isLoading={isLoading}
                    isDisabled={Object.keys(formik.errors).length > 0}
                />
                
            )}
        </div>
    );
};

export default InvoicingsSettingsPage;