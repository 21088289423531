import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import PageHeader from "../../layout/shared/page-header";
import { traductions } from "../../utils/traductions";
import ConfigurationsList from "./ConfigurationsList";
const ConfigListWrapper = () => {

    const location = useLocation();
    const entity = location.pathname.split('/')[2];

    const breadCrumb = [
        { name: 'Administración' },
        { name: 'Configuración General' },
        { name: traductions.find(trad => trad.key === entity)?.plural || '' }
    ];

    return (
        <Fragment >
            <FiltersProvider>
                <PageHeader title={`Listado de ${traductions.find(trad => trad.key === entity)?.plural}`} breadcrumb={breadCrumb} />
                <ConfigurationsList />
            </FiltersProvider>
        </Fragment>
    );
}

export default ConfigListWrapper;