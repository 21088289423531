import { useCallback, useEffect, useState } from 'react';
import { ContractService } from '../../../services/contract/contractService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage client Consultancy history fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación del historial de asesorias de clientes.
----------------------------------------------------------------------------------------*/

const useClientConsultanciesHistory = (initialFilters = {}, limit = 99999) => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMoreResults, setIsMoreResults] = useState<boolean>(false);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: limit,
        filter_filters: { ...initialFilters }
    });

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const service = new ContractService();
            const response = await service.showClientConsultaciesHistory(filters);
            const fetchedData = response.getResponseData().data;

            setIsMoreResults(fetchedData?.length >= filters.limit);

            if (fetchedData) {
                const mappedData = fetchedData?.map((data: any) => ({
                    id: data.id,
                    title: data.name,
                    email: data.email,
                    description: data?.description || '',
                    createdAt: data?.createdAt?.date?.toString().split(' ')[0],
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching client consultancies history:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filters]);

    const getList = (entityId?: string) => {
        if (entityId) return data.filter((state: any) => state.id === entityId);
        return data;
    };

    const addFilter = (key: string, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                [key]: value
            }
        }));
    };

    const updateClientId = (value: string) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                client: value
            }
        }));
    }

    const modifyLimit = (amount = 5) => {
        if (filters.limit + amount >= 5) {
            setFilters(prevFilters => ({
                ...prevFilters,
                limit: prevFilters.limit + amount
            }));
        }
    }

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    }

    return { fetchClientConsultancyHistory: fetchData, getClientConsultanciesList: getList, modifyLimitClientConsultancy: modifyLimit, addClientConsultancyFilter: addFilter, getClientConsultancy: getEntity, updateClientId, isMoreClientConsultancies: isMoreResults, isLoadingClientConsultancies: isLoading };
}

export default useClientConsultanciesHistory;