import { CampaignService } from "../services/campaign/campaignService";
import { EducationService } from "../services/education/educationService";
import { ProfessionalCategoryService } from "../services/professional-category/professionalCategoryService";
import { QuotationGroupService } from "../services/quotation-group/quotationGroupService";

export const traductions = [
    {
        key: "campaigns",
        value: "campaña",
        plural: "Campañas",
        list_service: (new CampaignService).listCampaigns,
        create_service: (new CampaignService).createCampaign,
        edit_service: (new CampaignService).editCampaign,
        delete_service: (new CampaignService).deleteCampaign,
        delete_multi_service: (new CampaignService).deleteMultiCampaigns,
        update_order_service: (new CampaignService).updateCampaignOrder,
        toggle_service: (new CampaignService).toggleCampaign,
    },
    {
        key: "studies",
        value: "nivel educativo",
        plural: "Niveles Educativos",
        list_service: (new EducationService).listEducation,
        create_service: (new EducationService).createEducation,
        edit_service: (new EducationService).editEducation,
        delete_service: (new EducationService).deleteEducation,
        delete_multi_service: (new EducationService).deleteMultiEducation,
        update_order_service: (new EducationService).updateEducationOrder,
        toggle_service: (new EducationService).toggleEducation,
    },
    {
        key: "professional_categories",
        value: "categoría profesional",
        plural: "Categorías Profesionales",
        list_service: (new ProfessionalCategoryService).listProfessionalCategories,
        create_service: (new ProfessionalCategoryService).createProfessionalCategory,
        edit_service: (new ProfessionalCategoryService).editProfessionalCategory,
        delete_service: (new ProfessionalCategoryService).deleteProfessionalCategory,
        delete_multi_service: (new ProfessionalCategoryService).deleteMultiProfessionalCategories,
        update_order_service: (new ProfessionalCategoryService).updateProfessionalCategoryOrder,
        toggle_service: (new ProfessionalCategoryService).toggleProfessionalCategory,
    },
    {
        key: "quotation_groups",
        value: "grupo de cotización",
        plural: "Grupos de Cotización",
        list_service: (new QuotationGroupService).listQuotationGroups,
        create_service: (new QuotationGroupService).createQuotationGroup,
        edit_service: (new QuotationGroupService).editQuotationGroup,
        delete_service: (new QuotationGroupService).deleteQuotationGroup,
        delete_multi_service: (new QuotationGroupService).deleteMultiQuotationGroups,
        update_order_service: (new QuotationGroupService).updateQuotationGroupOrder,
        toggle_service: (new QuotationGroupService).toggleQuotationGroup,
    },
];