export const ReactSelectStyles = {
    control: (base: any, state: any) => ({
        ...base,
        border: '1px !important',
        borderRadius: '0.375rem',
        borderColor: 'rgb(229 231 235) !important',
        boxShadow: state.isFocused ? '0 0 0 2px rgb(0, 172, 216)' : '0 0 0 1px rgba(0,0,0, 0.2) !important',
        backgroundColor: 'transparent',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgb(0, 172, 216)' : 'white',
        color: state.isFocused ? 'white' : 'black',
        '&:hover': {
            backgroundColor: 'rgb(0, 172, 216)',
            color: 'white',
            borderColor: '#000000 !important'
        }
    }),
}