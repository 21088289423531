import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import ClientProfileLayout from '../../ClientProfileLayout';
import { ClientService } from '../../../../../services/client/clientService';
import useFilters from '../../../../../hooks/useFilters';
import CustomTable from '../../../../../components/table/CustomTable';
import moment from 'moment';
import { ActionIcon, Button, Tooltip, cn } from 'rizzui';
import { menuRoutes } from '../../../../../router/menu';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import { toast } from 'sonner';
import { StudentService } from '../../../../../services/student/StudentService';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { StudentsApiResponse } from '../../../../../type/entities/student-type';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import StudentsFilters from '../../../../students/list/StudentsFilters';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';
import { Text } from 'rizzui';
import { HiOutlineEye } from 'react-icons/hi2';

interface ClientStudentsProps { }

const columnOrder = [
    'id',
    'name',
    'firstName',
    'lastName',
    'nif',
    'gender',
    'birthDate',
    'disability',
    'genderViolenceVictim',
    'terrorismVictim',
    'other',
    'createdAt',
    'updatedAt',
];

const ClientStudents: React.FC<ClientStudentsProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const service = new ClientService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters({ client: id });

    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.lisStudents(filters);
        return response.getResponseData() as StudentsApiResponse;
    }, [id, filters]));

    const handleDelete = async (id: string) => {
        const response = await (await (new StudentService).deleteStudent(id)).getResponseData();
        if (response.success) {
            toast.success('Empleado eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await (new StudentService).deleteMultiStudents(ids)).getResponseData();
        if (response.success) {
            toast.success('Empleados eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <ClientProfileLayout isLoading={loading}>
            {data !== undefined && (
                <div className="pt-4">
                    <CustomTable
                        id={'students-table'}
                        columnOrder={columnOrder}
                        data={orderedData()}
                        isLoading={loading}
                        columnsNotShown={['firstName', 'lastName', 'disability', 'genderViolenceVictim', 'terrorismVictim']}
                        overrideColumns={[
                            {
                                key: 'name',
                                render: (row: any) => {
                                    return (
                                        userCan('get_students', 'students')
                                            ? (
                                                <Link to={`${menuRoutes.students.path}/${row.id}/profile${menuRoutes.students.profile.info}`}>
                                                    <Text className='font-bold text-primary'>{`${row.row.original.name} ${row.row.original.firstName || ''} ${row.row.original.lastName || ''}`}</Text>
                                                </Link>
                                            )
                                            : <Text className='font-bold text-primary'>`${row.row.original.name} ${row.row.original.firstName || ''} ${row.row.original.lastName || ''}`</Text>
                                    )
                                },
                            },
                            {
                                key: 'gender',
                                render: (row: any) => {
                                    return ((row.row.original.gender === 'male') ? 'Masculino' : (row.row.original.gender === 'female' ? 'Femenino' : 'N/A'));
                                },
                            },
                            {
                                key: 'other',
                                render: (row: any) => {
                                    return (
                                        <ul className='list-disc text-nowrap'>
                                            {row.row.original.disability && <li>Discapacidad</li>}
                                            {row.row.original.genderViolenceVictim && <li>Victima de violencia de género</li>}
                                            {row.row.original.terrorismVictim && <li>Victima de terrorismo</li>}
                                            {!row.row.original.genderViolenceVictim && !row.row.original.terrorismVictim && !row.row.original.disability && 'N/A'}
                                        </ul>
                                    );
                                },
                            },
                            {
                                key: 'createdAt',
                                render: (row: any) => {
                                    const date = row.row.original?.createdAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                            {
                                key: 'updatedAt',
                                render: (row: any) => {
                                    const date = row.row.original?.updatedAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                        ]}
                        actions={[
                            {
                                label: 'View',
                                permissions: { group: 'students', permission: 'get_students' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Ver perfil'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link
                                                to={`${menuRoutes.students.path}/${row.id}/profile${menuRoutes.students.profile.info}`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <HiOutlineEye className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Edit',
                                permissions: { group: 'students', permission: 'edit_students' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar empleado'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link to={`${menuRoutes.students.path}/${row.id}/edit`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Delete',
                                permissions: { group: 'students', permission: 'delete_students' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Eliminar'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>
                                                <DeletePopover
                                                    title={`Eliminar empleado`}
                                                    description={`¿Estás seguro de que deseas eliminar a ${row.name}?`}
                                                    onDelete={() => handleDelete(row.id)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        handleMultipleDelete={handleMultiDelete}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as StudentsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    >
                        {userCan('create_students', 'students') && (
                            <Button
                                color="primary"
                                onClick={() => { navigate(`${menuRoutes.students.path}/${menuRoutes.students.create}`, { replace: true }) }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Crear empleado
                            </Button>
                        )}
                    </CustomTable>
                </div>
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Empleados'}>
                <StudentsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </ClientProfileLayout>
    );
};

export default ClientStudents;