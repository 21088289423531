import React from 'react';

interface FormLabelProps {
    label: string;
    children: React.ReactNode;
}

const FormLabel: React.FC<FormLabelProps> = ({ label, children }) => {
    return (
        <label className='block'>
            <span className='rizzui-input-label block text-sm mb-1.5 font-medium'>{label}</span>
            {children}
        </label>
    );
};

export default FormLabel;