import { Fragment } from "react";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import MyInvoices from "./MyInvoices";

const ListMyInvoicesWrapper = () => {

    const breadCrumb = [
        { name: 'Panel de control del tutor' },
        { name: 'Mis facturas', href: menuRoutes.tutor_panel.invoices },
    ];

    const defaultFilters = {
        active: true,
    };

    return (
        <Fragment>
            <FiltersProvider defaultFilterFilters={defaultFilters}>
                <PageHeader title="Listado de mis facturas" breadcrumb={breadCrumb} />
                <MyInvoices />
            </FiltersProvider>
        </Fragment>
    );
}

export default ListMyInvoicesWrapper;