import { useCallback, useEffect, useState } from 'react';
import { ContractService } from '../../../services/contract/contractService';
import { format, parseISO } from 'date-fns';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage client Comments history fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación del historial de contratos de clientes.
----------------------------------------------------------------------------------------*/

const useClientCommentsHistory = (initialFilters = {}, initialOrders = {}, limit = 99999) => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMoreResults, setIsMoreResults] = useState<boolean>(false);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: limit,
        filter_filters: { ...initialFilters },
        filter_orders: { ...initialOrders, createdAt: 'DESC' },
    });

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const service = new ContractService();
            const response = await service.showCommentsOnClient(filters);
            const fetchedData = response.getResponseData().data;

            setIsMoreResults(fetchedData?.data?.length >= filters.limit);

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: any) => ({
                    id: data.id,
                    title: data.comment,
                    date: format(parseISO(data.createdAt.date), 'yyyy-MM-dd HH:mm'),
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching client Comments history:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filters]);

    const getList = (entity?: string) => {
        if (entity) return data.filter((state: any) => state.entity === entity);
        return data;
    };

    const addFilter = (key: string, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                [key]: value
            }
        }));
    };

    const modifyLimit = (amount = 5) => {
        if (filters.limit + amount >= 5) {
            setFilters(prevFilters => ({
                ...prevFilters,
                limit: prevFilters.limit + amount
            }));
        }
    }

    const updateClientId = (value: string) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                client: value
            }
        }));
    }

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    }

    return { fetchClientCommentHistory: fetchData, getClientCommentsList: getList, modifyLimitClientComments: modifyLimit, addClientCommentsFilter: addFilter, getClientComment: getEntity, updateClientId, isMoreClientComments: isMoreResults, isLoadingClientComments: isLoading };
}

export default useClientCommentsHistory;