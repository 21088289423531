import { useCallback, useEffect, useState } from 'react';
import { ContractService } from '../../../services/contract/contractService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage contract corporations fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de empresas de contrato.
----------------------------------------------------------------------------------------*/

const useContractCorporation = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState(initialFilters);

    const fetchContractCorporation = useCallback(async () => {
        try {
            const service = new ContractService();
            const response = await service.findCorporationsOnContract({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching contracts corporations:', error);
        }
    }, []);

    useEffect(() => {
        fetchContractCorporation();
    }, []);

    const updateContractCorporationHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getContractCorporationList = () => {
        return data;
    };

    return { fetchContractCorporation, getContractCorporationList, updateContractCorporationHookFilters };
}

export default useContractCorporation;