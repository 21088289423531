import React from 'react';
import { FilterOptions } from '../../../hooks/useFilters';
import CustomSelect from '../../../components/forms/CustomSelect';
import { Button } from 'rizzui';
import { PiTrashDuotone } from 'react-icons/pi';
import DateField from '../../../components/forms/DateField';
import { isEqual } from 'lodash';
import CustomSearchInput from '../../../components/forms/CustomSearchInput';
import FormLabel from '../../../components/forms/FormLabel';
import useConsultancies from '../../../hooks/api-calls/useConsultancies';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import SelectProvinceFilter from '../../../components/forms/SelectProvinceFilter';

interface ClientsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const ClientsFilters: React.FC<ClientsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { userCan } = usePrivilege();
    const { getConsultanciesList } = useConsultancies();

    return (
        <>
            <CustomSelect
                isSearchable
                id={'active'}
                label="Estado"
                options={[
                    { label: 'Todos', value: null },
                    { label: 'Activos', value: true },
                    { label: 'Desactivados', value: false }
                ]}
                onChange={(e: any) => {
                    if (e.value === null) {
                        updateFilters({ active: null });
                        return;
                    }
                    updateFilters({ active: e.value });
                }}
                value={filters.filter_filters?.active !== null
                    ? { label: filters.filter_filters?.active ? 'Activo' : 'Desactivado', value: filters.filter_filters?.active }
                    : { label: 'Todos', value: null }}
            />

            <FormLabel label='CNAE'>
                <CustomSearchInput onSearch={(e) => updateFilters({ cnae: e })} defaultValue={filters.filter_filters?.cnae || ''} />
            </FormLabel>

            <FormLabel label='Actividad'>
                <CustomSearchInput onSearch={(e) => updateFilters({ activity_description: e })} defaultValue={filters.filter_filters?.activity_description || ''} />
            </FormLabel>

            <SelectProvinceFilter label='Provincia del domicilio social' id='registered_office_province' filters={filters} updateFilters={updateFilters} />

            <FormLabel label='Población del domicilio social'>
                <CustomSearchInput onSearch={(e) => updateFilters({ registered_office_town: e })} defaultValue={filters.filter_filters?.registered_office_town || ''} />
            </FormLabel>

            <FormLabel label='C.P. del domicilio social'>
                <CustomSearchInput onSearch={(e) => updateFilters({ registered_office_postal_code: e })} defaultValue={filters.filter_filters?.registered_office_postal_code || ''} />
            </FormLabel>

            <SelectProvinceFilter label='Provincia del responsable' id='person_responsible_province' filters={filters} updateFilters={updateFilters} />

            <FormLabel label='Población del responsable'>
                <CustomSearchInput onSearch={(e) => updateFilters({ person_responsible_town: e })} defaultValue={filters.filter_filters?.person_responsible_town || ''} />
            </FormLabel>

            <FormLabel label='C.P. del responsable'>
                <CustomSearchInput onSearch={(e) => updateFilters({ person_responsible_postal_code: e })} defaultValue={filters.filter_filters?.person_responsible_postal_code || ''} />
            </FormLabel>

            <CustomSelect
                isSearchable
                isMulti
                id={'consultancies'}
                label="Asesoría"
                options={getConsultanciesList()}
                onChange={(e: any) => { updateFilters({ consultancies: e.map((d: any) => d.value) }) }}
                value={
                    filters.filter_filters?.consultancies
                        ? getConsultanciesList().filter((d: any) => filters.filter_filters?.consultancies.includes(d.value))
                        : []
                }
                display={userCan('list_consultancies', 'consultancies')}
            />

            <FormLabel label='Comentarios'>
                <CustomSearchInput onSearch={(e) => updateFilters({ comments: e })} defaultValue={filters.filter_filters?.comments || ''} />
            </FormLabel>

            <DateField
                isClearable
                className="w-full"
                label="Fecha de Creación"
                placeholderText="Selececiona las fechas"
                selectedDateField={filters.filter_filters?.between_dates?.type || "createdAt"}
                dateTypesOptions={[
                    { label: "Fecha de creación", value: "createdAt" },
                    { label: "Fecha de modificación", value: "updatedAt" },
                    { label: "Último acceso", value: "lastLoginAt" }
                ]}
                startDate={filters.filter_filters?.between_dates?.startDate ? new Date(filters.filter_filters.between_dates.startDate) : null}
                endDate={filters.filter_filters?.between_dates?.endDate ? new Date(filters.filter_filters.between_dates.endDate) : null}
                onChange={(date: any) => {
                    const filters_between_dates = filters.filter_filters?.between_dates;
                    const between_dates = date ? { startDate: date[0], endDate: date[1] } : null;

                    if (between_dates && !isEqual(filters_between_dates, between_dates)) {
                        updateFilters({ between_dates: { startDate: date[0], endDate: date[1], type: date[2] } });
                    } else if (!between_dates && filters_between_dates) {
                        updateFilters({ between_dates: null });
                    }
                }}
            />

            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default ClientsFilters;