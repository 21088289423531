import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import FormGroup from '../../layout/shared/form-group';
import RequiredInput from '../../components/forms/InputHOC';
import Page from '../../layout/Page/Page';
import FormFooter from '../_layout/_footers/form-footer';
import { validateCifNif, validateSS } from '../../utils/validatorFunctions';
import IBAN from 'iban';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from '../../components/forms/PhoneInputWrapper';
import { Client } from '../../type/entities/client-type';
import { CNAEModalSelector } from '../../components/forms/CNAEModalSelector';
import { IoSearch } from 'react-icons/io5';
import CustomSelect from '../../components/forms/CustomSelect';
import useConsultancies from '../../hooks/api-calls/useConsultancies';
import SelectProvince from '../../components/forms/SelectProvince';

interface ClientFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Client | undefined;
}

const schema = yup.object({
    cifNif: yup.string().test('valid-cif-nif', 'El CIF/NIF no es válido', (value) => {
        if (value !== undefined && value.length > 0) {
            return validateCifNif(value);
        } else {
            return true;
        }
    }).required('Campo obligatorio'),
    companyName: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').required('Campo obligatorio'),
    registeredOfficeAddress: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    registeredOfficeTown: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    registeredOfficeProvince: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    registeredOfficePostalCode: yup.string().matches(/^[0-9]{5}$/, 'Código postal no válido').required('Campo obligatorio'),
    cnae: yup.string().matches(/^[0-9]{4}$/, 'CNAE no válido').required('Campo obligatorio'),
    activityDescription: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    iban: yup.string().test('iban', 'El formato de IBAN no es válido', (value: any) => IBAN.isValid(value)),
    email: yup.string().email('Email no válido').required('Campo obligatorio'),
    telephone: yup.string().required('El teléfono es obligatorio').test("valid-phone", "El teléfono no es válido", (value) => isValidPhoneNumber(value)),
    telephoneSecondary: yup.string().nullable().notRequired().nullable(),
    consultancyId: yup.string().nullable(),
    personResponsibleName: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    personResponsibleNif: yup.string().test('valid-cif-nif', 'El CIF/NIF no es válido', (value) => {
        if (value !== undefined && value.length > 0) {
            return validateCifNif(value);
        } else {
            return true;
        }
    }).required('Campo obligatorio'),
    personResponsibleEmail: yup.string().email('Email no válido').required('Campo obligatorio'),
    personResponsibleTelephone: yup.string().required('El teléfono es obligatorio').test("valid-phone", "El teléfono no es válido", (value) => isValidPhoneNumber(value)),
    personResponsibleTelephoneSecondary: yup.string().nullable().notRequired().nullable(),
    companyCreationDate: yup.string().nullable(),
    socialSecurityNumber: yup.string().test('valid-ss', 'El número de la SS no es válido', (value) => {
        if (value !== undefined && value.length > 0) {
            return validateSS(value);
        } else {
            return true;
        }
    }).nullable(),
    workersNumber: yup.number().nullable().min(0, 'Número de trabajadores no válido'),
});

const ClientForm: React.FC<ClientFormProps> = ({ isLoading, submit, data }) => {

    const mode = data ? 'Editar' : 'Crear';
    const navigate = useNavigate();
    const { getConsultanciesList } = useConsultancies({ active: true });

    const [CNAESelectorVisible, setCNAESelectorVisible] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            clientId: data?.id ?? '',
            cifNif: data?.cifNif ?? '',
            companyName: data?.companyName ?? '',
            registeredOfficeAddress: data?.registeredOfficeAddress ?? '',
            registeredOfficeTown: data?.registeredOfficeTown ?? '',
            registeredOfficeProvince: data?.registeredOfficeProvince ?? '',
            registeredOfficePostalCode: data?.registeredOfficePostalCode ?? '',
            cnae: data?.cnae ?? '',
            activityDescription: data?.activityDescription ?? '',
            iban: data?.iban ?? '',
            email: data?.email ?? '',
            telephone: data?.telephone ?? '',
            telephoneSecondary: data?.telephoneSecondary ?? '',
            consultancyId: data?.consultancy?.id ?? '',
            personResponsibleName: data?.personResponsibleName ?? '',
            personResponsibleNif: data?.personResponsibleNif ?? '',
            personResponsibleEmail: data?.personResponsibleEmail ?? '',
            personResponsibleTelephone: data?.personResponsibleTelephone ?? '',
            personResponsibleTelephoneSecondary: data?.personResponsibleTelephoneSecondary ?? '',
            companyCreationDate: data?.companyCreationDate?.date.split(' ')[0] ?? '',
            socialSecurityNumber: data?.socialSecurityNumber ?? '',
            workersNumber: data?.workersNumber ?? 0,
        },
        validationSchema: schema,
        validateOnBlur: false,
        //validateOnChange: false,
        onSubmit: values => { submit(values) },
    });

    /**
     * Validate form when the cnae and the activity are filled
     */
    useEffect(() => {
        if (formik.values.cnae && formik.values.activityDescription) {
            formik.validateForm();
        }
    }, [formik.values.cnae, formik.values.activityDescription]);

    const getContent = () => {
        return (
            <>
                <div className="@container">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200 font-medium">
                            <FormGroup
                                title="Información General"
                                description="Datos principales"
                                titleCols="@md:col-span-2"
                                childCols="@md:col-span-10 md:grid-cols-4"
                            >
                                <RequiredInput
                                    id='cifNif'
                                    label="CIF/NIF"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='companyName'
                                    label="Razón Social"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='email'
                                    type="email"
                                    label="Email"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='cnae'
                                    readOnly
                                    label="CNAE"
                                    suffix={<IoSearch className="cursor-pointer" size={'20px'} onClick={() => { setCNAESelectorVisible(true) }} />}
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='activityDescription'
                                    readOnly
                                    label="Descripción de la actividad"
                                    className="md:col-span-2"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='iban'
                                    label="IBAN"
                                    formik={formik}
                                />

                                <PhoneInput
                                    id='telephone'
                                    label='Teléfono'
                                    onBlur={formik.handleBlur}
                                    onChange={e => { formik.setFieldValue('telephone', e) }}
                                    value={formik.values.telephone}
                                    error={formik.errors.telephone}
                                />

                                <PhoneInput
                                    id='telephoneSecondary'
                                    label='Teléfono Secundario'
                                    onBlur={formik.handleBlur}
                                    onChange={e => { formik.setFieldValue('telephoneSecondary', e) }}
                                    value={formik.values.telephoneSecondary}
                                    error={formik.errors.telephoneSecondary}
                                    required={false}
                                />

                                <CustomSelect
                                    isSearchable
                                    isClearable
                                    id={'consultancyId'}
                                    label="Asesoría"
                                    options={getConsultanciesList()}
                                    onChange={(e: any) => formik.setFieldValue('consultancyId', e ? e.value : '')}
                                    value={formik.values.consultancyId ? { value: formik.values.consultancyId, label: getConsultanciesList().find((c: any) => c.value === formik.values.consultancyId)?.label } : null}
                                />

                                <RequiredInput
                                    id='companyCreationDate'
                                    type="date"
                                    label="Fecha de creación"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='socialSecurityNumber'
                                    label="Número de Seguridad Social"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='workersNumber'
                                    type="number"
                                    label="Número de trabajadores"
                                    formik={formik}
                                />
                            </FormGroup>

                            <FormGroup
                                title="Domilicio Social"
                                description="Datos de la dirección"
                                className='pt-6'
                                titleCols="@md:col-span-2"
                                childCols="@md:col-span-10 md:grid-cols-6"
                            >
                                <SelectProvince
                                    required
                                    id='registeredOfficeProvince'
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='registeredOfficeTown'
                                    label="Población"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='registeredOfficeAddress'
                                    label="Dirección"
                                    className='md:col-span-3'
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='registeredOfficePostalCode'
                                    label="Código Postal"
                                    formik={formik}
                                />
                            </FormGroup>

                            <FormGroup
                                title="Responsable"
                                description="Datos del responsable"
                                className='pt-6'
                                titleCols="@md:col-span-2"
                                childCols="@md:col-span-10 md:grid-cols-3"
                            >
                                <RequiredInput
                                    id='personResponsibleNif'
                                    label="CIF/NIF"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='personResponsibleName'
                                    label="Nombre y apellidos"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='personResponsibleEmail'
                                    label="Email"
                                    formik={formik}
                                />

                                <PhoneInput
                                    id='personResponsibleTelephone'
                                    label='Teléfono'
                                    onBlur={formik.handleBlur}
                                    onChange={e => { formik.setFieldValue('personResponsibleTelephone', e) }}
                                    value={formik.values.personResponsibleTelephone}
                                    error={formik.errors.personResponsibleTelephone}
                                />

                                <PhoneInput
                                    id='personResponsibleTelephoneSecondary'
                                    label='Teléfono Secundario'
                                    onBlur={formik.handleBlur}
                                    onChange={e => { formik.setFieldValue('personResponsibleTelephoneSecondary', e) }}
                                    value={formik.values.personResponsibleTelephoneSecondary}
                                    error={formik.errors.personResponsibleTelephoneSecondary}
                                    required={false}
                                />
                            </FormGroup>
                        </div>
                    </form>
                </div>

                <CNAEModalSelector
                    visible={CNAESelectorVisible}
                    setVisible={setCNAESelectorVisible}
                    onSelect={(cnae: string, description: string) => {
                        formik.setFieldValue('cnae', cnae);
                        formik.setFieldValue('activityDescription', description);
                        setCNAESelectorVisible(false);
                    }}
                />
            </>
        );
    };

    return (
        <>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Cliente'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm}
                isLoading={isLoading}
            />
        </>
    );
};

export default ClientForm;