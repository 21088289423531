import { useCallback, useEffect, useState } from 'react';
import { ProductService } from "../../services/product/productService";
import { ContractService } from '../../services/contract/contractService';

/*----------------------------------------------------------------------------------------------------
* EN: Hook to manage product fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de productos.
----------------------------------------------------------------------------------------------------*/
const useProducts = (initialFilters: any = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({ limit: 999999, filter_filters: { ...initialFilters } });

    const fetchProducts = useCallback(async (filter?: any) => {
        const newFilters = filter ? { ...filters, filter_filters: { ...filters.filter_filters, ...filter } } : filters;
        try {
            const service = new ProductService();
            const response = await service.listProducts(newFilters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; amount: number }) => ({
                    value: data.id,
                    label: data.name,
                    amount: data.amount,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching products:', error);
        }
    }, []);

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [filters]);

    const updateProductsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getProductsList = () => {
        return data;
    };

    return { fetchProducts, getProductsList, updateProductsHookFilters };
}

export default useProducts;


/*----------------------------------------------------------------------------------------
* EN: Hook to manage product fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de productos.
----------------------------------------------------------------------------------------*/
export const useFindProducts = (initialFilters: any = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({ limit: 999999, filter_filters: { ...initialFilters } });

    const findProducts = useCallback(async (filter?: any) => {
        const newFilters = filter ? { ...filters, filter_filters: { ...filters.filter_filters, ...filter } } : filters;
        try {
            const response = await (new ContractService()).findProductsOnContract(newFilters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData) {
                const mappedData = fetchedData?.map((data: { id: string; name: string; amount: number }) => ({
                    value: data.id,
                    label: data.name,
                    amount: data.amount,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching products:', error);
        }
    }, []);

    useEffect(() => {
        findProducts();
    }, []);

    useEffect(() => {
        findProducts();
    }, [filters]);

    const getFoundProductsList = () => {
        return data;
    };

    return { findProducts, getFoundProductsList };
}