import { useEffect, useState } from 'react';
import { ContractService } from '../../../services/contract/contractService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage contract commercial fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de comerciales de contratos.
----------------------------------------------------------------------------------------*/

const useContractCommercial = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchContractCommercial = async () => {
        try {
            const service = new ContractService();
            const response = await service.findCommercialsOnContract(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; lastName: string; email: string; }) => ({
                    value: data.id,
                    label: `${data.name} ${data.lastName} (${data.email})`,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching contracts commercials:', error);
        }
    };

    useEffect(() => {
        fetchContractCommercial();
    }, [filters]);

    const updateContractCommercialHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getContractCommercialList = () => {
        return data;
    };

    return { fetchContractCommercial, getContractCommercialList, updateContractCommercialHookFilters };
}

export default useContractCommercial;