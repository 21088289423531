import { useEffect, useState } from 'react';
import { InvoiceService } from '../../services/invoice/invoiceService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage invoice tutors fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de empresa.
----------------------------------------------------------------------------------------*/

const useInvoiceTutors = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchInvoiceTutors = async () => {
        try {
            const service = new InvoiceService();
            const response = await service.getInvoiceTutors(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; lastName: string; }) => ({
                    value: data.id,
                    label: data.name + ' ' + data.lastName,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching Invoice Tutors:', error);
        }
    };

    useEffect(() => {
        fetchInvoiceTutors();
    }, [filters]);

    const updateInvoiceTutorsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getInvoiceTutorsList = () => {
        return data;
    };

    return { fetchInvoiceTutors, getInvoiceTutorsList, updateInvoiceTutorsHookFilters };
}

export default useInvoiceTutors;