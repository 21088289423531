import { useEffect, useState } from 'react';
import { ConsultancyService } from '../../services/consultancy/consultancyService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage consultancies fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de asesorías.
----------------------------------------------------------------------------------------*/

const useConsultancies = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchConsultancies = async () => {
        try {
            const service = new ConsultancyService();
            const response = await service.listConsultancies(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching consultancies:', error);
        }
    };

    useEffect(() => {
        fetchConsultancies();
    }, [filters]);

    const updateConsultanciesHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getConsultanciesList = () => {
        return data;
    };

    return { fetchConsultancies, getConsultanciesList, updateConsultanciesHookFilters };
}

export default useConsultancies;