import React from 'react';
import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import ClientProfileLayout from '../../ClientProfileLayout';
import ClientInvoices from './ClientInvoices';

interface ClientInvoicesWrapperProps { }

const ClientInvoicesWrapper: React.FC<ClientInvoicesWrapperProps> = () => {

    return (
        <FiltersProvider>
            <ClientProfileLayout>
                <ClientInvoices />
            </ClientProfileLayout>
        </FiltersProvider>
    );
};

export default ClientInvoicesWrapper;