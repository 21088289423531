import React from 'react';

interface MyTutorialsProps { }

const MyTutorials: React.FC<MyTutorialsProps> = ({ }) => {
    return (
        <div>
            <h1>Mis tutorías</h1>
        </div>
    );
};

export default MyTutorials;