import React from 'react';
import CustomCalendar from '../../../components/calendar/Calendar';

interface MyCalendarProps { }

const MyCalendar: React.FC<MyCalendarProps> = ({ }) => {
    return (
        <CustomCalendar
            events={[
                {
                    title: 'Curso de React',
                    start: new Date(2024, 10, 7, 10, 0),
                    end: new Date(2024, 10, 7, 12, 0),
                    allDay: false,
                }]
            }
        />
    );
};

export default MyCalendar;