// components/OrderControl.tsx
import React from 'react';
import { ActionIcon, Tooltip, cn } from 'rizzui';

interface OrderControlProps {
    id: string;
    order?: number;
    index: number;
    totalRegisters: number;
    onOrderChange: (id: string, direction: 'up' | 'down') => void;
}

const OrderControl: React.FC<OrderControlProps> = ({ id, order, index, totalRegisters, onOrderChange }) => {
    return (
        <div className="flex justify-center gap-4" key={totalRegisters}>
            <Tooltip size="sm" content="Subir" placement="top" color="invert">
                <ActionIcon
                    as="span"
                    size="sm"
                    variant="outline"
                    className={cn(['hover:!border-gray-900 hover:text-gray-700 cursor-pointer'], { hidden: order ? (order === 1) : (index === 0) })}
                    onClick={() => onOrderChange(id, 'down')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>
                </ActionIcon>
            </Tooltip>

            <span className="text-gray-700">{order || (index + 1)}</span>

            <Tooltip size="sm" content="Bajar" placement="top" color="invert">
                <ActionIcon
                    as="span"
                    size="sm"
                    variant="outline"
                    className={cn(['hover:!border-gray-900 hover:text-gray-700 cursor-pointer'], { hidden: order ? (order === totalRegisters) : (index === totalRegisters - 1) })}
                    onClick={() => onOrderChange(id, 'up')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                    </svg>
                </ActionIcon>
            </Tooltip>
        </div>
    )
};

export default OrderControl;