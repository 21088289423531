import { useEffect, useState } from 'react';
import { EducationService } from '../../services/education/educationService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage education fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de educación.
----------------------------------------------------------------------------------------*/

const useEducation = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchEducation = async () => {
        try {
            const service = new EducationService();
            const response = await service.listEducation(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching education:', error);
        }
    };

    useEffect(() => {
        fetchEducation();
    }, [filters]);

    const updateEducationHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getEducationList = () => {
        return data;
    };

    return { fetchEducation, getEducationList, updateEducationHookFilters };
}

export default useEducation;