import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/campaign';

export class CampaignService extends RestServiceConnection {

    listCampaigns = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getCampaignById = async (campaignId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { campaignId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createCampaign = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editCampaign = async (entity: any) => {
        entity.campaignId = entity.id;
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteCampaign = async (campaignId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { campaignId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiCampaigns = async (campaignIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { campaignIds },
        }, true);
        return this;
    }

    updateCampaignOrder = async (campaignId: string, action: 'up' | 'down') => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-campaign-order',
            data: { campaignId, action },
        }, true);
        return this;
    }

    toggleCampaign = async (campaignId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { campaignId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}