import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import StudentProfileLayout from '../../StudentProfileLayout';
import StudentActivityHistory from './StudentActivityHistory';

const StudentActivityHistoryWrapper = () => {
    return (
        <FiltersProvider>
            <StudentProfileLayout>
                <StudentActivityHistory />
            </StudentProfileLayout>
        </FiltersProvider>
    );
};

export default StudentActivityHistoryWrapper;