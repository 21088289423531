import React from 'react';
import SimpleBar from 'simplebar-react';
import VerticalTabNav, { MenuItem } from '../../components/navigation/VerticalTabNav';
import { attivaMenuItems } from '../../router/menu';

export const generalConfigMenuItems: MenuItem[] = (attivaMenuItems.administrationMenu.dropdownItems as any)?.filter((item: any) => item.id === 'generalConfiguration')[0].subMenuItems.map((item: any) => {
    return {
        label: item.name,
        path: `/${item.href.split('/').slice(2).join('/')}`,
        permission: {
            group: item.permissions_required[0].group,
            action: item.permissions_required[0].action,
        },
    };
});

export default function GeneralConfigLayout({ children }: { children: React.ReactNode }) {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
            <div className="lg:col-span-1 col-span-5">
                <SimpleBar className="h-full">
                    <VerticalTabNav menuItems={generalConfigMenuItems} />
                </SimpleBar>
            </div>

            <div className="lg:col-span-4 col-span-5 ps-5">
                {children}
            </div>
        </div>
    );
};