import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/professional-categories';

export class ProfessionalCategoryService extends RestServiceConnection {

    listProfessionalCategories = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getProfessionalCategoryById = async (professionalCategoryId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { professionalCategoryId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createProfessionalCategory = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editProfessionalCategory = async (entity: any) => {
        entity.professionalCategoryId = entity.id;
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteProfessionalCategory = async (professionalCategoryId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { professionalCategoryId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiProfessionalCategories = async (professionalCategoryIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { professionalCategoryIds },
        }, true);
        return this;
    }

    updateProfessionalCategoryOrder = async (professionalCategoryId: string, action: 'up' | 'down') => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-professional-category-order',
            data: { professionalCategoryId, action },
        }, true);
        return this;
    }

    toggleProfessionalCategory = async (professionalCategoryId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { professionalCategoryId },
        }, true);
        return this;
    }

}