import { useCallback, useEffect, useState } from 'react';
import { entities } from '../../pages/states/StateForm';
import { InvoiceService } from '../../services/invoice/invoiceService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage invoice states fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de estados de facturas.
----------------------------------------------------------------------------------------*/

const useInvoiceStates = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchInvoiceStates = useCallback(async () => {
        try {
            const service = new InvoiceService();
            const response = await service.getInvoiceStatuses(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; entity: string; color: string; }) => ({
                    value: data.id,
                    label: data.name,
                    entityLabel: entities.find((entity) => entity.value === data.entity)?.label,
                    color: data.color,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching invoice states:', error);
        }
    }, [filters]);

    useEffect(() => {
        fetchInvoiceStates();
    }, []);

    const getInvoiceStatesList = ( notShowStatusID?: string|null) => {
        if (notShowStatusID) return data.filter((status) => status.value !== notShowStatusID);
        return data;
    };

    const addInvoiceStatesFilter = (key: string, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                [key]: value
            }
        }));
    };
    return { fetchInvoiceStates, getInvoiceStatesList, addInvoiceStatesFilter };
}

export default useInvoiceStates;