import { useEffect, useState } from 'react';
import { ContractService } from '../../../services/contract/contractService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage contract networks fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de redes de contrato.
----------------------------------------------------------------------------------------*/

const useContractNetwork = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchContractNetwork = async () => {
        try {
            const service = new ContractService();
            const response = await service.findNetworksOnContract(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching contracts networks:', error);
        }
    };

    useEffect(() => {
        fetchContractNetwork();
    }, [filters]);

    const updateContractNetworkHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getContractNetworkList = () => {
        return data;
    };

    return { fetchContractNetwork, getContractNetworkList, updateContractNetworkHookFilters };
}

export default useContractNetwork;