import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import ClientProfileLayout from '../../ClientProfileLayout';
import { ClientService } from '../../../../../services/client/clientService';
import { ClientApiResponse } from '../../../../../type/entities/client-type';
import useFilters from '../../../../../hooks/useFilters';
import CustomTable from '../../../../../components/table/CustomTable';
import moment from 'moment';
import { Button, Input, cn } from 'rizzui';
import { toast } from 'sonner';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { StudentsApiResponse } from '../../../../../type/entities/student-type';
import ModalForm from '../../../../../components/forms/ModalForm';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';

interface ClientCreditsHistoryProps { }

const columnOrder = [
    'credits',
    'user',
    'createdAt',
];

const ClientCreditsHistory: React.FC<ClientCreditsHistoryProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { userCan } = usePrivilege();
    const service = new ClientService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters({ client: id });

    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [credits, setCredits] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.listCreditsUpdate(filters);
        return response.getResponseData() as ClientApiResponse;
    }, [id, filters]));

    const addCredits = async () => {
        setIsLoading(true);
        const response = await (await service.addCredits(id, credits)).getResponseData();
        if (response.success) {
            setOpenModal(false);
            setCredits(0);
            toast.success('Créditos actualizados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
        setIsLoading(false);
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <ClientProfileLayout isLoading={loading}>
            {data !== undefined && (
                <div className="pt-4">
                    <CustomTable
                        id={'credits-table'}
                        columnOrder={columnOrder}
                        filter={false}
                        data={orderedData()}
                        isLoading={loading}
                        columnsNotShown={['id']}
                        overrideColumns={[
                            {
                                key: 'user',
                                render: (row: any) => {
                                    return `${row.row.original.user.name} ${row.row.original.user.lastName || ''}`;
                                },
                            },
                            {
                                key: 'createdAt',
                                render: (row: any) => {
                                    const date = row.row.original?.createdAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                            {
                                key: 'updatedAt',
                                render: (row: any) => {
                                    const date = row.row.original?.updatedAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                        ]}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as StudentsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    >
                        {userCan('add_client_credits', 'clients') && (
                            <Button
                                color="primary"
                                onClick={() => { setOpenModal(true); }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Actualizar créditos
                            </Button>
                        )}
                    </CustomTable>
                </div>
            )}

            <ModalForm isOpen={openModal} onClose={() => setOpenModal(false)} title='Añadir actualización de créditos'>
                <Input
                    label='Créditos'
                    type='number'
                    value={credits}
                    onChange={(e) => setCredits(parseInt(e.target.value))}
                />

                <div className='flex justify-center p-7'>
                    <Button
                        disabled={isLoading}
                        onClick={() => addCredits()}
                        color='primary'
                    >
                        Actualizar créditos
                    </Button>
                </div>
            </ModalForm>
        </ClientProfileLayout>
    );
};

export default ClientCreditsHistory;