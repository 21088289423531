import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import AttivaCard from "../../../components/card/AttivaCard";
import { toast } from "sonner";
import useFetch from "../../../hooks/useFetch";
import ProductForm from "../ProductForm";
import { ProductService } from "../../../services/product/productService";
import { Product } from "../../../type/entities/product-type";
import { Loader } from "../../../components/loader/SpinnerLogo";

export default function ProductEdit() {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const service = new ProductService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getProductById(id as string);
        return response.getResponseData() as Product;
    }, [id]));

    const pageHeader = {
        title: `Editar ${data?.name}`,
        breadcrumb: [
            { name: 'Administración' },
            { name: 'Gestión de Productos' },
            { href: menuRoutes.products.path, name: "Productos" },
            { name: "Editar Producto" },
        ],
    };

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (
                await service.editProduct(values)
            ).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success("Producto editado correctamente");
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                {(data !== null && data !== undefined) && <ProductForm data={data} submit={handleSubmit} isLoading={isLoading} />}
            </AttivaCard>
        </>
    );
}