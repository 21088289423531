import React, { useCallback, useState } from 'react';
import ClientProfileLayout from '../../ClientProfileLayout';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { toast } from 'sonner';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';
import useFetch from '../../../../../hooks/useFetch';
import useFilters from '../../../../../hooks/useFilters';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { ClientService } from '../../../../../services/client/clientService';
import { ProductService } from '../../../../../services/product/productService';
import { ProductsApiResponse } from '../../../../../type/entities/product-type';
import CustomTable from '../../../../../components/table/CustomTable';
import moment from 'moment';
import { ActionIcon, Button, Switch, Tooltip, cn } from 'rizzui';
import { menuRoutes } from '../../../../../router/menu';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import ProductsFilters from '../../../../products/list/ProductsFilters';
import { fixNumber } from '../../../../../utils/formatNumber';

interface ClientProductsProps { }

const columnOrder = [
    'id',
    'name',
    'product',
    'productType',
    'code',
    'amount',
    'weeklyHours',
    'description',
    'active',
    'createdAt',
    'updatedAt',
];

const ClientProducts: React.FC<ClientProductsProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const service = new ClientService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters({ client: id });

    const [changingState, setChangingState] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.listProducts(filters);
        return response.getResponseData() as ProductsApiResponse;
    }, [id, filters]));

    const toggleState = async (id: string, status: boolean) => {
        try {
            setChangingState([...changingState, id]);
            const response = (await (new ProductService).toggleProductStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingState(changingState.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el producto" : "Se ha activado el producto");
            } else {
                setChangingState(changingState.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingState(changingState.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        const response = await (await (new ProductService).deleteProduct(id)).getResponseData();
        if (response.success) {
            toast.success('Producto eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await (new ProductService).deleteMultiProducts(ids)).getResponseData();
        if (response.success) {
            toast.success('Productos eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            if (key === 'id') {
                orderedRow[key] = row.product?.id;
                return;
            }
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <ClientProfileLayout isLoading={loading}>
            {data !== undefined && (
                <div className="pt-4">
                    <CustomTable
                        id={'products-table'}
                        columnOrder={columnOrder}
                        data={orderedData()}
                        isLoading={loading}
                        columnsNotShown={['product']}
                        overrideColumns={[
                            {
                                key: 'name',
                                render: (row: any) => { return row.row.original.product?.name },
                            },
                            {
                                key: 'productType',
                                render: (row: any) => { return row.row.original.product?.productType?.name },
                            },
                            {
                                key: 'code',
                                render: (row: any) => { return row.row.original.product?.code ? row.row.original.product?.code : 'N/A' },
                            },
                            {
                                key: 'amount',
                                render: (row: any) => { return row.row.original.product?.amount ? fixNumber(row.row.original.product?.amount) + '€' : 'N/A' },
                            },
                            {
                                key: 'weeklyHours',
                                render: (row: any) => { return row.row.original.product?.weeklyHours ? row.row.original.product?.weeklyHours + ' h' : 'N/A' },
                            },
                            {
                                key: 'description',
                                render: (row: any) => { return row.row.original.product?.description ? row.row.original.product?.description : 'N/A' },
                            },
                            {
                                key: 'createdAt',
                                render: (row: any) => {
                                    const date = row.row.original?.createdAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                            {
                                key: 'updatedAt',
                                render: (row: any) => {
                                    const date = row.row.original?.updatedAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                            {
                                key: 'active',
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={row.row.original.product?.active ? 'Desactivar producto' : 'Activar producto'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Switch
                                                id={row.row.original.id}
                                                checked={row.row.original.product?.active}
                                                onChange={() => toggleState(row.row.original.id, row.row.original.product?.active)}
                                                switchKnobClassName='bg-secondary'
                                            />
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        actions={[
                            {
                                label: 'Edit',
                                permissions: { group: 'products', permission: 'edit_products' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar producto'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link to={`${menuRoutes.products.path}/${row.product?.id}/edit`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Delete',
                                permissions: { group: 'products', permission: 'delete_products' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Eliminar'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>
                                                <DeletePopover
                                                    title="Eliminar producto"
                                                    description={`¿Estás seguro de que deseas eliminar ${row.product?.name}?`}
                                                    onDelete={() => handleDelete(row.product?.id)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        handleMultipleDelete={handleMultiDelete}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as ProductsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    >
                        {userCan('create_products', 'products') && (
                            <Button
                                color="primary"
                                onClick={() => { navigate(`${menuRoutes.products.path}/${menuRoutes.products.create}`, { replace: true }) }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Crear producto
                            </Button>
                        )}
                    </CustomTable>
                </div>
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Productos'}>
                <ProductsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </ClientProfileLayout>
    );
};

export default ClientProducts;