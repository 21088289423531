import { useCallback, useEffect, useState } from 'react';
import { DepartmentService } from '../../services/department/departmentService';

/*--------------------------------------------------------------------------------------------------------------
* EN: Hook to manage departments fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de departamentos.
---------------------------------------------------------------------------------------------------------------*/
const useDepartments = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchDepartments = async () => {
        try {
            const service = new DepartmentService();
            const response = await service.listDepartments(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching departments:', error);
        }
    };

    useEffect(() => {
        fetchDepartments();
    }, [filters]);

    const getDepartmentsList = () => {
        return data;
    };

    return { fetchDepartments, getDepartmentsList };
}

export default useDepartments;


/*---------------------------------------------------------------------------------------------------------------
* EN: Hook to manage unassigned departments fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de departamentos no asignados.
----------------------------------------------------------------------------------------------------------------*/
export const useUnassignedDepartments = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchUnassignedDepartments = async () => {
        try {
            const service = new DepartmentService();
            const response = await service.getUnassignedDepartments(filters);
            const fetchedData = response.getResponseData();

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching departments:', error);
        }
    };

    useEffect(() => {
        fetchUnassignedDepartments();
    }, [filters]);

    const getUnassignedDepartmentsList = () => {
        return data;
    };

    return { fetchUnassignedDepartments, getUnassignedDepartmentsList };
}


/*-------------------------------------------------------------------------------------------------------------
* EN: Hook to manage departments filtered by commercial network fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de departamentos filtrados por red comercial.
--------------------------------------------------------------------------------------------------------------*/
export const useDepartmentsByCommercialNetwork = () => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState<string>('');

    const fetchDepartmentsByCommercialNetwork = async (commercialNetworkId: string) => {
        if (!commercialNetworkId) return;
        try {
            const service = new DepartmentService();
            const response = await service.getDepartmentsByCommercialNetwork(filters);
            const fetchedData = response.getResponseData();

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching departments:', error);
        }
    };

    useEffect(() => {
        fetchDepartmentsByCommercialNetwork(filters);
    }, [filters]);

    const updateDepartmentsFilters = (newFilters: string) => {
        setFilters(newFilters);
    };

    const getDepartmentsByCommercialNetworkList = () => {
        return data;
    };

    return { fetchDepartmentsByCommercialNetwork, getDepartmentsByCommercialNetworkList, updateDepartmentsFilters };
}