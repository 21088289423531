import { AxiosResponse } from "axios";
import { toast } from "sonner";

export default function useHandleDownloadDocument() {

    const handleDownloadDocument = async (service: any, documentId: string, documentName: string) => {
        try {
            const response = await service(documentId);

            if (response && response.getResponseData()) {
                const fileData = response.getResponseData();

                // Verificar que los datos no estén vacíos
                if (fileData) {
                    // Crear un blob y generar el enlace de descarga
                    const blob = new Blob([fileData]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', documentName);

                    // Descargar el archivo
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);

                    toast.success('Documento descargado con éxito');
                } else {
                    toast.error('El documento está vacío o no se pudo obtener el archivo');
                }
            } else {
                toast.error('Error al descargar el documento');
            }
        } catch (error: any) {
            toast.error(`Error al descargar el documento: ${error.message}`);
        }
    };

    const handleDownloadDocumentNewTab = async (service: any, documentId: string, documentName: string, extension: string = 'pdf') => {
        try {
            const response = (await service(documentId)).getResponse() as AxiosResponse;

            if (response.status === 200) {
                if (extension === 'pdf') {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', documentName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else {
                    handleDownloadDocument(service, documentId, documentName);
                }
            } else {
                toast.error('Error al descargar el documento');
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error.message || 'Error al descargar el documento');
        }
    };

    return { handleDownloadDocument, handleDownloadDocumentNewTab };
}