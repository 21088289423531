import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import AttivaCard from "../../../components/card/AttivaCard";
import { toast } from "sonner";
import useFetch from "../../../hooks/useFetch";
import { Loader } from "rizzui";
import { StudentService } from "../../../services/student/StudentService";
import { Student } from "../../../type/entities/student-type";
import StudentForm from "../StudentForm";

const pageHeader = {
    title: "Editar Empleado",
    breadcrumb: [
        {
            href: menuRoutes.students.path,
            name: "Empleados",
        },
        {
            name: "Editar",
        },
    ],
};

export default function StudentEdit() {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const service = new StudentService();

    const [loading, setLoading] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        const response = await service.getStudentById(id as string);
        return response.getResponseData() as Student;
    }, [id]));

    const handleSubmit = async (values: any) => {
        setLoading(true);

        try {
            const response = await (await service.editStudent(values)).getResponseData();
            if (response.success) {
                setLoading(false);
                navigate(-1);
                setTimeout(() => {
                    toast.success("Empleado editado correctamente");
                }, 100);
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                {(data !== null && data !== undefined) && <StudentForm data={data} submit={handleSubmit} isLoading={loading} />}
            </AttivaCard>
        </>
    );
}