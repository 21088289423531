// Function to remove a key from an object
export const omitKey = (obj: any, keyToOmit: string) => {
    const { [keyToOmit]: omitted, ...rest } = obj;
    return rest;
};

// Function to get the differences between two objects
export function getDifferences(obj1: any, obj2: any) {
    const diffs = {} as any;

    Object.keys(obj1).forEach(key => {
        if (obj2.hasOwnProperty(key)) {
            // Compare objects recursively to detect changes in nested structures
            if (typeof obj1[key] === 'object' && obj1[key] !== null && !Array.isArray(obj1[key])) {
                const nestedDiff = getDifferences(obj1[key], obj2[key]);
                if (Object.keys(nestedDiff).length > 0) {
                    diffs[key] = nestedDiff;
                }
            } else if (obj1[key] !== obj2[key]) {
                diffs[key] = obj2[key];  // If the value is different, store the new value
            }
        } else {
            diffs[key] = obj2[key];
        }
    });

    // Check for keys in obj2 that are not in obj1
    Object.keys(obj2).forEach(key => {
        if (!obj1.hasOwnProperty(key)) {
            diffs[key] = obj2[key];
        }
    });

    return diffs;
};

export function isEqual(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}