import { FC, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoginService } from '../../services/auth/loginService';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { login, User } from '../../redux/authSlice';
import { PrivilegeContext } from '../../components/priviledge/PriviledgeProvider';
import { toast } from 'sonner';
import { Checkbox, Password, Button, Input, Text, Tooltip } from 'rizzui';
import { useFormik } from 'formik';
import Spinner from '../../components/bootstrap/Spinner';
import cn from '../../utils/classNames';
import fullLogo from '../../assets/logo_completo.png';
import logo from '../../assets/attiva_logo.png';
import { HiOutlineInformationCircle } from 'react-icons/hi2';

const Login: FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { userCan } = useContext(PrivilegeContext);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const [isLoading, setIsLoading] = useState(false);
  const [remindMe, setRemindMe] = useState(false);

  if (isAuthenticated) {
    setTimeout(() => {
      if (userCan('get_dashboard_information', 'dashboard')) {
        navigate('/dashboard', { replace: true })
      } else {
        navigate('/users', { replace: true })
      }
    }, 100);
  };

  const handleLogin = async (username: string, password: string) => {
    setIsLoading(true);
    const response = await (await (new LoginService()).authUserCredentials(username, password, remindMe)).getResponseData();

    if (!response?.success) {
      toast.error(response.message);
      setIsLoading(false);
      dispatch(login({
        isAuthenticated: false,
        loading: false,
        error: response.message,
        user: null
      }))
      return;
    }

    if (response && response.success) {
      // store data in indexDb for service worker.
      try {
        //saveUser(response.data);
        const user: User = {
          id: response.data.user.id,
          email: response.data.user.email,
          token: response.data.token,
          name: response.data.user.name,
          lastName: response.data.user.lastName,
          profileImage: response.data.user.imgProfile,
          roles: response.data.user.roles,
          commercialNetwork: response.data.user.commercialNetworks || '',
          companyName: response.data.user.companyName || '',
        };

        // dispatch login action
        dispatch(login({
          isAuthenticated: true,
          loading: false,
          error: null,
          user: user
        }))
      } catch (error) {
        toast.error('Error saving user to indexDb');
        return;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loginUsername: '',
      loginPassword: '',
    },
    validate: (values) => {
      const errors: { loginUsername?: string; loginPassword?: string } = {};

      if (!values.loginUsername) {
        errors.loginUsername = 'Requerido';
      }

      if (!values.loginPassword) {
        errors.loginPassword = 'Requerido';
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: (values) => { handleLogin(values.loginUsername, values.loginPassword) },
  });

  return (
    <div className="relative min-h-screen">
      {/* 
      <div className="float-start">
        <Link to='/' className={'flex justify-center items-center m-5'}>
          <img src={fullLogo} width={'150px'} alt="Logo de Attiva" />
        </Link>
      </div>
      */}

      <div className=' w-full flex-col justify-center p-4 md:p-12 lg:p-28'>
        <div className={cn('mx-auto w-full max-w-md rounded-xl px-4 py-9 flex flex-col justify-center',)}>
          <div className='flex flex-col justify-center text-center mb-5'>
            <Link to='/' className={'flex justify-center items-center mb-5'}>
              <img src={fullLogo} height={'30vh'} alt="Logo de Attiva" />
            </Link>

            <Text className="text-2xl font-bold text-gray-800">Entra con tus credenciales</Text>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-5">
              <Input
                id='loginUsername'
                type="email"
                size="lg"
                label="Email"
                placeholder="Introducte tu email"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                error={formik.errors.loginUsername}
              />
              <Password
                id='loginPassword'
                label="Contraseña"
                placeholder="Introduce tu contraseña"
                size="lg"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                value={formik.values.loginPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.loginPassword}
              />

              <Checkbox
                id='remindMe'
                label={
                  <Tooltip
                    size="md"
                    content={<div className='tooltip-container'>La duración de tu sesión será de 24 horas</div>}
                    placement="top"
                    color="invert"
                  >
                    <div className={'flex ms-1'}>
                      Recuérdame <HiOutlineInformationCircle size={20} color={'primary'} className='ms-2' />
                    </div>
                  </Tooltip>
                }
                checked={remindMe}
                onChange={() => setRemindMe(!remindMe)}
                className="text-sm"
              />

              <Button className="w-full" type="submit" size="lg" disabled={isLoading}>
                {isLoading ? <Spinner color={'dark'} /> : 'Iniciar Sesión'}
              </Button>
            </div>
          </form>
          <Text className="mt-5 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-base">
            ¿No recuerdas la contraseña?{' '}
            <Link to='/forgot-password' className="font-semibold text-gray-700 transition-colors hover:text-gray-1000">
              Recupérala
            </Link>
          </Text>

          {/* Acceder al login de tutor */}
          <div className='flex flex-col justify-center items-center h-full'>
            <Text className="text-sm text-gray-700 mt-5">
              ¿Eres tutor?{' '}
              <Link to='/tutor-login' className='font-bold'>
                Accede a tu panel
              </Link>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;