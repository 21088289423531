import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import FormGroup from "../../layout/shared/form-group";
import { Modal, Text, ActionIcon, Button } from "rizzui";
import CustomSearchInput from "./CustomSearchInput";
import { ReactSelectStyles } from "../../styles/select-styles";
import { CNAEService } from "../../services/cnae/cnaeService";
import { IoClose } from "react-icons/io5";

type CNAEModalSelectorProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    onSelect: (cnae: string, description: string) => void;
}

type CNAECode = {
    code: string;
    codeGroup: string;
    description: string;
}

export const CNAEModalSelector: React.FC<CNAEModalSelectorProps> = ({
    visible,
    setVisible,
    onSelect
}) => {

    const [groups, setGroups] = useState<CNAECode[]>([]);
    const [subGroups, setSubGroups] = useState<CNAECode[]>([]);
    const [categories, setCategories] = useState<CNAECode[]>([]);
    const [activities, setActivities] = useState<CNAECode[]>([]);
    const [customSearch, setCustomSearch] = useState<string | undefined>(undefined);
    const [customCnaes, setCustomCnaes] = useState<CNAECode[]>([]);

    const [groupSelected, setGroupSelected] = useState<any | null>(null);
    const [subGroupSelected, setSubgroupSelected] = useState<any | null>(null);
    const [categorySelected, setCategorySelected] = useState<any | null>(null);
    const [activitySelected, setActivitySelected] = useState<any | null>(null);

    const cnaeService = new CNAEService();

    const _getGroups = async () => {
        let response = (await (cnaeService.getGroups())).getResponseData();
        if (response.success) {
            setGroups(response.data);
        }
    }

    const _getSubGroups = async () => {
        let response = (await (cnaeService.getSubGroups(groupSelected.value))).getResponseData();
        if (response.success) {
            setSubGroups(response.data);
        }
    }

    const _getCategories = async () => {
        let response = (await (cnaeService.getCategories(subGroupSelected.value))).getResponseData();
        if (response.success) {
            setCategories(response.data);
        }
    }

    const _getActivities = async () => {
        let response = (await (cnaeService.getActivities(subGroupSelected.value))).getResponseData();
        if (response.success) {
            setActivities(response.data);
        }
    }

    const _searchCustomCnae = async () => {
        if (customSearch === undefined) return;
        let response = (await (cnaeService.searchCNAEs(customSearch))).getResponseData();
        if (response.success) {
            setCustomCnaes(response.data);
        }
    }

    useEffect(() => {
        _getGroups();
    }, []);

    useEffect(() => {
        if (groupSelected) {
            setSubgroupSelected(null);
            _getSubGroups();
        }
    }, [groupSelected])

    useEffect(() => {
        if (subGroupSelected) {
            setCategorySelected(null);
            _getCategories();
        }
    }, [subGroupSelected])

    useEffect(() => {
        if (categorySelected) {
            setActivitySelected(null);
            _getActivities();
        }
    }, [categorySelected])

    useEffect(() => {
        _searchCustomCnae();
    }, [customSearch]);

    return (
        <Modal
            isOpen={visible}
            onClose={() => setVisible(false)}
            size='md'
            overlayClassName='backdrop-blur'
            containerClassName='!shadow-2xl'
            className='z-[9999] [&_.pointer-events-none]:overflow-visible'
        >
            <div className='m-auto px-7 pt-6 pb-8'>
                <div className='mb-7 flex items-center justify-between'>
                    <Text className="text-xl">Buscador de CNAE</Text>
                    <ActionIcon size='md' variant='text' onClick={() => setVisible(false)}>
                        <IoClose className='h-auto' strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                <>
                    <Text className="text-xl bold">CNAE Interactivo: </Text>
                    <FormGroup title="Grupo">
                        <ReactSelect
                            placeholder="Seleccionar grupo"
                            styles={ReactSelectStyles}
                            value={groupSelected}
                            options={groups.map((group: any) => { return { value: group.codeGroup, label: `(${group.codeGroup}) ${group.description}` } })}
                            onChange={setGroupSelected}
                            className="col-span-2"
                        />
                    </FormGroup>
                    {groupSelected && (
                        <FormGroup title="Subgrupo" className="mt-5">
                            <ReactSelect
                                placeholder="Seleccionar grupo"
                                styles={ReactSelectStyles}
                                value={subGroupSelected}
                                options={subGroups.map((group: any) => { return { value: group.codeGroup, label: `(${group.codeGroup}) ${group.description}` } })}
                                onChange={setSubgroupSelected}
                                className="col-span-2"
                            />
                        </FormGroup>
                    )}

                    {subGroupSelected && (
                        <FormGroup title="Categoría" className="mt-5">
                            <ReactSelect
                                placeholder="Seleccionar grupo"
                                styles={ReactSelectStyles}
                                value={categorySelected}
                                options={categories.map((group: any) => { return { value: group.codeGroup, label: `(${group.codeGroup}) ${group.description}` } })}
                                onChange={setCategorySelected}
                                className="col-span-2"
                            />
                        </FormGroup>
                    )}

                    {categorySelected && (
                        <FormGroup title="Actividad" className="mt-5">
                            <ReactSelect
                                placeholder="Seleccionar grupo"
                                styles={ReactSelectStyles}
                                value={activitySelected}
                                options={activities.map((group: any) => { return { value: group.code, label: `(${group.codeGroup}) ${group.description}` } })}
                                onChange={setActivitySelected}
                                className="col-span-2"
                            />
                        </FormGroup>
                    )}

                    {activitySelected && (
                        <div className="d-flex justify-content-center mt-5">
                            <Button color="primary" onClick={() => {
                                onSelect(activitySelected.value, activities.find((a) => a.code === activitySelected.value)?.description || '');
                            }}>
                                Aplicar CNAE {activitySelected.value}
                            </Button>
                        </div>

                    )}

                    <div className="mt-16">
                        <Text className="text-xl bold">O bien, selecciona un CNAE directamente:</Text>
                        <FormGroup title="Búsqueda de cnae (código o nombre):" className="mt-3">
                            <CustomSearchInput
                                placeholder="Buscar CNAE..."
                                defaultValue={customSearch}
                                onSearch={(search: string) => {
                                    if (search.length >= 3) {
                                        setCustomSearch(search);
                                    } else {
                                        setCustomSearch(undefined);
                                    }
                                }}
                                className="col-span-2"
                            />
                        </FormGroup>
                        <div className="cnae-list pt-3">
                            {customCnaes.length === 0 && (<div className="d-flex justify-content-center text-muted">No se han encontrado cnaes</div>)}
                            {customCnaes.map((cnae: CNAECode) => {
                                return (
                                    <div className="cnae-item flex justify-between items-center border mb-3 p-2" key={cnae.code}>
                                        <div className="cnae-item-content">
                                            <div className="cnae-item-code"><b>Código: </b>{cnae.code}</div>
                                            <div className="cnae-item-description">{cnae.description}</div>
                                        </div>
                                        <Button color="primary" onClick={() => { onSelect(cnae.code, cnae.description); }}>
                                            Seleccionar
                                        </Button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
            </div>
        </Modal>
    )
}