import classNames from 'classnames';
import React from 'react';
import InputPhone, { Country } from 'react-phone-number-input';
import es from "react-phone-number-input/locale/es.json";
import 'react-phone-number-input/style.css';

type PhoneInputProps = {
    id: string;
    label?: string;
    required?: boolean;
    value: string;
    onChange?: (value: any) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    defaultCountry?: Country;
    className?: string;
    international?: boolean;
    labels?: any;
    disabled?: boolean;
    placeholder?: string;
    error?: string;
};

const PhoneInput: React.FC<PhoneInputProps> = ({
    id,
    label,
    required = true,
    value,
    onChange,
    onBlur,
    defaultCountry = 'ES',
    international = true,
    labels = es,
    className = '',
    disabled = false,
    placeholder,
    error,
}) => {
    return (
        <div className={`phone-input-wrapper ${className}`}>
            {label && (
                <label htmlFor={id} className="block text-sm font-medium text-gray-700" style={{ marginBottom: '6px' }}>
                    <span>{label} {required && <span className="text-red-500">*</span>}</span>
                </label>
            )}

            <InputPhone
                id={id}
                labels={labels}
                international={international}
                defaultCountry={defaultCountry}
                onBlur={onBlur}
                onChange={onChange || (() => { })}
                value={value}
                className={
                    classNames(
                        `rizzui-input-container flex items-center peer w-full transition duration-200 [&.is-focus]:ring-[0.8px] ring-[0.6px] [&.is-hover]:border-primary [&.is-focus]:border-primary [&.is-focus]:ring-primary [&_input::placeholder]:opacity-60 px-3.5 py-2 h-10 rounded-md border border-muted ring-muted bg-transparent text-sm`,
                        {
                            '!border-red [&.is-hover]:!border-red [&.is-focus]:!border-red !ring-red': error,
                            '!bg-muted/70': disabled
                        }
                    )
                }
                disabled={disabled}
                placeholder={placeholder}
            />

            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
    );
};

export default PhoneInput;