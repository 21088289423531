import { useEffect, useState } from 'react';
import { UserService } from '../../services/user/userService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage users fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de usuarios.
----------------------------------------------------------------------------------------*/

const useUsers = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchUsers = async () => {
        try {
            const service = new UserService();
            const response = await service.listUsers(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; lastName: string; }) => ({
                    value: data.id,
                    label: data.name + (data.lastName ? ` ${data.lastName}` : ''),
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [filters]);

    const getUsersList = () => {
        return data;
    };

    return { fetchUsers, getUsersList };
}

export default useUsers;