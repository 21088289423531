/**
 * Formats a number to a string representation with support for million (M) and billion (B) abbreviations.
 */
export function formatNumber(value: number): string {
    // Check if the value is less than 0
    if (value < 0) {
        // Handle negative values separately and format the absolute value
        const absoluteValue = Math.abs(value);
        return `-${formatNumber(absoluteValue)}`;
    } else if (value >= 1e9) {
        // Format the value in billions
        const formattedValue = value / 1e9;
        return `${formattedValue.toFixed(1)}B`;
    } else if (value >= 1e6) {
        // Check if the value is between 1 million and 1 billion
        // Format the value in millions
        const formattedValue = value / 1e6;
        return `${formattedValue.toFixed(1)}M`;
    } else if (value >= 1000) {
        // Check if the value is between 1 thousand and 1 million
        // Format the value in thousands
        const formattedValue = value / 1000;
        return `${formattedValue.toFixed(1)}K`;
    } else {
        // If the value is less than 1 thousand, return the original value as a string
        return value.toString();
    }
};

/**
 * Formats a number to a string representation with commas.
 */
export function formatNumberWithCommas(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Formats a number to a string representation with commas and support for million (M) and billion (B) abbreviations.
 */
export const fixNumber = (num: number, fix = 2): string => {
    if (!num) return '0';

    const roundedNum = Math.round(Number(num) * Math.pow(10, fix)) / Math.pow(10, fix);

    if (roundedNum % 1 === 0) {
        return roundedNum.toFixed(0);
    } else {
        const formattedNum = roundedNum.toFixed(fix);
        const parts = formattedNum.split('.');

        if (parts.length === 1) {
            return parts[0];
        } else {
            return `${parts[0]}.${parts[1].padEnd(fix, '0')}`;
        }
    }
};