import React, { useCallback, useEffect, useState } from 'react';
import { TutorService } from '../../../services/tutor/tutorService';
import useFetch from '../../../hooks/useFetch';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useHandleDownloadDocument from '../../../hooks/useDownloadDocument';
import moment from 'moment';
import CustomTable from '../../../components/table/CustomTable';
import { ActionIcon, Tooltip } from 'rizzui';
import { IoMdDownload } from 'react-icons/io';
import { DocumentsService } from '../../../services/document/documentsService';
import DocumentsFilters from '../../tutors/profile/views/documents/DocumentsFilters';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import RecentTutorDocuments from '../../../components/documents/RecentTutorDocuments';
import DropzoneTutor from '../../../components/documents/DropzoneTutor';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

interface MyDocumentsProps { }

const columnOrder = [
    'originalName',
    'documentType',
    'tutor',
    'extension',
    'createdAt',
    'documentId'
];

const MyDocuments: React.FC<MyDocumentsProps> = ({ }) => {

    const tutor = useSelector((state: RootState) => state.tutor);
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const docService = new DocumentsService();

    const [tutorId, setTutorId] = useState<string>('');
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await (new TutorService()).listMyDocuments(filters);
        return response.getResponseData();
    }, [filters]));

    /**
     * Order the data to match the column order
     */
    const orderedData = data?.data.map((row: any) => {
        const formattedRow: any = {
            documentId: row.document.id,
            originalName: row.document?.originalName,
            documentType: row.documentType?.name,
            tutor: row.tutor.name + (row.tutor.lastName ? ` ${row.tutor.lastName}` : ''),
            extension: row.document?.extension,
            createdAt: moment(row.createdAt.date).format('DD/MM/YYYY'),
        };

        const orderedRow: any = {};
        columnOrder.forEach((column) => {
            orderedRow[column] = formattedRow[column];
        });

        return orderedRow;
    });

    useEffect(() => {
        if (updateList) {
            refetch();
            setUpdateList(false);
        }
    }, [updateList]);

    useEffect(() => {
        if (tutor.isAuthenticated && tutor.tutor) {
            setTutorId(tutor.tutor.id);
        }
    }, [tutor]);

    return (
        <>
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div className="md:col-span-3 col-span-1 pe-5">
                        <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200">
                            {data !== undefined && data && (
                                <CustomTable
                                    id={'tutors-documents-table'}
                                    columnOrder={columnOrder}
                                    data={orderedData}
                                    isLoading={loading}
                                    columnsNotShown={['id', 'documentId', 'extension', 'fileName', 'status']}
                                    actions={[
                                        {
                                            label: 'Descargar',
                                            render: (row: any) => {
                                                return (
                                                    <Tooltip
                                                        size="sm"
                                                        content={'Descargar documento'}
                                                        placement="top"
                                                        color="invert"
                                                    >
                                                        <ActionIcon
                                                            as="span"
                                                            size="sm"
                                                            variant="outline"
                                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                            onClick={() => handleDownloadDocumentNewTab(docService.downloadTutorDocument, row.documentId, row.originalName, row.extension)}
                                                        >
                                                            <IoMdDownload className="h-4 w-4" size={20} />
                                                        </ActionIcon>
                                                    </Tooltip>
                                                );
                                            },
                                        },
                                    ]}
                                    filter={false}
                                    filters={filters}
                                    updateFilters={updateFilters}
                                    updateFilterOrder={updateFilterOrder}
                                    defaultOrder={filters.filter_order || undefined}
                                    paginationData={{
                                        pageSize: filters.limit,
                                        currentPage: filters.page,
                                        pageCount: data ? data.lastPage : 1,
                                        totalCount: data?.totalRegisters,
                                        handlePagination: updatePage,
                                        handlePerPage: updatePageSize,
                                    }}
                                    toggleFilters={() => setOpenFilters(!openFilters)}
                                />
                            )}
                        </div>

                        <DropzoneTutor id={tutorId} entity='tutor' setUpdateList={setUpdateList} companyId={tutor.tutor?.company || ''} />
                    </div>

                    <div className="md:col-span-1 col-span-1">
                        <RecentTutorDocuments updateList={updateList} setUpdateList={setUpdateList} />
                    </div>
                </div>
            </div>

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Documentos'}>
                <div className="grid grid-cols-1 gap-6">
                    <DocumentsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </>
    );
};

export default MyDocuments;