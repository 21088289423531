import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

export class ProvinceService extends RestServiceConnection {

    listProvinces = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: '/get-provinces',
            data: {
                publicUrl: true,
                ...filters,
            },
        }, true) as AxiosResponse;
        return this;
    }

}