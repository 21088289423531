import PageHeader from '../../../layout/shared/page-header';
import { menuRoutes } from '../../../router/menu';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import AttivaCard from '../../../components/card/AttivaCard';
import { toast } from 'sonner';
import { ProductService } from "../../../services/product/productService";
import ProductForm from "../ProductForm";

const pageHeader = {
    title: 'Crear Producto',
    breadcrumb: [
        { name: 'Administración' },
        { name: 'Gestión de Productos' },
        { href: menuRoutes.products.path, name: 'Productos' },
        { name: 'Crear Producto' },
    ],
};

export default function ProductCreate() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new ProductService()).createProduct(values)).getResponseData();
            if (response.success) {
                setLoading(false);
                navigate(-1);
                setTimeout(() => {
                    toast.success('Producto creado');
                }, 100)
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title='Datos Principales' className='mb-4'>
                <ProductForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
}