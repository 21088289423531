import React, { useContext, useEffect } from "react";
import { UserService } from "../../../services/user/userService";
import { isEqual } from "../../../utils/objectFunctions";
import { toast } from "sonner";
import { useFormik } from "formik";
import yup from "../../../yupExtensions";

type SettingsProviderProps = {
    children: React.ReactNode,
}

type SettingsContextData = {
    companyConfig: any,
    setCompanyConfig: (companyConfig: any) => void,
    updateCompanyConfig: (key: string, value: any) => void,
    isLoading: boolean,
    setIsLoading: (isLoading: boolean) => void,
    hasCompanyConfigChanges: boolean,
    applyCompanyConfigChanges: () => void,
    discardCompanyConfigChanges: () => void,
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    originalCompanyConfig: any,
    formik: any,
}

const SettingsContext: React.Context<SettingsContextData> = React.createContext<SettingsContextData>({
    companyConfig: null,
    setCompanyConfig: (newCompanyConfig: any) => { },
    updateCompanyConfig: (key: string, value: any) => { },
    isLoading: true,
    setIsLoading: (isLoading: boolean) => { },
    hasCompanyConfigChanges: false,
    applyCompanyConfigChanges: () => { },
    discardCompanyConfigChanges: () => { },
    showModal: false,
    setShowModal: (showModal: boolean) => { },
    originalCompanyConfig: null,
    formik: null,
});

const settingsSchema = yup.object({
    daysPeriodToEndContract: yup.number().integer('Debe ser un número entero').min(1, 'El tiempo mínimo es de 1 día').required('Campo obligatorio'),
    contractCodeFormat: yup.string().required('Campo obligatorio'),
    invoiceCodeFormat: yup.string().required('Campo obligatorio'),
    festiveDays: yup.array().of(
        yup.object({
            concept: yup.string().required('Campo obligatorio'),
            date: yup.string().required('Campo obligatorio'),
        })
    ),
});

const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }) => {

    const userService = new UserService();
    const [companyConfig, setCompanyConfig] = React.useState<any>(null);
    const [originalCompanyConfig, setOriginalCompanyConfig] = React.useState<any>(null);
    const [hasCompanyConfigChanges, setHasCompanyConfigChanges] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [showModal, setShowModal] = React.useState<boolean>(false);

    useEffect(() => {
        fetchCompanyConfig();
    }, [])

    const fetchCompanyConfig = () => {
        setIsLoading(true);
        userService.getCompanyConfiguration().then(response => {
            let fetchedData = response.getResponseData().data;
            fetchedData.festiveDays = fetchedData && fetchedData.festiveDays ? fetchedData?.festiveDays.map((day: any) => {
                return {
                    id: day.id,
                    concept: day.concept,
                    date: day.date.date.split(' ')[0],
                }
            }) : [];
            setCompanyConfig(fetchedData);
            setOriginalCompanyConfig(fetchedData);
            setIsLoading(false);
            setHasCompanyConfigChanges(false);
            formik.setValues({
                daysPeriodToEndContract: fetchedData?.daysPeriodToEndContract ?? '',
                contractCodeFormat: fetchedData?.contractCodeFormat ?? '',
                invoiceCodeFormat: fetchedData?.invoiceCodeFormat ?? '',
                festiveDays: fetchedData?.festiveDays ?? [],
            });
        }).catch(error => {
            console.log('Error fetching company config: ', error);
            setIsLoading(false);
        });
    }

    const formik = useFormik({
        initialValues: {
            daysPeriodToEndContract: companyConfig?.daysPeriodToEndContract ?? '',
            contractCodeFormat: companyConfig?.contractCodeFormat ?? '',
            invoiceCodeFormat: companyConfig?.invoiceCodeFormat ?? '',
            festiveDays: companyConfig?.festiveDays ?? [],
        },
        validationSchema: settingsSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
        },
    });

    //////////////////////////////////////////////////////////////////////
    // FUNTION TO KEEP COMPANY CONFIG NEW VALUES IN PROVIDER
    //////////////////////////////////////////////////////////////////////
    const updateCompanyConfig = (key: string, value: any) => {
        const newCompanyConfig = { ...companyConfig, [key]: value };
        setCompanyConfig(newCompanyConfig);
        if (!isEqual(newCompanyConfig, originalCompanyConfig)) setHasCompanyConfigChanges(true);
        else setHasCompanyConfigChanges(false);
    };

    //////////////////////////////////////////////////////////////////////
    // FUNCTION TO APPLY COMPANY CONFIG CHANGES
    //////////////////////////////////////////////////////////////////////
    const applyCompanyConfigChanges = () => {
        const configurationParameters = {
            configurationParameters: {
                contractCodeFormat: companyConfig.contractCodeFormat,
                daysPeriodToEndContract: companyConfig.daysPeriodToEndContract as number,
                invoiceCodeFormat: companyConfig.invoiceCodeFormat,
                festiveDays: companyConfig?.festiveDays.map((day: any) => {
                    return {
                        concept: day.concept,
                        date: day.date,
                    }
                })
            }
        }
        userService.manageCompanyConfiguration(configurationParameters).then(response => {
            const responseData = response.getResponseData();
            setOriginalCompanyConfig(companyConfig);
            setHasCompanyConfigChanges(false);
            if (responseData.success) toast.success('Configuración editada con éxito.')
            else toast.error('Error al guardar los cambios. Propiedad: ' + responseData.data?.errors[0]?.value + ' - ' + responseData.data?.errors[0]?.message);
        }).catch(error => {
            toast.error('Error al guardar los cambios: ' + error);
        });
    }

    //////////////////////////////////////////////////////////////////////
    // FUNCTION TO DISCARD COMPANY CONFIG CHANGES
    //////////////////////////////////////////////////////////////////////
    const discardCompanyConfigChanges = () => {
        setCompanyConfig(originalCompanyConfig);
        setHasCompanyConfigChanges(false);
        formik.setValues({
            daysPeriodToEndContract: originalCompanyConfig?.daysPeriodToEndContract ?? '',
            contractCodeFormat: originalCompanyConfig?.contractCodeFormat ?? '',
            invoiceCodeFormat: originalCompanyConfig?.invoiceCodeFormat ?? '',
            festiveDays: originalCompanyConfig?.festiveDays ?? [],
        })
        toast.info('Cambios descartados');
    }

    return (
        <SettingsContext.Provider value={{
            companyConfig,
            setCompanyConfig,
            updateCompanyConfig,
            isLoading,
            setIsLoading,
            hasCompanyConfigChanges,
            applyCompanyConfigChanges,
            discardCompanyConfigChanges,
            showModal,
            setShowModal,
            originalCompanyConfig,
            formik,
        }}>
            {children}
        </SettingsContext.Provider>
    );
}

export { SettingsProvider, SettingsContext };

export function useSettingsProvider() {
    return useContext(SettingsContext);
}