import React from 'react';
import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import InvoiceActivityHistory from './InvoiceActivityHistory';
import InvoiceProfileLayout from '../../InvoiceProfileLayout';

interface InvoiceActivityHistoryWrapperProps { }

const InvoiceActivityHistoryWrapper: React.FC<InvoiceActivityHistoryWrapperProps> = () => {

    return (
        <FiltersProvider>
            <InvoiceProfileLayout>
                <InvoiceActivityHistory />
            </InvoiceProfileLayout>
        </FiltersProvider>
    );
};

export default InvoiceActivityHistoryWrapper;