import React, { useCallback, useEffect, useState } from 'react';
import { Button, Switch, Tooltip } from 'rizzui';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import TabNav, { MenuItem } from "../../../components/navigation/TabNav";
import { menuRoutes } from "../../../router/menu";
import PageHeader from "../../../layout/shared/page-header";
import { useNavigate, useParams } from "react-router-dom";
import DeletePopover from '../../../components/buttons/DeletePopover';
import { UserService } from '../../../services/user/userService';
import useFetch from '../../../hooks/useFetch';
import { UserApiResponse } from '../../../type/entities/user-type';
import { toast } from 'sonner';
import { setSelectedView } from '../../../redux/userProfileSlice';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { Loader } from '../../../components/loader/SpinnerLogo';
import Spinner from '../../../components/bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { User, login } from '../../../redux/authSlice';
import { IoMdLogIn } from 'react-icons/io';
import CustomPopover from '../../../components/buttons/CustomPopover';
import { MdOutlineLockReset } from 'react-icons/md';

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: '/info',
        permission: {
            group: 'user',
            action: 'get_user',
        },
    },
    {
        label: 'Estadísticas',
        path: '/stats',
        permission: {
            group: 'user',
            action: 'user_profile_stats',
        },
    },
    {
        label: 'Documentos',
        path: '/documents',
        permission: {
            group: 'user',
            action: 'user_profile_documents',
        },
    },
    {
        label: 'Permisos',
        path: '/permissions',
        permission: {
            group: 'user',
            action: 'user_profile_permissions',
        },
    },
    {
        label: 'Histórico de contratos',
        path: '/contract-history',
        permission: {
            group: 'user',
            action: 'user_profile_contract_history',
        },
    },
    {
        label: 'Actividad',
        path: '/activity',
        permission: {
            group: 'user',
            action: 'user_profile_activity',
        },
    },
];

export default function UserProfileLayout({ children, isLoading }: { children: React.ReactNode, isLoading?: boolean }) {

    const { selectedView } = useSelector((state: RootState) => state.userProfile);
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch<AppDispatch>();
    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const service = new UserService();

    const [loginIsLoading, setIsLoading] = useState<boolean>(false);
    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getUserById(id);
        return response.getResponseData() as UserApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Perfil de ${data?.name || 'Usuario'} ${data?.lastName || ''}`,
        breadcrumb: [
            { name: 'Administración' },
            { name: 'Control de acceso' },
            { name: 'Usuarios', href: menuRoutes.users.path },
            { name: 'Perfil de Usuario' },
        ],
    };

    const handleLoginAsUser = async (id: string) => {
        setIsLoading(true);
        const response = await service.loginAsUser(id) as any;
        const responseData = response.getResponseData();

        if (response && responseData?.success) {
            try {
                const user: User = {
                    id: responseData.data.user.id,
                    email: responseData.data.user.email,
                    token: responseData.data.token,
                    name: responseData.data.user.name,
                    lastName: responseData.data.user.lastName,
                    profileImage: responseData.data.user.imgProfile,
                    roles: responseData.data.user.roles,
                    commercialNetwork: responseData.data.user.commercialNetworks,
                    companyName: responseData.data.user.companyName,
                };
                dispatch(login({
                    isAuthenticated: true,
                    loading: false,
                    error: null,
                    user: user
                }))
                toast.success('Has accedido como ' + user.name);
            } catch (error) {
                toast.error('Error al intentar acceder como el usuario');
                return;
            } finally {
                setIsLoading(false);
            }
        }

        if (responseData.payload) {
            navigate('/users', { replace: true });
            setIsLoading(false);
        }
        ;

        if (!responseData?.success) {
            toast.error(response?.response?.message || 'Error al intentar acceder como el usuario');
            setIsLoading(false);
            dispatch(login({
                isAuthenticated: false,
                loading: false,
                error: response.message,
                user: null
            }));
            return;
        }
    };

    const resetUserPermissions = async (id: string) => {
        try {
            const response = await (await service.resetUserPermissions(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Permisos reseteados correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleUserStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el usuario" : "Se ha activado el usuario");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteUser(id)).getResponseData();
            if (response.success) {
                navigate(menuRoutes.users.path);
                toast.success('Se ha eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    useEffect(() => {
        navigate(`${menuRoutes.users.path}/${id}/profile${selectedView}`);
    }, [selectedView]);

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className='flex flex-row flex-nowrap gap-3 mt-3 md:mt-0'>
                    {userCan('edit_user', 'user') && (
                        <>
                            {data && userCan('login_as_user', 'user') && data.id !== user?.id && (
                                <>
                                    <Tooltip
                                        size="sm"
                                        content={'Acceder como usuario'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Button
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => handleLoginAsUser(data.id)}
                                            disabled={loginIsLoading}
                                        >
                                            {loginIsLoading ? <Spinner color={'dark'} /> :
                                                <IoMdLogIn className="h-4 w-4" size={20} />}
                                        </Button>
                                    </Tooltip>
                                    <div className='border-l border-gray-300' />
                                </>
                            )}

                            {data && userCan('edit_user_permissions', 'user') && (
                                <>
                                    <CustomPopover
                                        title={'Resetear permisos'}
                                        description={'¿Estás seguro de que deseas resetear los permisos de este usuario?'}
                                        onClick={() => resetUserPermissions(data.id)}
                                        icon={<MdOutlineLockReset className="h-4 w-4" size={20} />}
                                        actionIconClassName='h-auto w-10'
                                    />
                                    <div className='border-l border-gray-300' />
                                </>
                            )}

                            {data && (
                                <>
                                    <Tooltip
                                        size="sm"
                                        content={data.active ? 'Desactivar usuario' : 'Activar usuario'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Switch
                                            id={id}
                                            label={data.active ? 'Activo' : 'Desactivado'}
                                            checked={data.active}
                                            disabled={changingStatus.includes(data.id) || data.id === user?.id}
                                            onChange={() => toggleStatus(data.id, data.active)}
                                            switchKnobClassName='bg-secondary'
                                        />
                                    </Tooltip>
                                    <div className='border-l border-gray-300' />
                                </>
                            )}

                            <Tooltip content={'Editar usuario'} placement={'top'} color={'invert'} size={'sm'}>
                                <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                                    navigate(`${menuRoutes.users.path}/${id}/edit/info`)
                                }}>
                                    Editar
                                </Button>
                            </Tooltip>
                        </>
                    )}

                    {userCan('delete_user', 'user') && (
                        <DeletePopover
                            title={`Eliminar usuario`}
                            description={`¿Estás seguro de que deseas eliminar el usuario?`}
                            size={'md'}
                            onDelete={() => handleDelete(id)}
                            actionIconClassName='h-auto w-10'
                        />
                    )}
                </div>
            </PageHeader>

            <TabNav menuItems={menuItems} setSelectedView={setSelectedView} />

            {isLoading ? <Loader height='60vh' /> : children}
        </>
    );
}