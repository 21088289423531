import { useEffect, useState } from 'react';
import { NetworkService } from '../../services/network/networkService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage networks fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de redes comerciales.
----------------------------------------------------------------------------------------*/

const useNetworks = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });
    const fetchNetworks = async () => {
        try {
            const service = new NetworkService();
            const response = await service.listNetworks(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching networks:', error);
        }
    };

    useEffect(() => {
        fetchNetworks();
    }, [filters]);

    const updateNetworksHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getNetworksList = () => {
        return data;
    };

    return { fetchNetworks, getNetworksList, updateNetworksHookFilters };
}

export default useNetworks;