import { useEffect, useState } from 'react';
import { ProfessionalCategoryService } from '../../services/professional-category/professionalCategoryService';

/*---------------------------------------------------------------------------------------------
* EN: Hook to manage professional categories fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de categorías profesionales.
---------------------------------------------------------------------------------------------*/

const useProfessionalCategories = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchProfessionalCategories = async () => {
        try {
            const service = new ProfessionalCategoryService();
            const response = await service.listProfessionalCategories(filters);
            const fetchedData = response.getResponseData().data;
            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching professional categories:', error);
        }
    };

    useEffect(() => {
        fetchProfessionalCategories();
    }, [filters]);

    const updateProfessionalCategoriesHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getProfessionalCategoriesList = () => {
        return data;
    };

    return { fetchProfessionalCategories, getProfessionalCategoriesList, updateProfessionalCategoriesHookFilters };
}

export default useProfessionalCategories;