import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/invoice';

export class InvoiceService extends RestServiceConnection {

    listInvoices = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getInvoiceById = async (invoiceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { invoiceId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleInvoiceStatus = async (invoiceId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { invoiceId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    changeInvoiceState = async (invoiceId: string, statusId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-status',
            data: { invoiceId, statusId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getInvoiceStatusTracking = async (invoiceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/status-tracking',
            data: { invoiceId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createInvoice = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: entity,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    editInvoice = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: entity,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    deleteInvoice = async (invoiceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { invoiceId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiInvoices = async (invoiceIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { invoiceIds: invoiceIds },
        }, true);
        return this;
    }

    getInvoiceStatuses = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/available-statuses',
            data: filters,
        }, true);
        return this;
    }

    getInvoiceCorporations = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/available-corporations',
            data: filters,
        }, true);
        return this;
    }

    getInvoiceTutors = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/available-tutors',
            data: filters,
        }, true);
        return this;
    }

    getInvoiceClients = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/available-clients',
            data: filters,
        }, true);
        return this;
    }

    printInvoice = async (invoiceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/generate-invoice-pdf',
            responseType: 'blob',
            data: { invoiceId },
        }, true);
        return this;
    }

    invoicableContractsList = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/invoicable-contracts',
            data: filters,
        }, true);
        return this;
    }

    listActivityHistory = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-invoice-history',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

}