import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/student';

export class StudentService extends RestServiceConnection {

    listStudents = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getStudentById = async (studentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { studentId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleStudentState = async (studentId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { studentId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createStudent = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: entity,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    editStudent = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: entity,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    deleteStudent = async (studentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { studentId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiStudents = async (studentIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { studentIds },
        }, true);
        return this;
    }

    listActivityHistory = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-student-history',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

}