// src/store/slices/clientProfileSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ClientProfileState {
    selectedView: string;
}

const initialState: ClientProfileState = {
    selectedView: '/info',
};

const clientProfileSlice = createSlice({
    name: 'clientProfile',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<string>) {
            state.selectedView = action.payload;
        },
    },
});

export const { setSelectedView } = clientProfileSlice.actions;
export default clientProfileSlice.reducer;