import CustomSelect from "../../../../../components/forms/CustomSelect";
import useProductTypes from "../../../../../hooks/api-calls/useProductTypes";
import useProducts from "../../../../../hooks/api-calls/useProducts";
import FormGroup from "../../../../../layout/shared/form-group";
import cn from "../../../../../utils/classNames";
import useProductComplements from "../../../../../hooks/api-calls/useProductComplements";
import { Checkbox, Input } from "rizzui";
import { FaEuroSign } from "react-icons/fa6";
import { Contract } from "../../../../../type/entities/contract-type";
import { fixNumber } from "../../../../../utils/formatNumber";

interface ProductContractFormInfoProps {
    data: Contract;
}

const ProductContractFormInfo = ({ data }: ProductContractFormInfoProps) => {

    const { getProductTypesList } = useProductTypes();
    const { getProductsList, fetchProducts } = useProducts({ product_type: [data?.productType?.id] });
    const { getProductComplementsList, fetchProductComplements } = useProductComplements();

    return (
        <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
            <div className='flex flex-col md:flex-row md:justify-between items-center'>
                <h3 className='font-bold text-lg pb-2'>Datos del Producto</h3>
            </div>

            <FormGroup
                title="Información General"
                description="Producto asociado al contrato."
                className={cn("py-4 @3xl:grid-cols-12 ")}
                titleCols="@md:col-span-2"
                childCols="@md:col-span-10 md:grid-cols-4"
            >
                <div className="grid grid-cols-12 col-span-8 gap-4">
                    <CustomSelect
                        isDisabled={true}
                        isSearchable
                        isClearable
                        id={'productTypeId'}
                        label="Tipo de producto"
                        containerClassName="col-span-12 md:col-span-3"
                        value={{ value: data?.productType?.id, label: getProductTypesList()?.find((g: any) => g.value === data?.productType?.id)?.label }}
                        options={getProductTypesList()}
                    />

                    <CustomSelect
                        isDisabled={true}
                        isSearchable
                        isClearable
                        id={'productId'}
                        label="Producto"
                        containerClassName="col-span-12 md:col-span-5"
                        value={{ value: data?.product?.id, label: getProductsList()?.find((g: any) => g.value === data?.product?.id)?.label }}
                        options={getProductsList()}

                    />

                    <Input
                        disabled
                        label="Importe"
                        id="productAmount"
                        type="number"
                        className={cn("col-span-12 md:col-span-2", !data?.product?.id && 'hidden')}
                        suffix={<FaEuroSign />}
                        value={data?.productAmount}
                    />

                    <span className={cn("col-span-12 md:col-span-2 text-md font-medium text-end", !data?.product?.id && 'hidden')}>
                        Total: {fixNumber(Number(data?.totalAmount))}€
                    </span>
                </div>
            </FormGroup>

            <FormGroup
                title="Complementos"
                description="Complementos del producto."
                className={cn("py-4", (getProductComplementsList().length === 0 || !data?.product?.id) && 'hidden')}
                titleCols="@md:col-span-2"
                childCols="@md:col-span-10 md:grid-cols-2"
            >
                {getProductComplementsList()?.map((complement: any) => (
                    <div key={complement.value} className="col-span-4 md:col-span-1 flex">
                        <Checkbox
                            disabled
                            size='md'
                            label={<span className="text-wrap">{complement.label} <span className="text-gray-400">({fixNumber(complement.amount)}€)</span></span>}
                            checked={data?.complements.includes(complement.value)}
                            labelClassName="pl-2 text-sm font-medium !text-gray-900"
                        />
                    </div>
                ))}
            </FormGroup>
        </div>
    );
}

export default ProductContractFormInfo;