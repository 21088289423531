import React, { useEffect, useState } from 'react';
import { PiMagnifyingGlassBold, PiFunnel, PiXBold } from 'react-icons/pi';
import { Button, ActionIcon, Input, Title } from 'rizzui';
import cn from '../../../utils/classNames';
import { useMedia } from '../../../hooks/useMedia';
import Spinner from '../../bootstrap/Spinner';
import { ToggleColumns } from './Table';

const Drawer = React.lazy(() => import('rizzui').then(module => ({ default: module.Drawer })));

export function FilterDrawerView({
    isOpen,
    drawerTitle,
    setOpenDrawer,
    children,
    showMoreButton = true,
}: React.PropsWithChildren<{
    drawerTitle?: string;
    hasSearched?: boolean;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen?: boolean;
    showMoreButton?: boolean
}>) {
    const [isComponentLoaded, setComponentLoaded] = useState(false);

    useEffect(() => {
        setComponentLoaded(true);
    }, []);


    return (
        <React.Suspense fallback={<div className='text-center'><Spinner /></div>}>
            {isComponentLoaded && (
                <Drawer
                    size="md"
                    isOpen={isOpen ?? false}
                    onClose={() => setOpenDrawer(false)}
                    overlayClassName="dark:bg-opacity-40 dark:backdrop-blur-[3px]"
                    containerClassName="dark:bg-gray-100 overflow-auto"
                    className="z-[9999]"
                >
                    <div className="flex flex-col p-5">
                        <div className="-mx-5 mb-6 flex items-center justify-between border-b border-muted px-5 pb-4">
                            <Title as="h5">{drawerTitle}</Title>
                            <ActionIcon
                                size="sm"
                                rounded="full"
                                variant="text"
                                title={'Close Filter'}
                                onClick={() => setOpenDrawer(false)}
                            >
                                <PiXBold className="h-4 w-4" />
                            </ActionIcon>
                        </div>
                        <div className="flex-grow">
                            <div className="grid grid-cols-1 gap-6 [&_.price-field>span.mr-2]:mb-1.5 [&_.price-field]:flex-col [&_.price-field]:items-start [&_.react-datepicker-wrapper]:w-full [&_.react-datepicker-wrapper_.w-72]:w-full [&_.text-gray-500]:text-gray-700 [&_button.h-9]:h-10 sm:[&_button.h-9]:h-11 [&_label>.h-9]:h-10 sm:[&_label>.h-9]:h-11 [&_label>.w-24.h-9]:w-full">
                                {children}
                            </div>
                        </div>
                        {showMoreButton && (
                            
                            <Button
                                size="lg"
                                onClick={() => setOpenDrawer(false)}
                                className="mt-5 h-11 w-full text-sm"
                            >
                                Mostrar resultados
                            </Button>
                        )}
                    </div>
                </Drawer>
            )}
        </React.Suspense>
    );
}

export type TableFilterProps = {
    searchTerm: string;
    onSearchClear: () => void;
    onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    columns: { [key: string]: any }[];
    checkedColumns: string[];
    setCheckedColumns: React.Dispatch<React.SetStateAction<string[]>>;
    hideIndex?: number;
    children?: React.ReactNode;
    drawerTitle?: string;
    hasSearched?: boolean;
    showSearchOnTheRight?: boolean;
    enableDrawerFilter?: boolean;
    menu?: React.ReactNode;
};

export default function TableFilter({
    searchTerm,
    onSearchClear,
    onSearchChange,
    columns,
    checkedColumns,
    setCheckedColumns,
    hideIndex,
    drawerTitle = 'Table Filters',
    hasSearched,
    enableDrawerFilter = false,
    showSearchOnTheRight = false,
    menu,
    children,
}: TableFilterProps) {
    const isMediumScreen = useMedia('(max-width: 1860px)', false);
    const [showFilters, setShowFilters] = useState(true);
    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <div className="table-filter mb-4 flex items-center justify-between">
            <div className="flex flex-wrap items-center gap-4">
                {!showSearchOnTheRight ? (
                    <Input
                        type="search"
                        placeholder="Search by anything..."
                        value={searchTerm}
                        onClear={onSearchClear}
                        onChange={onSearchChange}
                        inputClassName="h-9"
                        clearable={true}
                        prefix={<PiMagnifyingGlassBold className="h-4 w-4" />}
                    />
                ) : null}

                {showSearchOnTheRight && enableDrawerFilter ? (
                    <>{menu ? menu : null}</>
                ) : null}

                {children && (
                    <>
                        {isMediumScreen || enableDrawerFilter ? (
                            <FilterDrawerView
                                isOpen={openDrawer}
                                setOpenDrawer={setOpenDrawer}
                                drawerTitle={drawerTitle}
                                hasSearched={hasSearched}
                            >
                                {children}
                            </FilterDrawerView>
                        ) : (
                            <>{showFilters ? children : null}</>
                        )}
                    </>
                )}
            </div>

            <div className="ms-4 flex flex-shrink-0 items-center">
                {showSearchOnTheRight ? (
                    <Input
                        type="search"
                        placeholder="Search by anything..."
                        value={searchTerm}
                        onClear={onSearchClear}
                        onChange={onSearchChange}
                        inputClassName="h-9"
                        clearable={true}
                        prefix={<PiMagnifyingGlassBold className="h-4 w-4" />}
                        className="me-2.5"
                    />
                ) : null}

                {children ? (
                    <Button
                        {...(isMediumScreen || enableDrawerFilter
                            ? {
                                onClick: () => {
                                    setOpenDrawer(() => !openDrawer);
                                },
                            }
                            : { onClick: () => setShowFilters(() => !showFilters) })}
                        variant={'outline'}
                        className={cn(
                            'me-2.5 h-9 pe-3 ps-2.5',
                            !(isMediumScreen || enableDrawerFilter) &&
                            showFilters &&
                            'border-dashed border-gray-700'
                        )}
                    >
                        <PiFunnel className="me-1.5 h-[18px] w-[18px]" strokeWidth={1.7} />
                        {!(isMediumScreen || enableDrawerFilter) && showFilters
                            ? 'Hide Filters'
                            : 'Filters'}
                    </Button>
                ) : null}

                <ToggleColumns
                    columns={columns}
                    checkedColumns={checkedColumns}
                    setCheckedColumns={setCheckedColumns}
                    hideIndex={hideIndex}
                />
            </div>
        </div>
    );
}