import { useEffect, useState } from 'react';
import { ContractService } from '../../../services/contract/contractService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage contracts campaigns fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de campañas de contratos.
----------------------------------------------------------------------------------------*/

const useContractCampaign = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchContractCampaign = async () => {
        try {
            const service = new ContractService();
            const response = await service.findCampaignsOnContract(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching contract campaings:', error);
        }
    };

    useEffect(() => {
        fetchContractCampaign();
    }, [filters]);

    const updateContractCampaignHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getContractCampaignList = () => {
        return data;
    };

    return { fetchContractCampaign, getContractCampaignList, updateContractCampaignHookFilters };
}

export default useContractCampaign;