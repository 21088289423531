import { Outlet } from "react-router-dom";
import { ContractProvider } from "./providers/ContractProvider";

const ContractsLayoutWrapper = () => {
    return (
        <div className="flex flex-grow flex-col px-3">
            <ContractProvider>
                <Outlet />
            </ContractProvider>
        </div>
    )
}

export default ContractsLayoutWrapper;