import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/client';

export class ClientService extends RestServiceConnection {

    listClients = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getClientById = async (clientId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { clientId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleClientState = async (clientId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { clientId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createClient = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editClient = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteClient = async (clientId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { clientId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiClients = async (clientIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { clientIds },
        }, true);
        return this;
    }

    listComments = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-comments',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    addComment = async (clientId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/add-comment',
            data: { clientId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editComment = async (commentId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit-comment',
            data: { commentId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteComment = async (clientCommentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-comment',
            data: { clientCommentId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiComments = async (clientCommentIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/multi-delete-comment',
            data: { clientCommentIds },
        }, true);
        return this;
    }

    lisStudents = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-students',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    listProducts = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-products',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    listContracts = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-contracts',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getConsultancy = async (clientId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-consultancy',
            data: { clientId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    listInvoices = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-invoices',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    listInvoicesDueDates = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-invoices-due-dates',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    listCalls = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-calls',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    listCreditsUpdate = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-credits-update',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    addCredits = async (clientId: string, credits: number) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/add-credits',
            data: { clientId, credits },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    listActivityHistory = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-activity-history',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    generateInvoicePdf = async (invoiceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/generate-client-invoice-pdf',
            data: { invoiceId },
            responseType: 'arraybuffer', // to avoid string conversion
        }, true);
        return this;
    }

}