import { useCallback, useEffect, useState } from 'react';
import { ClientService } from '../../services/client/clientService';
import { ProvinceService } from '../../services/province/provinceService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage province fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de provincias.
----------------------------------------------------------------------------------------*/

const useProvinces = (initialFilters = {}, initialOrders = {}, limit = 999999) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: limit, 
        filter_filters: { ...initialFilters },
        filter_orders: { ...initialOrders }
    });

    const fetchData = useCallback(async () => {
        try {
            const service = new ProvinceService();
            const response = await service.listProvinces(filters);
            const fetchedData = response.getResponseData();

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { provinceId: string; provinceName: string; }) => ({
                    id: data.provinceId,
                    value: data.provinceName,
                    label: data.provinceName,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching provinces:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const updateHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getList = () => {
        return data;
    };

    return { fetchProvinces: fetchData, getProvincesList: getList, updateProvincesHookFilters: updateHookFilters };
}

export default useProvinces;