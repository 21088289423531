import { useEffect, useState } from 'react';
import { ProductService } from "../../services/product/productService";

/*----------------------------------------------------------------------------------------
* EN: Hook to manage product types fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de tipos de productos.
----------------------------------------------------------------------------------------*/

const useProductTypes = (initialFilters?: {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchProductTypes = async () => {
        try {
            const service = new ProductService();
            const response = await service.getProductTypes(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching product types:', error);
        }
    };

    useEffect(() => {
        fetchProductTypes();
    }, [filters]);

    const getProductTypesList = () => {
        return data;
    };

    return { fetchProductTypes, getProductTypesList };
}

export default useProductTypes;