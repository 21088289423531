import FilesIcon from '../../../components/icons/files';
import TruckIcon from '../../../components/icons/truck';
import ScheduleIcon from '../../../components/icons/schedule';
import ShopIcon from '../../../components/icons/shop';
import AnalyticsCircularIcon from '../../../components/icons/analytics-circular';
import WalkmanIcon from '../../../components/icons/walkman';
import UserPlusIcon from '../../../components/icons/user-plus';
import UserLockIcon from '../../../components/icons/user-lock';
import LockExclamationIcon from '../../../components/icons/lock-exclamation';
import LockWildCardIcon from '../../../components/icons/lock-wild-card';
import DicesIcon from '../../../components/icons/dices';
import GreenLeafIcon from '../../../components/icons/green-leaf';
import PieChartCurrencyIcon from '../../../components/icons/pie-chart-currency';
import MapMarkerWithPathIcon from '../../../components/icons/map-marker-with-path';
import UserSettingsIcon from '../../../components/icons/user-settings';
import NotificationSettingsIcon from '../../../components/icons/notification-settings';
import UserInfoIcon from '../../../components/icons/user-info';
import NewsletterAnnouncement from '../../../components/icons/newsletter-announcement';
import MultiStepArrowIcon from '../../../components/icons/multi-step-arrow';
import OnlinePaymentIcon from '../../../components/icons/online-payment';
import ShoppingCartIcon from '../../../components/icons/shopping-cart';
import WalkmanWithExclamationIcon from '../../../components/icons/walkman-with-exclamation';
import ShipIcon from '../../../components/icons/ship';
import FileSettingsIcon from '../../../components/icons/file-settings';
import InvoiceIcon from '../../../components/icons/invoice';
import TableBasicIcon from '../../../components/icons/table-basic';
import TableCollapsibleIcon from '../../../components/icons/table-collapsible';
import TableEnhancedIcon from '../../../components/icons/table-enhanced';
import TableStickyHeaderIcon from '../../../components/icons/table-sticky-header';
import ArrowBothDirectionIcon from '../../../components/icons/arrow-both-direction';
import TableSearchIcon from '../../../components/icons/table-search';
import UserAvatarIcon from '../../../components/icons/user-avatar';
import ShootingStarIcon from '../../../components/icons/shooting-star';
import RocketFlamingIcon from '../../../components/icons/rocket-flaming';
import BadgeNotAllowedIcon from '../../../components/icons/badge-not-allowed';
import MagnifyingWithCrossIcon from '../../../components/icons/magnifying-with-cross';
import SettingsWarningIcon from '../../../components/icons/settings-warning';
import PageBlankIcon from '../../../components/icons/page-blank';
import CalenderIcon from '../../../components/icons/calendar';
import FolderLockIcon from '../../../components/icons/folder-lock';
import PointOfSellIcon from '../../../components/icons/point-of-sell';
import RealEstateIcon from '../../../components/icons/real-estate';
import NftIcon from '../../../components/icons/nft';
import FlightIcon from '../../../components/icons/flight-icon';
import FinancialStatisticsIcon from '../../../components/icons/financial-statistics';
import BusinessIcon from '../../../components/icons/business';
import SuitcaseIcon from '../../../components/icons/suitcase';
import ProjectDashIcon from '../../../components/icons/project-dash';
import ApartmentIcon from '../../../components/icons/apartment';
import RolesIcon from '../../../components/icons/roles';
import DocumentIcon from '../../../components/icons/document';
import UserListIcon from '../../../components/icons/user-list';
import AddIcon from '../../../components/icons/add';
import PackageOpenIcon from '../../../components/icons/package-open';
import FolderIcon from '../../../components/icons/folder-solid';
import NetworksIcon from '../../../components/icons/networks';
import DepartmentIcon from '../../../components/icons/department';
import StructureIcon from '../../../components/icons/structure';
import ConfigIcon from '../../../components/icons/gear';

export const lithiumMenuIcons = {
    FolderIcon,
    PackageOpenIcon,
    AddIcon,
    UserListIcon,
    DocumentIcon,
    RolesIcon,
    ApartmentIcon,
    FilesIcon,
    TruckIcon,
    ShopIcon,
    AnalyticsCircularIcon,
    WalkmanIcon,
    UserPlusIcon,
    UserLockIcon,
    LockExclamationIcon,
    LockWildCardIcon,
    DicesIcon,
    GreenLeafIcon,
    PieChartCurrencyIcon,
    MapMarkerWithPathIcon,
    UserSettingsIcon,
    NotificationSettingsIcon,
    UserInfoIcon,
    NewsletterAnnouncement,
    MultiStepArrowIcon,
    OnlinePaymentIcon,
    ShoppingCartIcon,
    WalkmanWithExclamationIcon,
    ShipIcon,
    FileSettingsIcon,
    InvoiceIcon,
    TableBasicIcon,
    TableCollapsibleIcon,
    TableEnhancedIcon,
    TableStickyHeaderIcon,
    ArrowBothDirectionIcon,
    TableSearchIcon,
    UserAvatarIcon,
    ShootingStarIcon,
    RocketFlamingIcon,
    BadgeNotAllowedIcon,
    MagnifyingWithCrossIcon,
    SettingsWarningIcon,
    PageBlankIcon,
    CalenderIcon,
    FolderLockIcon,
    PointOfSellIcon,
    RealEstateIcon,
    NftIcon,
    FlightIcon,
    FinancialStatisticsIcon,
    BusinessIcon,
    ScheduleIcon,
    SuitcaseIcon,
    ProjectDashIcon,
    StructureIcon,
    NetworksIcon,
    DepartmentIcon,
    ConfigIcon,
};

export type LithiumMenuIconType = keyof typeof lithiumMenuIcons;
