import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import Page from "../../layout/Page/Page";
import FormFooter from "../_layout/_footers/form-footer";
import FormGroup from "../../layout/shared/form-group";
import RequiredInput from "../../components/forms/InputHOC";
import { useFormik } from "formik";
import CustomSelect from "../../components/forms/CustomSelect";
import useFetch from "../../hooks/useFetch";
import { toast } from "sonner";
import { Loader } from "../../components/loader/SpinnerLogo";
import useUsers from "../../hooks/api-calls/useUsers";
import useNetworks from "../../hooks/api-calls/useNetworks";
import { DepartmentService } from "../../services/department/departmentService";
import { Department, DepartmentFieldsModel } from "../../type/entities/department-type";
import { Button, Textarea } from "rizzui";
import useStates from "../../hooks/api-calls/useStates";
import useProducts from "../../hooks/api-calls/useProducts";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import { FaRegCopy, FaInfoCircle } from "react-icons/fa";
import ButtonPopover from "../../components/buttons/ButtonPopover";
import useDeleteDepartment from "../../hooks/customs/departments/useDeleteDepartment";

interface CreateFormProps {
    id: string | undefined;
    setNodeId: (value: string | undefined) => void;
    setCopyConfig: (value: boolean) => void;
    setInfo: (value: boolean) => void;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    refetch: () => void;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
    description: yup.string().nullable(),
    commercialNetworkIds: yup
    .array()
    .of(yup.string())
    .min(1, 'Debe seleccionar al menos una red comercial')
    .required('Campo obligatorio'),
    departmentUsers: yup.array().nullable(),
    departmentStatusesToView: yup.array().nullable(),
    departmentStatusesToEdit: yup.array().nullable(),
    departmentProducts: yup.array().nullable(),
});

const DepartmentForm: React.FC<CreateFormProps> = ({ id, setNodeId, setCopyConfig, setInfo, isOpen, setIsOpen, refetch }) => {

    const { userCan } = usePrivilege();
    const { getNetworksList } = useNetworks({ active: true });
    const { getUsersList } = useUsers({ active: true });
    const { getStatesList } = useStates({ active: true });
    const { getProductsList } = useProducts({ active: true });
    const service = new DepartmentService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = await service.getDepartmentById(id as string);
        return response.getResponseData() as Department;
    }, [id]));

    const mode = data ? 'Editar' : 'Crear';

    const submit = async (values: any) => {
        setIsLoading(true);

        if (mode === 'Crear') {
            try {
                const response = await service.createDepartment(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Departamento creado correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            try {
                const response = await service.editDepartment(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Departamento editado correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const deleteDepartment = useDeleteDepartment(refetch);

    const formik = useFormik<DepartmentFieldsModel>({
        initialValues: {
            departmentId: id ?? '',
            name: data?.name ?? '',
            description: data?.description ?? '',
            commercialNetworkIds: data?.commercialNetworks?.map((n: any) => n.commercialNetwork?.id) ?? [],
            departmentUsers: data?.user?.map((u: any) => u.id) ?? [],
            departmentStatusesToView: data?.departmentsHasStatuses?.filter((s: any) => s.action === 'view').map((s: any) => s.statuses?.id) ?? [],
            departmentStatusesToEdit: data?.departmentsHasStatuses?.filter((s: any) => s.action === 'edit').map((s: any) => s.statuses?.id) ?? [],
            departmentProducts: data?.departmentsHasProduct?.map((p: any) => p.product?.id) ?? [],
        },
        validationSchema: schema,
        validateOnBlur: false,
        //validateOnChange: false,
        onSubmit: values => { submit(values); },
    });

    useEffect(() => {
        if (data) {
            formik.setValues({
                departmentId: data.id,
                name: data.name,
                description: data.description,
                commercialNetworkIds: data.commercialNetworks?.map((n: any) => n.commercialNetwork?.id) ?? [],
                departmentUsers: data.user?.map((u: any) => u.id) ?? [],
                departmentStatusesToView: data.departmentsHasStatuses?.filter((s: any) => s.action === 'view').map((s: any) => s.statuses?.id) ?? [],
                departmentStatusesToEdit: data.departmentsHasStatuses?.filter((s: any) => s.action === 'edit').map((s: any) => s.statuses?.id) ?? [],
                departmentProducts: data.departmentsHasProduct?.map((p: any) => p.product?.id) ?? [],
            });
        }
    }, [data]);

    useEffect(() => {
        if (id === undefined) {
            formik.resetForm();
        }
    }, [id]);

    if (isOpen && id && !data) return <Loader />;

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-10 grid gap-3 @2xl:gap-9 @3xl:gap-11 font-medium">
                        {id && isOpen && (
                            <div className='grid grid-cols-2 gap-10 pt-2'>
                                <div className="col-start-2 flex justify-end">
                                    <Button
                                        onClick={() => setCopyConfig(true)}
                                        color='secondary'
                                        variant="text"
                                    >
                                        <FaRegCopy size={20} />
                                    </Button>

                                    {userCan('get_departments', 'departments') && (
                                        <Button
                                            onClick={() => setInfo(true)}
                                            color="secondary"
                                            variant="text"
                                        >
                                            <FaInfoCircle size={20} />
                                        </Button>
                                    )}

                                    {userCan('delete_departments', 'departments') && (
                                        <ButtonPopover
                                            title="Eliminar departamento"
                                            description="Esto eliminará todo lo que dependa de este departamento"
                                            onClick={() => { deleteDepartment(id); setIsOpen(false); setNodeId(undefined); }} // Se utiliza el hook para eliminar departamentos
                                            variant="text"
                                            mobileView
                                            btnClassName="px-4 py-2 gap-2"
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        <FormGroup
                            title="Información General"
                            description="Datos del departamento"
                            className="pt-4"
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10"
                        >
                            <RequiredInput
                                id='name'
                                label="Nombre"
                                formik={formik}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                isClearable
                                required
                                id={'commercialNetworkIds'}
                                label="Redes Comerciales"
                                value={formik.values.commercialNetworkIds ? getNetworksList().filter((n: any) => formik.values.commercialNetworkIds?.includes(n.value)) : []}
                                options={getNetworksList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('commercialNetworkIds', selectedIds);
                                }}
                                error={formik.errors.commercialNetworkIds}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'departmentUsers'}
                                label="Usuarios"
                                value={formik.values.departmentUsers ? getUsersList().filter((u: any) => formik.values.departmentUsers?.includes(u.value)) : []}
                                options={getUsersList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('departmentUsers', selectedIds);
                                }}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'departmentStatusesToView'}
                                label="Estados que puede visualizar"
                                info="Muestran al usuario aquellos contratos que se encuentran en un estado concreto de los seleccionados. Si no selecciona ninguno, podrá visualizar todos los estados."
                                value={formik.values.departmentStatusesToView ? getStatesList().filter((s: any) => formik.values.departmentStatusesToView?.includes(s.value)) : []}
                                options={getStatesList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('departmentStatusesToView', selectedIds);
                                }}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'departmentStatusesToEdit'}
                                label="Estados que puede modificar"
                                info="Permiten al usuario modificar el estado de un contrato a uno de los seleccionados. Si no se selecciona ninguno, podrá modificar a cualquier estado."
                                value={formik.values.departmentStatusesToEdit ? getStatesList().filter((s: any) => formik.values.departmentStatusesToEdit?.includes(s.value)) : []}
                                options={getStatesList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('departmentStatusesToEdit', selectedIds);
                                }}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'departmentProducts'}
                                label="Productos"
                                value={formik.values.departmentProducts ? getProductsList().filter((p: any) => formik.values.departmentProducts?.includes(p.value)) : []}
                                options={getProductsList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('departmentProducts', selectedIds);
                                }}
                            />

                            <Textarea
                                id='description'
                                label="Descripción"
                                className="[&>label>span]:font-medium"
                                rows={3}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                error={formik.errors.description}
                            />
                        </FormGroup>
                    </div>
                </form>
            </div>
        )
    };

    return (
        <>
            {isOpen && (
                <>
                    <Page container="fluid">
                        {(mode === "Editar" && data) && getContent()}
                        {mode === "Crear" && getContent()}
                    </Page>
                    <FormFooter
                        customClassName={"border-0 py-1"}
                        customBg={"bg-transparent"}
                        submitBtnText={mode + ' Departamento'}
                        handleCancelBtn={() => { setIsOpen(false); setNodeId(undefined); formik.resetForm(); }}
                        handleSubmitBtn={formik.submitForm} isLoading={isLoading}
                    />
                </>
            )}
        </>
    )
};

export default DepartmentForm;