import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { User } from '../../type/entities/user-type';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import FormGroup from '../../layout/shared/form-group';
import { FileInput, Password, Switch } from 'rizzui';
import Page from '../../layout/Page/Page';
import FormFooter from '../_layout/_footers/form-footer';
import { PiEnvelopeSimple } from 'react-icons/pi';
import useRolesByUser from '../../hooks/api-calls/useRolesByUser';
import { UserService } from '../../services/user/userService';
import { toast } from 'sonner';
import classNames from 'classnames';
import PlaceholderImage from '../../components/extras/PlaceholderImage';
import AsyncImg from '../../components/extras/AsyncImg';
import DeletePopover from '../../components/buttons/DeletePopover';
import CustomSelect from '../../components/forms/CustomSelect';
import RequiredInput from '../../components/forms/InputHOC';
import useNetworks from '../../hooks/api-calls/useNetworks';
import useDepartments, { useDepartmentsByCommercialNetwork } from '../../hooks/api-calls/useDepartments';
import cn from '../../utils/classNames';

interface CreateFormProps {
  isLoading: boolean;
  submit: Function;
  data?: User | undefined;
  profileImg?: any;
}

const schema = yup.object({
  name: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
  lastName: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
  email: yup.string().email('Email no válido').required('Campo obligatorio'),
  password: yup.string().min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'La contraseña debe contener al menos una letra mayúscula, una minúscula y un número')
    .when('isCreateMode', {
      is: (val: boolean) => val == true,
      then: (schema: yup.Schema) => schema.required('Campo obligatorio'),
      otherwise: (schema: yup.Schema) => schema.nullable()
    })
    .nullable(),
  passwordConfirm: yup.string()
    .when('password', {
      is: (val: string | null | undefined) => val !== null && val !== undefined && val.length > 0,
      then: (schema: yup.Schema) => schema.oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden'),
      otherwise: (schema: yup.Schema) => schema.nullable(),
    })
    .when('isCreateMode', {
      is: (val: boolean) => val == true,
      then: (schema: yup.Schema) => schema.required('Campo obligatorio'),
      otherwise: (schema: yup.Schema) => schema.nullable()
    })
    .nullable(),
  commercialNetworkId: yup.string(),
  departmentId: yup.string(),
  accessThrowCommercialNetworkIp: yup.boolean(),
  profileImg: yup.mixed(),
  roleId: yup.string().required('Campo obligatorio'),
});

const UserForm: React.FC<CreateFormProps> = ({ isLoading, submit, data, profileImg }) => {

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getRolesList } = useRolesByUser({ active: true });
  const { getNetworksList } = useNetworks({ active: true });
  const { getDepartmentsList } = useDepartments({ active: true });
  const { getDepartmentsByCommercialNetworkList, updateDepartmentsFilters } = useDepartmentsByCommercialNetwork();
  const mode = data ? 'Editar' : 'Crear';
  const service = new UserService();

  const [selectedImage, setSelectedImage] = useState<any>(null);

  const handleImageUpload = async (event: React.ChangeEvent<any>) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);

    try {
      const response = await service.editUserImg(id, file);
      const responseData = response.getResponseData();
      if (responseData.success) {
        setTimeout(() => {
          toast.success('Imagen de perfil actualizada');
        }, 100);
      } else {
        toast.error(responseData.message);
        setSelectedImage(null);
      }
    } catch (error: any) {
      toast.error("Formato de imagen incorrecto");
      setSelectedImage(null);
    }
  };

  const deleteImage = async () => {
    try {
      const response = await service.deleteUserImg(id);
      const responseData = response.getResponseData();
      if (responseData.success) {
        setSelectedImage(null);
        window.location.reload();
      }
    } catch (error: any) {
      toast.error("Error al eliminar la imagen");
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: data?.id ?? '',
      company: data?.userRoles[0]?.company?.id ?? '',
      roleId: data?.userRoles[0].role.id ?? '',
      name: data?.name ?? '',
      lastName: data?.lastName ?? '',
      email: data?.email ?? '',
      password: '',
      passwordConfirm: '',
      commercialNetworkId: data?.commercialNetwork?.id ?? '',
      departmentId: data?.department?.id ?? '',
      accessThrowCommercialNetworkIp: data?.accessThrowCommercialNetworkIp ?? false,
      profileImg: data?.profileImg?.id ?? undefined,
      isCreateMode: mode === 'Crear',
    },
    validationSchema: schema,
    validateOnBlur: false,
    // validateOnChange: false,
    onSubmit: values => { submit(values); },
  });

  useEffect(() => {
    if (formik.values.commercialNetworkId) {
      updateDepartmentsFilters(formik.values.commercialNetworkId);
    }
  }, [formik.values.commercialNetworkId]);

  const getContent = () => {
    return (
      <div className="@container">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-10 grid gap-4 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">
            <FormGroup
              title="Información General"
              description="Datos principales del usuario"
              className='pt-6'
              titleCols="@md:col-span-2"
              childCols="@md:col-span-10 md:grid-cols-12"
            >
              <div className={cn(mode === 'Crear' ? 'md:col-span-12' : 'md:col-span-8')}>
                <div className='md:grid md:grid-cols-12 gap-4'>
                  <RequiredInput
                    id='name'
                    label="Nombre"
                    className='md:col-span-3'
                    formik={formik}
                  />

                  <RequiredInput
                    id='lastName'
                    label="Apellidos"
                    className='md:col-span-4'
                    formik={formik}
                  />

                  <RequiredInput
                    id='email'
                    type="email"
                    label="Correo Electrónico"
                    prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                    className='md:col-span-5'
                    formik={formik}
                  />

                  <CustomSelect
                    required
                    isSearchable
                    id={'roleId'}
                    label="Rol"
                    containerClassName='md:col-span-4'
                    value={{ value: formik.values.roleId, label: getRolesList()?.find((g: any) => g.value === formik.values.roleId)?.label }}
                    options={getRolesList()}
                    onChange={(e: any) => { formik.setFieldValue('roleId', e?.value) }}
                    error={formik.errors.roleId}
                  />

                  <Password
                    id='password'
                    label="Contraseña"
                    className="[&>label>span]:font-medium md:col-span-4"
                    inputClassName="text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.errors.password}
                  />

                  <Password
                    id='passwordConfirm'
                    label="Confirmar contraseña"
                    className="[&>label>span]:font-medium md:col-span-4"
                    inputClassName="text-sm"
                    onChange={formik.handleChange}
                    value={formik.values.passwordConfirm}
                    error={formik.errors.passwordConfirm}
                  />
                </div>
              </div>

              <div className={classNames('md:col-span-4', { 'hidden': mode === 'Crear' })}>
                {selectedImage
                  ? <div style={{ height: "200px", width: "200px" }}>
                    <img src={selectedImage} alt="selected" className='mx-auto d-block img-fluid rounded object-cover w-[220px] h-[220px]' />
                  </div>
                  : profileImg
                    ? <AsyncImg id={profileImg.id} isBackground className="mx-auto d-block img-fluid rounded w-[220px] h-[220px] object-cover" />
                    : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid rounded' />
                }
                <div className={'flex justify-start items-center mt-4'}>
                  <FileInput onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)} autoComplete='photo' placeholder={"Cambiar imagen"} className='me-3' />
                  <DeletePopover
                    title={`Eliminar imagen`}
                    description={`¿Estás seguro de que deseas eliminar la imagen?`}
                    onDelete={deleteImage}
                    size={32}
                    className={classNames({ 'hidden': !profileImg?.id })}
                  />
                </div>
              </div>
            </FormGroup>

            <FormGroup
              title="Información Adicional"
              description="Datos adicionales del usuario"
              className='pt-6'
              titleCols="@md:col-span-2"
              childCols="@md:col-span-10 md:grid-cols-3"
            >
              <CustomSelect
                isSearchable
                isClearable
                id={'commercialNetworkId'}
                label="Red Comercial"
                value={{ value: formik.values.commercialNetworkId, label: getNetworksList()?.find((g: any) => g.value === formik.values.commercialNetworkId)?.label }}
                options={getNetworksList()}
                onChange={(e: any) => {
                  formik.setFieldValue('commercialNetworkId', e?.value);
                  formik.setFieldValue('departmentId', '');
                }}
              />

              <CustomSelect
                isSearchable
                isClearable
                id={'departmentId'}
                label="Departamento"
                value={{ value: formik.values.departmentId, label: getDepartmentsList()?.find((g: any) => g.value === formik.values.departmentId)?.label }}
                options={getDepartmentsByCommercialNetworkList()}
                onChange={(e: any) => { formik.setFieldValue('departmentId', e?.value) }}
                isDisabled={!formik.values.commercialNetworkId}
              />

              <Switch
                id='accessThrowCommercialNetworkIp'
                label="Acceso a través de la IP de la red comercial"
                switchKnobClassName='bg-secondary'
                className="[&>label>span]:font-medium md:mt-6"
                onChange={formik.handleChange}
                checked={formik.values.accessThrowCommercialNetworkIp}
              />
            </FormGroup>
          </div>
        </form>
      </div>
    )
  };

  return (
    <>
      <Page container="fluid">
        {(mode === "Editar" && data) && getContent()}
        {mode === "Crear" && getContent()}
      </Page>
      <FormFooter
        submitBtnText={mode + ' Usuario'}
        handleCancelBtn={() => navigate(-1)}
        handleSubmitBtn={formik.submitForm} isLoading={isLoading}
      />
    </>
  )
};

export default UserForm;