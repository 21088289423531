import { IconType } from "react-icons";
import { Tooltip } from "rizzui";

interface StatusToggleProps {
    isActive: boolean;
    onToggle: () => void;
    ActiveIcon: IconType;
    InactiveIcon: IconType;
    activeColor?: string;
    inactiveColor?: string;
    tooltipMessage: string;
}

const StatusToggle: React.FC<StatusToggleProps> = ({
    isActive,
    onToggle,
    ActiveIcon,
    InactiveIcon,
    activeColor = '#444445',
    inactiveColor = '#b7b8b9',
    tooltipMessage,
}) => {
    const Icon = isActive ? ActiveIcon : InactiveIcon;

    return (
        <Tooltip size="sm" content={tooltipMessage} placement="top" color="invert">
            <div className="w-min">
                <Icon
                    size={23}
                    color={isActive ? activeColor : inactiveColor}
                    className={isActive ? '' : 'cursor-pointer'}
                    onClick={() => !isActive && onToggle()}
                />
            </div>
        </Tooltip>
    );
};

export default StatusToggle;