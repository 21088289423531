import React, { useEffect } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, PieChart, Pie, Cell } from 'recharts';
import UserProfileLayout from '../../UserProfileLayout';
import { UserService } from '../../../../../services/user/userService';
import { useParams } from 'react-router-dom';
import useFilters from '../../../../../hooks/useFilters';
import { Title, cn } from 'rizzui';
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions';
import moment from 'moment';
import UserStatsFilters from './UserStatsFilters';

interface CustomTooltipProps {
    active?: boolean;
    payload?: any;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-slate-100 p-2 rounded">
                <p className="label">{`${payload[0].payload.name} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

interface UserStatsProps { }

const UserStats: React.FC<UserStatsProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { width } = useWindowDimensions();
    const { filters, updateFilters, resetFilters } = useFilters({ user: id, option: "", datePeriod: "daily", startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') });

    const [data, setData] = React.useState<any>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [performedNumber, setPerformedNumber] = React.useState<any>();
    const [createdGraph, setCreatedGraph] = React.useState<any>();
    const [groupByStatus, setGroupByStatus] = React.useState<any>();

    const fetchData = async () => {
        if (!id || id === '') return null;

        let options = [
            'contracts-performed-number',
            'contracts-created-graph',
            'contracts-group-by-status',
        ];

        setIsLoading(true);

        const promises = options.map(op =>
            (new UserService).getContractStats({ filter_filters: { ...filters.filter_filters, option: op } })
                .then(res => ({
                    option: op,
                    data: res.getResponseData()?.data || [],
                }))
        );

        const results = await Promise.all(promises);

        setData(results);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [id, filters]);

    useEffect(() => {
        if (!data) return;

        const performedNumber = data.find((d: any) => d.option === 'contracts-performed-number')?.data[0]?.quantity;
        const createdGraph = data.find((d: any) => d.option === 'contracts-created-graph')?.data.map((d: any) => ({
            name: moment(d.date).format('DD/MM/YY'),
            pv: d.quantity,
        }));
        const groupByStatus = data.find((d: any) => d.option === 'contracts-group-by-status')?.data.map((d: any) => ({
            name: d.status,
            value: d.quantity,
            color: d.color,
        }));

        setPerformedNumber(performedNumber);
        setCreatedGraph(createdGraph);
        setGroupByStatus(groupByStatus);
    }, [data]);

    return (
        <UserProfileLayout isLoading={isLoading || !data}>
            <div className="@container">
                <UserStatsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />

                <div className='md:grid md:grid-cols-4 gap-5'>
                    <div className='md:grid md:grid-cols-1'>
                        <div className='p-4'>
                            <Title as="h3" className={cn('text-base font-semibold sm:text-lg')}>
                                Estadísticas de contratos
                            </Title>
                            <div className="inline-block relative overflow-hidden w-full rounded-lg p-4 @6xl:p-6 border border-muted hover:shadow transition-shadow duration-300 dark:bg-[#181818] dark:bg-none">
                                <p className="rizzui-text-p font-normal text-gray-700 dark:text-gray-500">Contratos realizados</p>
                                <p className="rizzui-text-p text-2xl font-semibold leading-none text-gray-900 dark:text-gray-700 my-3">{performedNumber}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122 122" fill="none" className="absolute bottom-2 right-2 size-16">
                                    <g opacity="0.03"><mask id="a" width="122" height="122" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'luminance' }}>
                                        <path fill="#fff" d="M0 0h122v122H0V0z"></path></mask><g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="4" mask="url(#a)">
                                            <path d="M106.94 72.485c0 15.845-6.431 30.201-16.825 40.6l-40.6-40.6h57.425z"></path>
                                            <path d="M49.515 72.485l32.479 32.479c-8.317 8.317-19.802 13.462-32.48 13.462-25.353 0-45.94-20.587-45.94-45.94 0-25.354 20.587-45.941 45.94-45.941v45.94z"></path>
                                            <path d="M49.515 3.574c38.035 0 68.911 30.876 68.911 68.911H49.515V3.575z"></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>

                        <div className='p-4'>
                            <Title as="h3" className={cn('text-base font-semibold sm:text-lg')}>
                                Estado de los contratos
                            </Title>
                            <PieChart width={width / 4} height={300}>
                                <Pie
                                    dataKey="value"
                                    innerRadius="42%"
                                    outerRadius="70%"
                                    fill="#8884d8"
                                    paddingAngle={4}
                                    data={groupByStatus}
                                    cornerRadius={6}
                                    label
                                >
                                    {groupByStatus?.map((entry: any, index: any) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} stroke={entry.color} />
                                    ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />
                            </PieChart>
                        </div>
                    </div>

                    <div className='md:col-span-3 p-4'>
                        <Title as="h3" className={cn('text-base font-semibold sm:text-lg')}>
                            Gráfica de contratos creados
                        </Title>
                        <AreaChart
                            width={((width / 4) * 3) - 50}
                            height={480}
                            data={createdGraph}
                            margin={{
                                top: 30,
                                right: 10,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Area type="monotone" dataKey="pv" stackId="1" stroke="#04ABD8" fill="#04ABD8" />
                        </AreaChart>
                    </div>
                </div>
            </div>
        </UserProfileLayout>
    );
};

export default UserStats;