import { Textarea, Text } from "rizzui";
import RequiredInput from "../../../components/forms/InputHOC";
import FormGroup from "../../../layout/shared/form-group";
import cn from "../../../utils/classNames";
import SelectorModal from "./SelectorModal";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import { ContractService } from "../../../services/contract/contractService";
import { debounce } from "lodash";
import PhoneInput from "../../../components/forms/PhoneInputWrapper";
import { MdMailOutline } from "react-icons/md";
import { useContractProvider } from "../providers/ContractProvider";

interface ConsultancyContractFormProps {
    formik: any;
}
const ConsultancyContractForm = ({ formik }: ConsultancyContractFormProps) => {

    const { setOriginalConsultancyData } = useContractProvider();

    const [openSelectorModal, setOpenSelectorModal] = useState(false);
    const [consultancyData, setConsultancyData] = useState<any>();

    const fetchData = async (finder: string, clientId: string) => {
        if (!clientId) return;
        try {
            const response = await (new ContractService()).findConsultanciesOnContract({ filter_filters: { client: clientId, finder: finder, active: true } });
            if (response.getResponseData()?.success) {
                setConsultancyData(response.getResponseData()?.data?.data);
                response.getResponseData()?.data?.data?.length > 0 && setOpenSelectorModal(true);
            }
        } catch (error) {
            toast.error('Error al buscar la asesoría en la base de datos');
        }
    };

    /**
     * Fill the form with the consultancy data selected from the modal
     */
    const fillData = (c: any) => {
        let consultancyData: any = {
            consultancyId: c?.id,
            consultancyName: c?.name,
            consultancyEmail: c?.email,
            consultancyContact: c?.contact,
            consultancyTelephone: c?.telephone,
            consultancyDescription: c?.description,
        };

        // Save the original consultancy data to compare it later
        setOriginalConsultancyData(consultancyData);

        // Fill the form with the consultancy data
        Object.keys(consultancyData).forEach(key => {
            formik.setFieldValue(key, consultancyData[key]);
        });

        // Validate the form after filling the data and close the modal
        formik.validateForm();
        setOpenSelectorModal(false);
    };

    /**
     * Debounce the function to avoid multiple calls
     */
    const fetchModalDebounced = useCallback(
        debounce(
            useCallback((finder: string, clientId: string) => {
                if (finder?.length > 0) fetchData(finder, clientId);
            }, [])
            , 1000
        ), []
    );

    return (
        <>
            <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
                <div className='flex flex-col md:flex-row md:justify-between items-center'>
                    <h3 className='font-bold text-lg pb-2'>Datos de la Asesoría</h3>
                </div>

                <FormGroup
                    title="Información General"
                    description="Asesoría asociada al contrato."
                    className={cn("py-4 @3xl:grid-cols-12")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <RequiredInput
                            id='consultancyName'
                            label="Nombre"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                            onKeyUp={() => fetchModalDebounced(formik.values.consultancyName, formik.values.clientId)}
                            required={false}
                        />

                        <RequiredInput
                            id='consultancyEmail'
                            type="email"
                            label="Correo Electrónico"
                            className="col-span-12 md:col-span-4"
                            prefix={<MdMailOutline />}
                            formik={formik}
                            onKeyUp={() => fetchModalDebounced(formik.values.consultancyEmail, formik.values.clientId)}
                            required={false}
                        />

                        <RequiredInput
                            id='consultancyContact'
                            label="Persona de Contacto"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                            required={false}
                        />

                        <PhoneInput
                            id='consultancyTelephone'
                            label="Teléfono"
                            onChange={e => { formik.setFieldValue('consultancyTelephone', e) }}
                            value={formik.values.consultancyTelephone}
                            error={formik.errors.consultancyTelephone}
                            className="col-span-12 md:col-span-4"
                            required={false}
                        />

                        <Textarea
                            required={formik.values.saveAsContract}
                            id='consultancyDescription'
                            label="Descripción"
                            className="[&>label>span]:font-medium col-span-12 md:col-span-8"
                            onChange={formik.handleChange}
                            value={formik.values.consultancyDescription}
                            error={formik.errors.consultancyDescription}
                        />
                    </div>
                </FormGroup>
            </div>

            <SelectorModal
                isOpen={openSelectorModal}
                onSubmit={fillData}
                onClose={() => { setOpenSelectorModal(false) }}
                data={consultancyData}
                title="Obtener los datos de la asesoria"
                renderItem={(c) => (
                    <Text>
                        <span className="font-bold">{c.name}</span>{' '}{c.email}
                    </Text>
                )}
                selectedLabel="Asesoria seleccionado"
                buttonText="Obtener datos"
            />
        </>
    );
}

export default ConsultancyContractForm;