import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import { UserApiResponse } from '../../../../../type/entities/user-type';
import Page from '../../../../../layout/Page/Page';
import { Loader } from '../../../../../components/loader/SpinnerLogo';
import CustomTable from '../../../../../components/table/CustomTable';
import moment from 'moment';
import { useFiltersPR } from '../../../../../components/providers/FiltersProvider';
import { ChangelogsApiResponse } from '../../../../../type/entities/changelog-type';
import { Link } from 'react-router-dom';
import { Text } from 'rizzui';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import UserActivityHistoryFilters from './UserActivityHistoryFilters';
import { UserService } from '../../../../../services/user/userService';
import AvatarCard from '../../../../../components/ui/AvatarCard';
import AsyncImg from '../../../../../components/extras/AsyncImg';

interface UserActivityHistoryProps { }

const columnOrder = [
    'id',
    'user',
    'entity',
    'company',
    'description',
    'entityType',
    'createdAt',
    'date',
];

const UserActivityHistory: React.FC<UserActivityHistoryProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new UserService()).listChangelogs(id, filters);
        return response.getResponseData() as UserApiResponse;
    }, [id, filters]));

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <Page container='fluid' className='mt-5'>
            {loading
                ? <Loader height='70vh' />
                : (
                    <CustomTable
                        id={'ActivityHistory-table'}
                        columnOrder={columnOrder}
                        data={orderedData()}
                        columnsNotShown={['id', 'entity', 'relationModifiedId', 'entityType', 'updatedAt', 'createdAt']}
                        overrideColumns={[
                            {
                                key: 'user',
                                render: (row: any) => {
                                    const entity = row.row.original.user;

                                    if (!entity.hasOwnProperty('profileImg')) {
                                        return (
                                            <AvatarCard src={'default-avatar.png'} name={entity.name + (entity.lastName ? ` ${entity.lastName}` : '')} />
                                        )
                                    } else {
                                        return (
                                            <Link to={`/users/${entity.id}/edit`}>
                                                <div className="flex items-center gap-3">
                                                    <AsyncImg id={entity.id} className="mx-auto d-block rounded w-[40px] h-[40px] object-cover" />
                                                    <Text>{entity.name + (entity.lastName ? ` ${entity.lastName}` : '')}</Text>
                                                </div>
                                            </Link>
                                        )
                                    }
                                },
                            },
                            {
                                key: 'description',
                                columnNameKey: 'Comentario',
                                render: (row: any) => { return row.row.original.description },
                            },
                            {
                                key: 'user',
                                render: (row: any) => { return row.row.original.entityType },
                            },
                            {
                                key: 'company',
                                render: (row: any) => { return row.row.original.company?.name },
                            },
                            {
                                key: 'date',
                                render: (row: any) => { return moment(row.row.original.createdAt?.date).format('HH:mm DD/MM/YY') },
                            },
                        ]}
                        actions={[]}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as ChangelogsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                )}
            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Actividad'}>
                <UserActivityHistoryFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default UserActivityHistory;