import { useEffect, useState } from 'react';
import { NetworkService } from '../../services/network/networkService';
import { usePrivilege } from '../../components/priviledge/PriviledgeProvider';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage networks fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de redes comerciales.
----------------------------------------------------------------------------------------*/

const useUserNetworks = (initialFilters = {}) => {

    const { userCan } = usePrivilege();
    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchUserNetworks = async () => {
        try {
            const service = new NetworkService();
            let response;
            let fetchedData;
            if (!userCan('admin_all_commercial_network_contracts', 'commercial_network')) {
                response = await service.getNetworksByUser(filters);
                fetchedData = response.getResponseData().data;
                if (fetchedData) {
                    const mappedData = fetchedData?.map((data: { id: string; name: string; }) => ({
                        value: data.id,
                        label: data.name,
                    }));
                    setData(mappedData);
                }
            } else {
                response = await (new NetworkService()).listNetworks(filters);
                fetchedData = response.getResponseData().data;
                if (fetchedData && fetchedData?.data) {
                    const mappedData = fetchedData?.data?.map((data: { id: string; name: string; }) => ({
                        value: data.id,
                        label: data.name,
                    }));
                    setData(mappedData);
                }
            }
        } catch (error) {
            console.log('Error fetching user networks:', error);
        }
    };

    useEffect(() => {
        fetchUserNetworks();
    }, [filters]);

    const updateUserNetworksHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getUserNetworksList = () => {
        return data;
    };

    return { fetchUserNetworks, getUserNetworksList, updateUserNetworksHookFilters };
}

export default useUserNetworks;