import { IoClose } from "react-icons/io5";
import { Modal, Text, ActionIcon, Button, Textarea } from "rizzui";
import { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import useHandleErrors from "../../hooks/useHandleErrors";
import { useFormik } from "formik";
import * as yup from 'yup';
import RequiredInput from "../../components/forms/InputHOC";

interface ConfigModalFormProps {
    data: any;
    isOpen: boolean;
    onClose: () => void;
    refetch: () => void;
    entity: string;
    entityName: any;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    description: yup.string().nullable(),
});

export const ConfigModalForm: React.FC<ConfigModalFormProps> = ({ data, isOpen, onClose, refetch, entity, entityName }) => {

    const { handleErrors } = useHandleErrors();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const create = useCallback(async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (await entityName?.create_service(values))?.getResponseData();
            if (response.success) {
                refetch();
                onClose();
                formik.resetForm();
                toast.success('Se ha creado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        } finally {
            setIsLoading(false);
        }
    }, [entityName]);

    const edit = useCallback(async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (await entityName?.edit_service(values))?.getResponseData();
            if (response.success) {
                refetch();
                onClose();
                formik.resetForm();
                toast.success('Se ha editado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        } finally {
            setIsLoading(false);
        }
    }, [entityName]);

    const formik = useFormik({
        initialValues: {
            id: data?.id || '',
            name: data?.name || '',
            description: data?.description || '',
        },
        validationSchema: schema,
        validateOnBlur: false,
        //validateOnChange: false,
        onSubmit: values => { data?.id ? edit(values) : create(values) },
    });

    useEffect(() => {
        if (data && data.id) {
            formik.setValues({
                id: data.id,
                name: data.name,
                description: data.description,
            });
        }
    }, [data]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                formik.resetForm();
                onClose();
            }}
            size='sm'
            overlayClassName='backdrop-blur'
            containerClassName='!max-w-xl !shadow-xl'
            className='z-[9999] [&_.pointer-events-none]:overflow-visible'
        >
            <form onSubmit={formik.handleSubmit}>
                <div className='m-auto px-7 pt-6 pb-4'>
                    <div className='mb-7 flex items-center justify-between'>
                        <Text className="text-xl">{data?.id ? 'Editar' : 'Añadir'} </Text>
                        <ActionIcon size='sm' variant='text' onClick={() => { formik.resetForm(); onClose(); }}>
                            <IoClose className='h-auto w-6' strokeWidth={1.8} />
                        </ActionIcon>
                    </div>

                    <RequiredInput
                        id='name'
                        label="Nombre"
                        formik={formik}
                        className="mb-3"
                    />

                    <Textarea
                        id='description'
                        label='Descripción'
                        rows={6}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                    />
                </div>

                <div className='flex justify-center p-7'>
                    <Button
                        disabled={isLoading}
                        type='submit'
                        color='primary'
                    >
                        {data?.id ? 'Editar' : 'Añadir'}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};