import FormGroup from "../../../../../layout/shared/form-group";
import cn from "../../../../../utils/classNames";
import CustomSelect from "../../../../../components/forms/CustomSelect";
import useCorporations from "../../../../../hooks/api-calls/useCorporations";
import useNetworks from "../../../../../hooks/api-calls/useNetworks";
import useContractCampaign from "../../../../../hooks/api-calls/contract/useContractCampaign";
import useContractCommercial from "../../../../../hooks/api-calls/contract/useContractCommercial";
import { usePrivilege } from "../../../../../components/priviledge/PriviledgeProvider";
import { Contract } from "../../../../../type/entities/contract-type";
import { Input } from "rizzui";

interface ContractDataFormInfoProps {
    data: Contract;
}
const ContractDataFormInfo = ({ data }: ContractDataFormInfoProps) => {

    const { userCan } = usePrivilege();
    const { getContractCampaignList } = useContractCampaign();
    const { getContractCommercialList } = useContractCommercial();
    const { getCorporationsList } = useCorporations();
    const { getNetworksList } = useNetworks();

    return (
        <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
            <div className='flex flex-col md:flex-row md:justify-between items-center'>
                <h3 className='font-bold text-lg pb-2'>Datos Generales del Contrato</h3>
            </div>

            <FormGroup
                title="Información General"
                description="Datos asociados al contrato."
                className={cn("py-4 @3xl:grid-cols-12 ")}
                titleCols="@md:col-span-2"
                childCols="@md:col-span-10 md:grid-cols-4"
            >
                <div className="grid grid-cols-12 col-span-8 gap-4">
                    <CustomSelect
                        isDisabled={true}
                        isSearchable
                        isClearable
                        id={'campaignId'}
                        label="Campaña"
                        containerClassName="col-span-12 md:col-span-4"
                        value={{ value: data?.campaign?.id, label: getContractCampaignList()?.find((g: any) => g.value === data?.campaign?.id)?.label }}
                    />

                    <CustomSelect
                        isDisabled={true}
                        isSearchable
                        isClearable
                        id={'commercialId'}
                        label="Comercial"
                        containerClassName="col-span-12 md:col-span-4"
                        value={{ value: data?.commercial?.id, label: getContractCommercialList()?.find((g: any) => g.value === data?.commercial?.id)?.label }}
                        className={cn(!userCan('admin_all_commercial_network_contracts', 'contracts')) && 'hidden'}
                    />

                    <CustomSelect
                        isDisabled={true}
                        isSearchable
                        isClearable
                        id={'organisingCorporationId'}
                        label="Empresa Organizadora"
                        containerClassName="col-span-12 md:col-span-4"
                        value={{ value: data?.organisingCorporation?.id, label: getCorporationsList()?.find((g: any) => g.value === data?.organisingCorporation?.id)?.label }}
                    />

                    <CustomSelect
                        isDisabled={true}
                        isSearchable
                        isClearable
                        id={'commercialNetworkId'}
                        label="Red Comercial"
                        containerClassName="col-span-12 md:col-span-4"
                        value={{ value: data?.commercialNetwork?.id, label: getNetworksList()?.find((g: any) => g.value === data?.commercialNetwork?.id)?.label }}
                        className={cn(!userCan('admin_all_commercial_network_contracts', 'contracts')) && 'hidden'}
                    />

                    <Input
                        disabled
                        id='groupings'
                        label="Agrupaciones"
                        className="col-span-12 md:col-span-4"
                        value={data?.groupings}
                    />

                    <Input
                        disabled
                        id='draftExpirationDate'
                        type="date"
                        label="Fecha de Expiración del Borrador"
                        className="col-span-12 md:col-span-4"
                        value={data?.draftExpirationDate?.date.split(' ')[0]}
                    />

                    <Input
                        disabled
                        id='contractStartDate'
                        type="date"
                        label="Fecha de Inicio del Contrato"
                        className="col-span-12 md:col-span-4"
                        value={data?.contractStartDate?.date.split(' ')[0]}
                    />

                    <Input
                        disabled
                        id='contractEndDate'
                        type="date"
                        label="Fecha de Fin del Contrato"
                        className="col-span-12 md:col-span-4"
                        value={data?.contractEndDate?.date.split(' ')[0]}
                    />
                </div>
            </FormGroup>

            <FormGroup
                title="Representante Legal"
                description="Datos del representante legal."
                className={cn("py-4 @3xl:grid-cols-12 ")}
                titleCols="@md:col-span-2"
                childCols="@md:col-span-10 md:grid-cols-4"
            >
                <div className="grid grid-cols-12 col-span-8 gap-4">
                    <Input
                        disabled
                        id='legalRepresentativeName'
                        label="Nombre y Apellidos"
                        className="col-span-12 md:col-span-4"
                        value={data?.legalRepresentativeName}
                    />

                    <Input
                        disabled
                        id='legalRepresentativeNif'
                        label="NIF/NIE"
                        className="col-span-12 md:col-span-4"
                        value={data?.legalRepresentativeNif}
                    />

                    <Input
                        disabled
                        id='legalRepresentativeEmail'
                        type="email"
                        label="Correo electrónico"
                        className="col-span-12 md:col-span-4"
                        value={data?.legalRepresentativeEmail}
                    />

                    <Input
                        disabled
                        id='legalRepresentativePosition'
                        label="Cargo"
                        className="col-span-12 md:col-span-6"
                        value={data?.legalRepresentativePosition}
                    />

                    <Input
                        disabled
                        id='legalRepresentativeSchedule'
                        label="Horario de contacto"
                        className="col-span-12 md:col-span-6"
                        value={data?.legalRepresentativeSchedule}
                    />
                </div>
            </FormGroup>
        </div>
    );
}

export default ContractDataFormInfo;