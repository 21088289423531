import { useCallback, useEffect, useState } from 'react';
import { entities } from '../../../pages/states/StateForm';
import { State } from '../../../type/entities/state-type';
import { ContractService } from '../../../services/contract/contractService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage Contract products states fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de estados de prductos para contratos.
----------------------------------------------------------------------------------------*/

const useContractProductsStates = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchContractProductsStates = async () => {
        setIsLoading(true);
        try {
            const service = new ContractService();
            const response = await service.getContractProductStatuses(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: State) => ({
                    value: data.id,
                    label: data.name,
                    color: data.color,
                    statusOrder: data.statusOrder,
                    initialStatus: data.initialStatus,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching contract products states:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchContractProductsStates();
    }, [filters]);

    const getContractProductsStatesList = (entity?: string|null, notShowStatusID?: string|null) => {
        if (entity) return data.filter((state: any) => state.entity === entity);
        if (notShowStatusID) return data.filter((state: any) => state.value !== notShowStatusID);
        return data;
    };

    const addContractProductsStatesFilter = (key: string, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                [key]: value
            }
        }));
    };
    return { fetchContractProductsStates, getContractProductsStatesList, addContractProductsStatesFilter, isLoadingContractProductsStates: isLoading };
}

export default useContractProductsStates;