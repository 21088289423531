import { useEffect, useState } from 'react';
import { InvoiceService } from '../../services/invoice/invoiceService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage invoice clients fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de clientes.
----------------------------------------------------------------------------------------*/

const useInvoiceClients = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchInvoiceClients = async () => {
        try {
            const service = new InvoiceService();
            const response = await service.getInvoiceClients(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; companyName: string; cifNif: string; }) => ({
                    value: data.id,
                    label: `${data.companyName} (${data.cifNif})`,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching Invoice Clients:', error);
        }
    }; 

    useEffect(() => {
        fetchInvoiceClients();
    }, [filters]);

    const updateInvoiceClientsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getInvoiceClientsList = () => {
        return data;
    };

    return { fetchInvoiceClients, getInvoiceClientsList, updateInvoiceClientsHookFilters };
}

export default useInvoiceClients;