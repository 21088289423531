import { Switch } from "rizzui";
import FormGroup from "../../../layout/shared/form-group";
import cn from "../../../utils/classNames";
import RequiredInput from "../../../components/forms/InputHOC";
import CustomSelect from "../../../components/forms/CustomSelect";
import PhoneInput from "../../../components/forms/PhoneInputWrapper";
import { gender } from "../../students/StudentForm";
import useProfessionalCategories from "../../../hooks/api-calls/useProfessionalCategories";
import useQuotationGroups from "../../../hooks/api-calls/useQuotationGroups";
import useEducation from "../../../hooks/api-calls/useEducation";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import { ContractService } from "../../../services/contract/contractService";
import SelectorModal from "./SelectorModal";
import { Text } from "rizzui";
import { debounce } from "lodash";
import { useContractProvider } from "../providers/ContractProvider";

interface StudentContractFormProps {
    formik: any;
}
const StudentContractForm = ({ formik }: StudentContractFormProps) => {

    const { setOriginalStudentData } = useContractProvider();
    const { getProfessionalCategoriesList } = useProfessionalCategories({ active: true });
    const { getQuotationGroupsList } = useQuotationGroups({ active: true });
    const { getEducationList } = useEducation({ active: true });

    const [openSelectorModal, setOpenSelectorModal] = useState(false);
    const [studentData, setStudentData] = useState<any>();

    /**
     * Fetch the student data from the API and open the modal
     */
    const fetchStudentData = async (finder: string, clientId: string) => {
        if (!clientId) return;
        try {
            const service = new ContractService();
            const response = await service.findStudentsOnContract({ filter_filters: { client: clientId, nif: finder, active: true } });
            if (response.getResponseData()?.success) {
                setStudentData(response.getResponseData()?.data?.data);
                response.getResponseData()?.data?.data?.length > 0 && setOpenSelectorModal(true);
            }
        } catch (error) {
            toast.error('Error al buscar el empleado en la base de datos');
        }
    };

    /**
     * Fill the form with the student data selected from the modal
     */
    const fillStudentData = (s: any) => {
        let studentData: any = {
            studentId: s?.id,
            studentNif: s?.nif,
            studentName: s?.name,
            studentFirstName: s?.firstName,
            studentLastName: s?.lastName,
            studentSocialSecurityNumber: s?.socialSecurityNumber,
            studentEmail: s?.email,
            studentTelephone: s?.telephone,
            studentTelephoneSecondary: s?.telephoneSecondary,
            studentGender: s?.gender,
            studentBirthDate: s?.birthDate?.date.split(' ')[0],
            studentStudyId: s?.study?.id,
            studentProfessionalCategoryId: s?.professionalCategory?.id,
            studentQuotationGroupId: s?.quotationGroup?.id,
            studentFunctionalArea: s?.functionalArea,
            studentAlternativeShippingAddress: s?.alternativeShippingAddress,
            studentContactSchedule: s?.contactSchedule,
            studentDisability: s?.disability,
            studentGenderViolenceVictim: s?.genderViolenceVictim,
            studentTerrorismVictim: s?.terrorismVictim,
        };

        // Save the original student data to compare it later
        setOriginalStudentData(studentData);

        // Fill the form with the student data
        Object.keys(studentData).forEach(key => {
            formik.setFieldValue(key, studentData[key]);
        });

        // Validate the form after filling the data and close the modal
        formik.validateForm();
        setOpenSelectorModal(false);
    };

    /**
     * Debounce the function to avoid multiple calls
     */
    const fetchStudentModalDebounced = useCallback(
        debounce(
            useCallback((studentNif: any, clientId: string) => {
                if (studentNif?.length > 0) fetchStudentData(studentNif, clientId);
            }, [])
            , 1000
        ), []
    );

    return (
        <>
            <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
                <div className='flex flex-col md:flex-row md:justify-between items-center'>
                    <h3 className='font-bold text-lg pb-2'>Datos de Empleado</h3>
                </div>

                <FormGroup
                    title="Información General"
                    description="Empleado asociado al contrato."
                    className={cn("py-4 @3xl:grid-cols-12 ")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <RequiredInput
                            required={formik.values.saveAsContract && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                            id='studentNif'
                            label="CIF/NIF"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                            onKeyUp={() => fetchStudentModalDebounced(formik.values.studentNif, formik.values.clientId)}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                            id='studentName'
                            label="Nombre"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                            id='studentFirstName'
                            label="Primer Apellido"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                            id='studentLastName'
                            label="Segundo Apellido"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                        />

                        <RequiredInput
                            required={false}
                            id='studentSocialSecurityNumber'
                            label="Número de Seguridad Social"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                            id='studentEmail'
                            type="email"
                            label="Email"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                        />

                        <PhoneInput
                            required={formik.values.saveAsContract && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                            id='studentTelephone'
                            label="Teléfono"
                            className="[&>label>span]:font-medium col-span-12 md:col-span-3"
                            onChange={e => { formik.setFieldValue('studentTelephone', e) }}
                            value={formik.values.studentTelephone}
                            error={formik.errors.studentTelephone}
                        />

                        <PhoneInput
                            id='studentTelephoneSecondary'
                            label="Teléfono adicional"
                            className="[&>label>span]:font-medium col-span-12 md:col-span-3"
                            onChange={e => { formik.setFieldValue('studentTelephoneSecondary', e) }}
                            value={formik.values.studentTelephoneSecondary}
                            error={formik.errors.studentTelephoneSecondary}
                            required={false}
                        />

                        <CustomSelect
                            isSearchable
                            isClearable
                            id={'studentGender'}
                            label="Género"
                            containerClassName="col-span-12 md:col-span-3"
                            value={{ value: formik.values.gender, label: gender?.find((g: any) => g.value === formik.values.studentGender)?.label }}
                            options={gender}
                            onChange={(e: any) => { formik.setFieldValue('studentGender', e?.value); }}
                            error={formik.errors.studentGender}
                        />

                        <RequiredInput
                            id='studentBirthDate'
                            type="date"
                            required={false}
                            label="Fecha de Nacimiento"
                            className="col-span-12 md:col-span-3"
                            formik={formik}
                        />

                    </div>
                </FormGroup>

                <FormGroup
                    title="Datos adicionales"
                    description="Información adicional del empleado."
                    className={cn("py-4 @3xl:grid-cols-12 ")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <CustomSelect
                            isSearchable
                            isClearable
                            id={'studentStudyId'}
                            label="Estudios"
                            containerClassName="col-span-12 md:col-span-4"
                            value={{ value: formik.values.studentStudyId, label: getEducationList()?.find((g: any) => g.value === formik.values.studentStudyId)?.label }}
                            options={getEducationList()}
                            onChange={(e: any) => { formik.setFieldValue('studentStudyId', e?.value); }}
                            error={formik.errors.studentStudyId}
                        />

                        <CustomSelect
                            isSearchable
                            isClearable
                            id={'studentProfessionalCategoryId'}
                            label="Categoria Profesional"
                            containerClassName="col-span-12 md:col-span-4"
                            value={{ value: formik.values.studentProfessionalCategoryId, label: getProfessionalCategoriesList()?.find((g: any) => g.value === formik.values.studentProfessionalCategoryId)?.label }}
                            options={getProfessionalCategoriesList()}
                            onChange={(e: any) => { formik.setFieldValue('studentProfessionalCategoryId', e?.value); }}
                            error={formik.errors.studentProfessionalCategoryId}
                        />

                        <CustomSelect
                            isSearchable
                            isClearable
                            id={'studentQuotationGroupId'}
                            label="Grupo de Cotización"
                            containerClassName="col-span-12 md:col-span-4"
                            value={{ value: formik.values.studentQuotationGroupId, label: getQuotationGroupsList()?.find((g: any) => g.value === formik.values.studentQuotationGroupId)?.label }}
                            options={getQuotationGroupsList()}
                            onChange={(e: any) => { formik.setFieldValue('studentQuotationGroupId', e?.value); }}
                            error={formik.errors.studentQuotationGroupId}
                        />

                        <RequiredInput
                            id='studentFunctionalArea'
                            label="Área Funcional"
                            className="[&>label>span]:font-medium col-span-12 md:col-span-4"
                            inputClassName="text-sm"
                            onChange={formik.handleChange}
                            value={formik.values.studentFunctionalArea}
                            error={formik.errors.studentFunctionalArea}
                            required={false}
                        />

                        <RequiredInput
                            id='studentAlternativeShippingAddress'
                            label="Dirección de envío alternativa"
                            className="col-span-12 md:col-span-4"
                            required={false}
                            formik={formik}
                        />

                        <RequiredInput
                            id='studentContactSchedule'
                            label="Horario de contacto"
                            className="col-span-12 md:col-span-4"
                            required={false}
                            formik={formik}
                        />

                        <div className="col-span-12"></div>

                        <Switch
                            id='studentDisability'
                            label="¿Tiene alguna discapacidad?"
                            switchKnobClassName='bg-secondary'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-4"
                            onChange={formik.handleChange}
                            checked={formik.values.studentDisability}
                        />

                        <Switch
                            id='studentGenderViolenceVictim'
                            label="Victima de violencia de género"
                            switchKnobClassName='bg-secondary'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-4"
                            onChange={formik.handleChange}
                            checked={formik.values.studentGenderViolenceVictim}
                        />

                        <Switch
                            id='studentTerrorismVictim'
                            label="Victima de terrorismo"
                            switchKnobClassName='bg-secondary'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-4"
                            onChange={formik.handleChange}
                            checked={formik.values.studentTerrorismVictim}
                        />
                    </div>
                </FormGroup>
            </div>

            <SelectorModal
                isOpen={openSelectorModal}
                onSubmit={fillStudentData}
                onClose={() => { setOpenSelectorModal(false) }}
                data={studentData}
                title="Obtener los datos del empleado"
                renderItem={(student) => {
                    const formattedPhone = student.telephone ? student.telephone.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '($1) $2 $3 $4') : '';
                    return (
                        <>
                            <Text>
                                <span className="font-bold">
                                    {student.name} {student.firstName}
                                </span>{' '}
                                {formattedPhone} - {student.email}
                            </Text>
                        </>
                    );
                }}
                selectedLabel="Empleado seleccionado"
                buttonText="Obtener datos"
            />
        </>
    );
}

export default StudentContractForm;