import { IoClose } from "react-icons/io5";
import { Modal, Text, ActionIcon, Button, Textarea } from "rizzui";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import { useCallback, useState } from "react";
import { toast } from "sonner";

interface AddCommentModalProps {
    service: any;
    id: any;
    isOpen: boolean;
    onClose: () => void;
    refetch: () => void;
}

export const AddCommentModal: React.FC<AddCommentModalProps> = ({ service, id, isOpen, onClose, refetch }) => {

    const { handleErrors } = useHandleErrors();

    const [comment, setComment] = useState<string>('');

    const addComment = useCallback(async () => {
        try {
            const response = await (await service(id, comment)).getResponseData();
            if (response.success) {
                toast.success('Se ha añadido un nuevo comentario');
                setComment('');
                refetch();
                onClose();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    }, [service, handleErrors]);

    if (!id) return null;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size='sm'
            overlayClassName='backdrop-blur'
            containerClassName='!max-w-xl !shadow-xl'
            className='z-[9999] [&_.pointer-events-none]:overflow-visible'
        >
            <div className='m-auto px-7 pt-6 pb-4'>
                <div className='mb-7 flex items-center justify-between'>
                    <Text className="text-xl">Añadir un comentario</Text>
                    <ActionIcon size='sm' variant='text' onClick={onClose}>
                        <IoClose className='h-auto w-6' strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                <Textarea
                    id='comment'
                    label='Comentario'
                    rows={6}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>

            <div className='flex justify-center p-7'>
                <Button
                    disabled={!comment}
                    onClick={() => addComment()}
                    color='primary'
                >
                    Añadir comentario
                </Button>
            </div>
        </Modal>
    );
};