import { Input, Switch } from "rizzui";
import FormGroup from "../../../../../layout/shared/form-group";
import cn from "../../../../../utils/classNames";
import CustomSelect from "../../../../../components/forms/CustomSelect";
import PhoneInput from "../../../../../components/forms/PhoneInputWrapper";
import { gender } from "../../../../students/StudentForm";
import useProfessionalCategories from "../../../../../hooks/api-calls/useProfessionalCategories";
import useQuotationGroups from "../../../../../hooks/api-calls/useQuotationGroups";
import useEducation from "../../../../../hooks/api-calls/useEducation";
import { Contract } from "../../../../../type/entities/contract-type";

interface StudentContractFormInfoProps {
    data: Contract;
}
const StudentContractFormInfo = ({ data }: StudentContractFormInfoProps) => {

    const { getProfessionalCategoriesList } = useProfessionalCategories();
    const { getQuotationGroupsList } = useQuotationGroups();
    const { getEducationList } = useEducation();

    return (
        <>
            <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
                <div className='flex flex-col md:flex-row md:justify-between items-center'>
                    <h3 className='font-bold text-lg pb-2'>Datos de Empleado</h3>
                </div>

                <FormGroup
                    title="Información General"
                    description="Empleado asociado al contrato."
                    className={cn("py-4 @3xl:grid-cols-12")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <Input
                            disabled
                            id='studentNif'
                            label="NIF/NIE"
                            className="col-span-12 md:col-span-4"
                            value={data?.studentNif ?? ''}
                        />

                        <Input
                            disabled
                            id='studentName'
                            label="Nombre"
                            className="col-span-12 md:col-span-4"
                            value={data?.studentName ?? ''}
                        />

                        <Input
                            disabled
                            id='studentFirstName'
                            label="Primer Apellido"
                            className="col-span-12 md:col-span-4"
                            value={data?.studentFirstName ?? ''}
                        />

                        <Input
                            disabled
                            id='studentLastName'
                            label="Segundo Apellido"
                            className="col-span-12 md:col-span-4"
                            value={data?.studentLastName ?? ''}
                        />

                        <Input
                            disabled
                            id='studentSocialSecurityNumber'
                            label="Número de Seguridad Social"
                            className="col-span-12 md:col-span-4"
                            value={data?.studentSocialSecurityNumber ?? ''}
                        />

                        <Input
                            disabled
                            id='studentEmail'
                            type="email"
                            label="Email"
                            className="col-span-12 md:col-span-4"
                            value={data?.studentEmail ?? ''}
                        />

                        <PhoneInput
                            disabled
                            id='studentTelephone'
                            label="Teléfono"
                            className="[&>label>span]:font-medium col-span-12 md:col-span-3"
                            value={data?.studentTelephone ?? ''}
                            required={false}
                        />

                        <PhoneInput
                            disabled
                            id='studentTelephoneSecondary'
                            label="Teléfono adicional"
                            className="[&>label>span]:font-medium col-span-12 md:col-span-3"
                            value={data?.studentTelephoneSecondary ?? ''}
                            required={false}
                        />

                        <CustomSelect
                            isDisabled={true}
                            isSearchable
                            isClearable
                            id={'studentGender'}
                            label="Género"
                            containerClassName="col-span-12 md:col-span-3"
                            value={{ value: data?.studentGender, label: gender?.find((g: any) => g.value === data?.studentGender)?.label }}
                        />

                        <Input
                            disabled
                            id='studentBirthDate'
                            type="date"
                            required={false}
                            label="Fecha de Nacimiento"
                            className="col-span-12 md:col-span-3"
                        />

                    </div>
                </FormGroup>

                <FormGroup
                    title="Datos adicionales"
                    description="Información adicional del empleado."
                    className={cn("py-4 @3xl:grid-cols-12 ")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <CustomSelect
                            isDisabled={true}
                            isSearchable
                            isClearable
                            id={'studentStudyId'}
                            label="Estudios"
                            containerClassName="col-span-12 md:col-span-4"
                            value={{ value: data?.studentStudy?.id, label: getEducationList()?.find((g: any) => g.value === data?.studentStudy?.id)?.label }}
                            options={getEducationList()}
                        />

                        <CustomSelect
                            isDisabled={true}
                            isSearchable
                            isClearable
                            id={'studentProfessionalCategoryId'}
                            label="Categoria Profesional"
                            containerClassName="col-span-12 md:col-span-4"
                            value={{ value: data?.studentProfessionalCategory?.id, label: getProfessionalCategoriesList()?.find((g: any) => g.value === data?.studentProfessionalCategory?.id)?.label }}
                        />

                        <CustomSelect
                            isDisabled={true}
                            isSearchable
                            isClearable
                            id={'studentQuotationGroupId'}
                            label="Grupo de Cotización"
                            containerClassName="col-span-12 md:col-span-4"
                            value={{ value: data?.studentQuotationGroup?.id, label: getQuotationGroupsList()?.find((g: any) => g.value === data?.studentQuotationGroup?.id)?.label }}
                        />

                        <Input
                            disabled
                            id='studentFunctionalArea'
                            label="Área Funcional"
                            className="[&>label>span]:font-medium col-span-12 md:col-span-4"
                            inputClassName="text-sm"
                            value={data?.studentFunctionalArea ?? ''}
                        />

                        <Input
                            disabled
                            id='studentAlternativeShippingAddress'
                            label="Dirección de envío alternativa"
                            className="col-span-12 md:col-span-4"
                            value={data?.studentAlternativeShippingAddress ?? ''}
                        />

                        <Input
                            disabled
                            id='studentContactSchedule'
                            label="Horario de contacto"
                            className="col-span-12 md:col-span-4"
                            value={data?.studentContactSchedule ?? ''}
                        />

                        <div className="col-span-12"></div>

                        <Switch
                            disabled
                            id='studentDisability'
                            label="¿Tiene alguna discapacidad?"
                            switchKnobClassName='bg-secondary'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-4"
                            checked={data?.studentDisability}
                        />

                        <Switch
                            disabled
                            id='studentGenderViolenceVictim'
                            label="Victima de violencia de género"
                            switchKnobClassName='bg-secondary'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-4"
                            checked={data?.studentGenderViolenceVictim}
                        />

                        <Switch
                            disabled
                            id='studentTerrorismVictim'
                            label="Victima de terrorismo"
                            switchKnobClassName='bg-secondary'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-4"
                            checked={data?.studentTerrorismVictim}
                        />
                    </div>
                </FormGroup>
            </div>

        </>
    );
}

export default StudentContractFormInfo;