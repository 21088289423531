import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/studies';

export class EducationService extends RestServiceConnection {

    listEducation = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getEducationById = async (studiesId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { studiesId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createEducation = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editEducation = async (entity: any) => {
        entity.studiesId = entity.id;
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteEducation = async (studiesId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { studiesId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiEducation = async (studiesIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { studiesIds },
        }, true);
        return this;
    }

    updateEducationOrder = async (studyId: string, action: 'up' | 'down') => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-study-order',
            data: { studyId, action },
        }, true);
        return this;
    }

    toggleEducation = async (studiesId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/toggle',
            data: { studiesId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}