import React from 'react';
import ClientProfileLayout from '../../ClientProfileLayout';

interface ClientCallsProps { }

const ClientCalls: React.FC<ClientCallsProps> = ({ }) => {
    return (
        <ClientProfileLayout>
            <></>
        </ClientProfileLayout>
    );
};

export default ClientCalls;