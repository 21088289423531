import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { IoDocumentText } from 'react-icons/io5';
import { TbCashRegister } from 'react-icons/tb';
import { HiCalendarDays } from 'react-icons/hi2';
import cn from '../../utils/classNames';
import { menuRoutes } from '../../router/menu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setSelectedTab } from '../../redux/settingsSlice';

const menuLinks = [
    // { label: 'General', path: '/settings', icon: <PiGearDuotone /> },
    { label: 'Contratos', path: menuRoutes.settings.path + menuRoutes.settings.contracts, icon: <IoDocumentText /> },
    { label: 'Facturación', path: menuRoutes.settings.path + menuRoutes.settings.invoices, icon: <TbCashRegister /> },
    { label: 'Días Festivos', path: menuRoutes.settings.path + menuRoutes.settings.holidays, icon: <HiCalendarDays /> },
];

const SettingsSidebar: React.FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedTab } = useSelector((state: RootState) => state.settings);

    const pathname = useLocation().pathname;

    useEffect(() => {
        if (pathname !== selectedTab) navigate(selectedTab);
    }, [selectedTab])
    

    return (
        <SimpleBar className="lg:pt-6 lg:ps-6">
            <div className="flex flex-col items-left justify-start h-full p-4">
                <h2 className="uppercase font-bold text-gray-400 mb-4">Muenú Navegación</h2>
                <div className="flex flex-row lg:flex-col text-nowrap flex-nowrap overflow-auto gap-1 scrollbar-thin">
                    {menuLinks.map((link) => {
                        let isActive = pathname === (link.path as string);
                        return (
                            <Link to={link.path}
                                key={link.label}
                                className={cn("flex flex-row flex-nowrap items-center gap-2 py-2 px-4 text-gray-500 hover:text-gray-800 hover:bg-gray-100 rounded-lg",
                                    isActive ? 'bg-primary-100 text-gray-800 hover:bg-primary-300' : ''
                                )}
                                onClick={() => dispatch(setSelectedTab(link.path)) }
                            >
                                {link.icon}
                                {link.label}
                            </Link>
                        )

                    })}
                </div>
            </div>
        </SimpleBar>
    );
};

export default SettingsSidebar;