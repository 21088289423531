import ContractProfileLayout from "../../ContractProfileLayout";

const ContractDocuments = () => {
    return (
        <ContractProfileLayout>
            <div className="@container">
                <div className="grid divide-y-2 divide-dashed divide-gray-200 gap-6 mt-3">
                    <div className={`flex flex-col gap-7 w-full transition-all duration-1000 ease-in-out md:h-[calc(100vh-330px)] md:overflow-y-scroll md:relative pe-2 scrollbar`} >
                    </div>
                </div>
            </div>
        </ContractProfileLayout>
    );
}

export default ContractDocuments;