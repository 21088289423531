import React, { useCallback, useEffect, useState } from 'react';
import TutorProfileLayout from '../../TutorProfileLayout';
import useFetch from '../../../../../hooks/useFetch';
import useFilters from '../../../../../hooks/useFilters';
import { useParams } from 'react-router-dom';
import Dropzone from '../../../../../components/documents/Dropzone';
import RecentDocuments from '../../../../../components/documents/RecentDocuments';
import CustomTable from '../../../../../components/table/CustomTable';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import moment from 'moment';
import { DocumentsService } from '../../../../../services/document/documentsService';
import useHandleDownloadDocument from '../../../../../hooks/useDownloadDocument';
import { ActionIcon, Tooltip } from 'rizzui';
import { IoMdDownload } from "react-icons/io";
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import DocumentsFilters from './DocumentsFilters';
import { TutorService } from '../../../../../services/tutor/tutorService';

interface TutorDocumentsProps { }

const columnOrder = [
    'id',
    'originalName',
    'documentType',
    'user',
    'extension',
    'createdAt',
];

const TutorDocuments: React.FC<TutorDocumentsProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters();
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const docService = new DocumentsService();
    const service = new TutorService();

    const [updateList, setUpdateList] = useState<boolean>(false);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.tutorId) return null;
        const response = await service.listTutorDocuments(filters);
        return response.getResponseData();
    }, [filters]));

    const handleMultiDelete = async (ids: string[]) => {
        await docService.multiDeleteDocuments(ids);
        setUpdateList(true);
        refetch();
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = data?.data.map((row: any) => {
        const formattedRow: any = {
            id: row.document.id,
            originalName: row.document.originalName,
            documentType: row.documentType.name,
            tutor: row.tutor.name + (row.tutor.lastName ? ` ${row.tutor.lastName}` : ''),
            extension: row.document.extension,
            createdAt: moment(row.createdAt.date).format('DD/MM/YYYY'),
        };

        const orderedRow: any = {};
        columnOrder.forEach((column) => {
            orderedRow[column] = formattedRow[column];
        });

        return orderedRow;
    });

    useEffect(() => {
        updateFilters({ tutorId: id });
    }, [id]);

    useEffect(() => {
        if (updateList) {
            refetch();
            setUpdateList(false);
        }
    }, [updateList]);

    return (
        <TutorProfileLayout>
            <div className="container mx-auto p-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div className="md:col-span-3 col-span-1">
                        <div className="pt-7 mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200">
                            {data !== undefined && data && (
                                <CustomTable
                                    id={'tutors-documents-table'}
                                    columnOrder={columnOrder}
                                    data={orderedData}
                                    isLoading={loading}
                                    columnsNotShown={['extension', 'fileName', 'status']}
                                    actions={[
                                        {
                                            label: 'Descargar',
                                            render: (row: any) => {
                                                return (
                                                    <Tooltip
                                                        size="sm"
                                                        content={'Descargar documento'}
                                                        placement="top"
                                                        color="invert"
                                                    >
                                                        <ActionIcon
                                                            as="span"
                                                            size="sm"
                                                            variant="outline"
                                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                            onClick={() => handleDownloadDocumentNewTab(docService.downloadDocument, row.id, row.originalName, row.extension)}
                                                        >
                                                            <IoMdDownload className="h-4 w-4" size={20} />
                                                        </ActionIcon>
                                                    </Tooltip>
                                                );
                                            },
                                        },
                                        {
                                            label: 'Delete',
                                            render: (row: any) => {
                                                return (
                                                    <DeletePopover
                                                        title={`Eliminar documento`}
                                                        description={`¿Estás seguro de que deseas eliminar ${row.originalName}?`}
                                                        onDelete={() => handleMultiDelete([row.id])}
                                                    />
                                                );
                                            },
                                        },
                                    ]}
                                    handleMultipleDelete={handleMultiDelete}
                                    filters={filters}
                                    updateFilters={updateFilters}
                                    updateFilterOrder={updateFilterOrder}
                                    defaultOrder={filters.filter_order || undefined}
                                    paginationData={{
                                        pageSize: filters.limit,
                                        currentPage: filters.page,
                                        pageCount: data ? data.lastPage : 1,
                                        totalCount: data?.totalRegisters,
                                        handlePagination: updatePage,
                                        handlePerPage: updatePageSize,
                                    }}
                                    toggleFilters={() => setOpenFilters(!openFilters)}
                                />
                            )}
                        </div>

                        <Dropzone id={id} entity='tutor' setUpdateList={setUpdateList} />
                    </div>

                    <div className="md:col-span-1 col-span-1">
                        <RecentDocuments updateList={updateList} setUpdateList={setUpdateList} />
                    </div>
                </div>
            </div>

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Documentos'}>
                <div className="grid grid-cols-1 gap-6">
                    <DocumentsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </TutorProfileLayout>
    );
};

export default TutorDocuments;