import { Fragment } from "react";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import InvoicesList from "./InvoicesList";

const InvoicesListWrapper = () => {

    const breadCrumb = [
        { name: 'Facturas', href: menuRoutes.invoices.path },
        { name: 'Listado', href: menuRoutes.invoices.path },
    ];

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de facturas" breadcrumb={breadCrumb}>
                </PageHeader>
                <InvoicesList />
            </FiltersProvider>
        </Fragment>
    );
}

export default InvoicesListWrapper;