import React from 'react';
import { PiTrashDuotone } from 'react-icons/pi';
import { Button } from 'rizzui';
import { FilterOptions } from '../../../hooks/useFilters';
import DateField from '../../../components/forms/DateField';
import { isEqual } from 'lodash';
import CustomSearchableEntities from '../../../components/forms/CustomSearchableEntities';
import useCorporations from '../../../hooks/api-calls/useCorporations';
import useTutors from '../../../hooks/api-calls/useTutors';

interface InvoicesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const InvoicesFilters: React.FC<InvoicesFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const {getCorporationsList, updateCorporationsHookFilters} = useCorporations();
    const {getTutorsList, updateTutorsHookFilters} = useTutors();

    return (
        <>
            <CustomSearchableEntities
                id="corporation"
                label="Empresa"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string) => {
                    updateCorporationsHookFilters({ search_text: searchText });
                    return getCorporationsList();
                }}
            />

            <CustomSearchableEntities
                id="tutor"
                label="Tutor"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string) => {
                    updateTutorsHookFilters({ search_text: searchText });
                    return getTutorsList();
                }}
            />

            <DateField
                isClearable
                className="w-full"
                label="Fecha de Creación"
                placeholderText="Selececiona las fechas"
                selectedDateField={filters.filter_filters?.between_dates?.type || "createdAt"}
                dateTypesOptions={[
                    { label: "Fecha de creación", value: "createdAt" },
                    { label: "Fecha de modificación", value: "updatedAt" },
                    { label: "Último acceso", value: "lastLoginAt" }
                ]}
                startDate={filters.filter_filters?.between_dates?.startDate ? new Date(filters.filter_filters.between_dates.startDate) : null}
                endDate={filters.filter_filters?.between_dates?.endDate ? new Date(filters.filter_filters.between_dates.endDate) : null}
                onChange={(date: any) => {
                    const filters_between_dates = filters.filter_filters?.between_dates;
                    const between_dates = date ? { startDate: date[0], endDate: date[1] } : null;

                    if (between_dates && !isEqual(filters_between_dates, between_dates)) {
                        updateFilters({ between_dates: { startDate: date[0], endDate: date[1], type: date[2] } });
                    } else if (!between_dates && filters_between_dates) {
                        updateFilters({ between_dates: null });
                    }
                }}

            />

            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default InvoicesFilters;