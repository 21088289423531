import { useCallback, useEffect, useState } from 'react';
import { TutorService } from '../../services/tutor/tutorService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage tutors fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de empresas.
----------------------------------------------------------------------------------------*/

const useTutors = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchTutors = useCallback(async () => {
        try {
            const service = new TutorService();
            const response = await service.listTutors({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; lastName: string; }) => ({
                    value: data.id,
                    label: data.name + ' ' + data.lastName,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching Tutors:', error);
        }
    }, []);

    useEffect(() => {
        fetchTutors();
    }, []);

    const updateTutorsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getTutorsList = () => {
        return data;
    };

    return { fetchTutors, getTutorsList, updateTutorsHookFilters };
}

export default useTutors;