import { useState } from 'react';
import { Text } from 'rizzui';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import WidgetCard from '../../../components/card/WidgetCard';
import cn from '../../../utils/classNames';
import DropdownAction from '../../../components/forms/DropdownAction';

const data = [
    { name: 'Ventas', value: 600 },
    { name: 'En curso', value: 250 },
    { name: 'Cancelaciones', value: 150 },
];

const viewOptions = [
    {
        value: 'Diario',
        label: 'Diario',
    },
    {
        value: 'Mensual',
        label: 'Mensual',
    },
];

const valueSum = data.reduce((total, item) => total + item.value, 0);
const calculatePercentage = (part: number, total: number) =>
    ((part / total) * 100).toFixed(2);

const COLORS = ['#8DE3F5', '#59A7FF', '#A5F6C6'];

export default function SalesAnalytics({ className }: { className?: string }) {
    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = (_: unknown, index: number) => {
        setActiveIndex(index);
    };

    function handleChange(viewType: string) {
    }

    return (
        <WidgetCard
            title="Analítica de ventas"
            className={cn('@container', className)}
            headerClassName="mb-6 lg:mb-0"
            action={<DropdownAction options={viewOptions} onChange={handleChange} />}
        >
            <div className="relative mx-auto size-[290px] @sm:size-[340px]">
                <ResponsiveContainer
                    width="100%"
                    height="100%"
                    className="relative z-10"
                >
                    <PieChart>
                        <Pie
                            cx="50%"
                            cy="50%"
                            dataKey="value"
                            innerRadius="42%"
                            outerRadius="70%"
                            fill="#8884d8"
                            paddingAngle={4}
                            data={data}
                            onMouseEnter={onPieEnter}
                            activeIndex={activeIndex}
                            cornerRadius={6}
                            label
                        >
                            {data.map((_, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                    stroke={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>

                <div className="absolute inset-24 flex flex-col items-center justify-center rounded-full bg-white shadow-[0px_4px_20px_0px_#00000029] @sm:inset-28 dark:bg-gray-200">
                    <Text className="text-center text-gray-500">Ventas totales</Text>
                    <Text className="text-xl font-semibold dark:text-white">
                        {valueSum}
                    </Text>
                </div>
            </div>

            <div className='flex justify-center flex-wrap gap-8'>
                {data.map((item, index) => (
                    <div key={item.name}>
                        <div className='flex items-center gap-1'>
                            <span
                                className="me-2 h-2.5 w-3.5 flex-shrink-0"
                                style={{ backgroundColor: COLORS[index] }}
                            />
                            <Text as="span" className="whitespace-nowrap">
                                {item.name}
                            </Text>
                        </div>
                        <Text as="p" className="ms-[26px] font-medium">
                            {calculatePercentage(item.value, valueSum)}%
                        </Text>
                    </div>
                ))}
            </div>
        </WidgetCard>
    );
}