import { Fragment, useContext } from "react";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useNavigate } from "react-router";
import { menuRoutes } from "../../../router/menu";
import cn from "../../../utils/classNames";
import ContractsList from "./ContractList";

const ContractsListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Ventas' },
        { name: 'Gestión de contratos' },
        { name: 'Contratos', href: menuRoutes.contracts.path },
    ];

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de contratos" breadcrumb={breadCrumb}>
                    {userCan('create_contracts', 'contracts') && (
                        <Button
                            color="primary"
                            onClick={() => { navigate(menuRoutes.contracts.create) }}
                            className={cn(['mt-4 md:mt-0'])}
                        >
                            Crear contrato
                        </Button>
                    )}
                </PageHeader>
                <ContractsList />
            </FiltersProvider>
        </Fragment>
    );
}

export default ContractsListWrapper;