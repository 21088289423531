import { Input, Textarea } from "rizzui";
import FormGroup from "../../../../../layout/shared/form-group";
import cn from "../../../../../utils/classNames";
import { Contract } from "../../../../../type/entities/contract-type";
import PhoneInput from "../../../../../components/forms/PhoneInputWrapper";
import { MdMailOutline } from "react-icons/md";

interface ConsultancyContractFormInfoProps {
    data: Contract;
}
const ConsultancyContractFormInfo = ({ data }: ConsultancyContractFormInfoProps) => {
    return (
        <>
            <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
                <div className='flex flex-col md:flex-row md:justify-between items-center'>
                    <h3 className='font-bold text-lg pb-2'>Datos de la Asesoría</h3>
                </div>

                <FormGroup
                    title="Información General"
                    description="Asesoría asociada al contrato."
                    className={cn("py-4 @3xl:grid-cols-12 ")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <Input
                            disabled
                            id='consultancyName'
                            label="Nombre"
                            className="col-span-12 md:col-span-4"
                            value={data.consultancy?.name ?? data.consultancyName}
                        />

                        <Input
                            disabled
                            id='consultancyEmail'
                            type="email"
                            label="Correo Electrónico"
                            prefix={<MdMailOutline />}
                            className="col-span-12 md:col-span-4"
                            value={data.consultancy?.email ?? data.consultancyEmail}
                        />

                        <PhoneInput
                            disabled
                            id='consultancyTelephone'
                            label="Teléfono"
                            value={data.consultancy?.telephone ?? data.consultancyTelephone}
                            className="col-span-12 md:col-span-4"
                            required={false}
                        />

                        <Input
                            disabled
                            id='consultancyContact'
                            label="Persona de Contacto"
                            className="col-span-12 md:col-span-4"
                            value={data.consultancy?.contact ?? data.consultancyContact}
                        />

                        <Textarea
                            disabled
                            id='consultancyDescription'
                            label="Descripción"
                            className="[&>label>span]:font-medium col-span-12 md:col-span-8"
                            value={data.consultancy?.description ?? data.consultancyDescription ?? ''}
                        />
                    </div>
                </FormGroup>
            </div>
        </>
    );
}

export default ConsultancyContractFormInfo;