import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import tutorReducer from './tutorSlice';
import tableReducer from './tableSlice';
import userProfileReducer from './userProfileSlice';
import tutorProfileReducer from './tutorProfileSlice';
import clientProfileReducer from './clientProfileSlice';
import invoiceProfileReducer from './invoiceProfileSlice';
import studentReducer from './studentSlice';
import contractProfileReducer from './contractProfileSlice';
import { loadState, saveState } from './browser-storage';
import { debounce } from "../helpers/helpers";
import statesSlice from './statesSlice';
import settingsSlice from './settingsSlice';

const store = configureStore({
  reducer: {
    //@ts-ignore
    auth: authReducer,
    tutor: tutorReducer,
    table: tableReducer,
    userProfile: userProfileReducer,
    tutorProfile: tutorProfileReducer,
    clientProfile: clientProfileReducer,
    student: studentReducer,
    contractProfile: contractProfileReducer,
    states: statesSlice,
    settings: settingsSlice,
    invoiceProfile: invoiceProfileReducer,
  },
  preloadedState: loadState(),
});

// here we subscribe to the store changes
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveState(store.getState());
  }, 800));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;