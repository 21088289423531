import React from 'react';
import { Outlet } from 'react-router-dom';

interface GeneralConfigurationWrapperProps { }

const GeneralConfigurationWrapper: React.FC<GeneralConfigurationWrapperProps> = ({ }) => {
    return (
        <div className="relative flex flex-grow flex-col px-4 pb-0 md:px-5 lg:px-6 lg:pb-8 3xl:px-8  3xl:pt-4 4xl:px-10">
            <Outlet />
        </div>
    );
};

export default GeneralConfigurationWrapper;