import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import AttivaCard from "../../../components/card/AttivaCard";
import { toast } from "sonner";
import useFetch from "../../../hooks/useFetch";
import { ClientService } from "../../../services/client/clientService";
import { Client } from "../../../type/entities/client-type";
import ClientForm from "../ClientForm";
import { Loader } from "../../../components/loader/SpinnerLogo";
import useHandleErrors from "../../../hooks/useHandleErrors";

export default function ClientEdit() {

    const { id = "" } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const service = new ClientService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getClientById(id as string);
        return response.getResponseData() as Client;
    }, [id]));

    const pageHeader = {
        title: `Perfil de ${data?.companyName}`,
        breadcrumb: [
            { name: 'Ventas' },
            { name: 'Gestión de clientes' },
            { name: 'Clientes', href: menuRoutes.clients.path },
            { name: 'Editar Cliente' },
        ],
    };

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (await service.editClient(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success("Cliente editado correctamente");
                }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al editar el cliente');
        } finally {
            setIsLoading(false);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales">
                {(data !== null && data !== undefined) && <ClientForm data={data} submit={handleSubmit} isLoading={isLoading} />}
            </AttivaCard>
        </>
    );
}