import { useEffect, useState } from 'react';
import { usePrivilege } from '../../components/priviledge/PriviledgeProvider';
import { UserService } from '../../services/user/userService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage roles fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de roles.
----------------------------------------------------------------------------------------*/

const useRolesByUser = (initialFilters = {}) => {

    const { userCan } = usePrivilege();
    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchData = async () => {
        if (!userCan('edit_user', 'user')) return;
        try {
            const service = new UserService();
            const response = await service.listRolesByUser(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappeddata = fetchedData.data?.map((data: { id: string; name: string; }) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappeddata);
            }
        } catch (error) {
            console.log('Error fetching role by user:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filters]);

    const getRolesList = () => {
        return data;
    };

    return { fetchData, getRolesList };
}

export default useRolesByUser;