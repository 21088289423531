import React from 'react';
import { FilterOptions } from '../../../hooks/useFilters';
import CustomSelect from '../../../components/forms/CustomSelect';
import { Button } from 'rizzui';
import { PiTrashDuotone } from 'react-icons/pi';

interface StudentsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const StudentsFilters: React.FC<StudentsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {
    return (
        <>
            <CustomSelect
                isSearchable
                id={'active'}
                label="Estado"
                options={[
                    { label: 'Todos', value: null },
                    { label: 'Activos', value: true },
                    { label: 'Desactivados', value: false }
                ]}
                onChange={(e: any) => {
                    if (e.value === null) {
                        updateFilters({ active: null });
                        return;
                    }
                    updateFilters({ active: e.value });
                }}
                value={filters.filter_filters?.active !== null
                    ? { label: filters.filter_filters?.active ? 'Activo' : 'Desactivado', value: filters.filter_filters?.active }
                    : { label: 'Todos', value: null }}
            />

            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default StudentsFilters;