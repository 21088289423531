import { Modal } from "rizzui";
import { useSettingsProvider } from "./provider/SettingsProvider";
import { IoWarningOutline } from "react-icons/io5";

interface SettignsComponentsProps {
    modalTitle?: string;
};

const SettingsComponents: React.FC<SettignsComponentsProps> = ({ modalTitle = '¿Quieres actualizar los ajustes?' }) => {

    const {showModal, setShowModal, applyCompanyConfigChanges} = useSettingsProvider();

    const handleConfirm = () => {
        setShowModal(false);
        applyCompanyConfigChanges();
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <>
            {showModal && (
                <Modal isOpen={showModal} onClose={() => setShowModal(false)} size={'md'}>
                    <div className='flex flex-col justify-center pt-5 pb-4'>
                        <IoWarningOutline className="text-5xl text-yellow-500 m-auto" />
                        <h4 className="text-2xl text-center">{modalTitle}</h4>
                    </div>
                    <p className="text-justify text-gray-500 text-sm mx-8 px-4 pb-6">
                        Al confirmar, los cambios realizados en la configuración de la empresa se guardarán. Y se aplicarán en los proximos procesos.
                    </p>
                    <div className="flex justify-center p-3 gap-4">
                        <button className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-300" onClick={handleConfirm}>
                            Confirmar
                        </button>
                        <button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={handleCancel}>
                            Cancelar
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );

}

export default SettingsComponents;