import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import FormGroup from '../../../../../layout/shared/form-group';
import InvoiceProfileLayout from '../../InvoiceProfileLayout';
import { InvoiceService } from '../../../../../services/invoice/invoiceService';
import { InvoiceApiResponse, InvoiceLine } from '../../../../../type/entities/invoice-type';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import { FaEuroSign, FaPercent } from 'react-icons/fa';
import useInvoiceCorporations from '../../../../../hooks/api-calls/useInvoiceCorporations';
import useInvoiceTutors from '../../../../../hooks/api-calls/useInvoiceTutors';
import useInvoiceClients from '../../../../../hooks/api-calls/useInvoiceClients';
import classNames from 'classnames';
import cn from '../../../../../utils/classNames';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { fixNumber } from '../../../../../utils/formatNumber';
import { Input } from 'rizzui';
import moment from 'moment';

interface InvoiceInfoProps { }

const InvoiceInfo: React.FC<InvoiceInfoProps> = () => {

    const { user } = useSelector((state: RootState) => state.auth);
    const { id = '' } = useParams<{ id: string }>();
    const service = new InvoiceService();

    const { getInvoiceCorporationsList } = useInvoiceCorporations({ active: true });
    const { getInvoiceTutorsList } = useInvoiceTutors({ active: true });
    const { getInvoiceClientsList } = useInvoiceClients({ active: true });

    const [data, loading] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getInvoiceById(id);
        return response.getResponseData() as InvoiceApiResponse;
    }, [id]));

    const [contractsList] = useFetch(useCallback(async () => {
        const response = await (new InvoiceService).invoicableContractsList(user?.id as string);
        return response.getResponseData();
    }, [user]));

    return (
        <InvoiceProfileLayout isLoading={loading}>
            <div className="@container">
                <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200">
                    <FormGroup
                        title="Información General"
                        description="Datos principales de la factura"
                        className='pt-3 pb-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-5"
                    >
                        <Input
                            disabled
                            id='taxableAmount'
                            type="number"
                            label="Base Imponible"
                            suffix={<FaEuroSign />}
                            value={fixNumber(data?.taxableAmount)}
                        />

                        <Input
                            disabled
                            id='tax'
                            type="number"
                            label="Impuesto"
                            suffix={<FaPercent />}
                            value={data?.tax}
                        />

                        <Input
                            disabled
                            id='totalTaxesAmount'
                            type="number"
                            label="Total Impuestos"
                            suffix={<FaEuroSign />}
                            value={fixNumber(data?.totalTaxesAmount)}
                        />

                        <Input
                            disabled
                            id='totalProducts'
                            type="number"
                            label="Total Productos"
                            value={data?.totalProducts}
                        />

                        <Input
                            disabled
                            id='totalPrice'
                            type="number"
                            label="Precio Total"
                            suffix={<FaEuroSign />}
                            value={fixNumber(data?.totalPrice)}
                        />
                    </FormGroup>

                    <FormGroup
                        title="Empresas y Usuarios"
                        description="Datos de las empresas y usuarios relacionados"
                        className='pt-3 pb-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-3"
                    >
                        <CustomSelect
                            isDisabled={true}
                            isSearchable
                            id={'corporationId'}
                            label="Empresa"
                            value={{ value: data?.corporation?.id, label: getInvoiceCorporationsList()?.find((g: any) => g.value === data?.corporation?.id)?.label }}
                            options={getInvoiceCorporationsList()}
                        />

                        <CustomSelect
                            isDisabled={true}
                            isSearchable
                            id={'tutorId'}
                            label="Tutor"
                            value={{ value: data?.tutor?.id, label: getInvoiceTutorsList()?.find((g: any) => g.value === data?.tutor?.id)?.label }}
                            options={getInvoiceTutorsList()}
                        />

                        <CustomSelect
                            isDisabled={true}
                            isSearchable
                            id={'clientId'}
                            label="Cliente"
                            value={{ value: data?.client?.id, label: getInvoiceClientsList()?.find((g: any) => g.value === data?.client?.id)?.label }}
                            options={getInvoiceClientsList()}
                        />
                    </FormGroup>

                    <FormGroup
                        title="Fechas y Plazos"
                        description="Fechas de la factura y plazos de pago"
                        className='pt-3 pb-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-3"
                    >
                        <Input
                            disabled
                            id='invoiceDate'
                            type="date"
                            label="Fecha de Factura"
                            value={moment(data?.invoiceDate?.date).format('YYYY-MM-DD')}
                        />

                        <Input
                            disabled
                            id='paymentDate'
                            type="date"
                            label="Fecha de Pago"
                            value={moment(data?.paymentDate?.date).format('YYYY-MM-DD')}
                        />

                        <Input
                            disabled
                            id='expirationDate'
                            type="date"
                            label="Fecha de Vencimiento"
                            value={moment(data?.expirationDate?.date).format('YYYY-MM-DD')}
                        />

                    </FormGroup>

                    <FormGroup
                        title="Líneas de Factura"
                        description="Líneas de la factura"
                        className='mt-3 pt-6 pb-4 border-top border-t-2 border-dashed hidden'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10"
                        childClassName={classNames('divide-y-2 divide-dashed pb-4', { 'border-gray-500': data?.lines?.length > 0 })}
                    >
                        <div className={classNames('divide-y-2 divide-dashed pb-4', { 'border-gray-500': data?.lines?.length > 0 })}>
                            {data?.lines?.map((item: InvoiceLine, index: number) => {
                                return (
                                    <div key={index} className="flex flex-col md:flex-row flex-nowrap gap-4 mb-5 pt-5">
                                        <div className="flex flex-row flex-wrap gap-3">
                                            <CustomSelect
                                                isDisabled={true}
                                                required
                                                id={`lines[${index}].contract`}
                                                isClearable
                                                label='Contrato'
                                                options={contractsList?.map((contract: any) => ({ value: contract.id, label: `${contract.code} - ${contract.clientCompanyName}` }))}
                                                value={{ value: item.contract, label: item.contract && `${contractsList?.find((contract: any) => contract.id === item.contract)?.code} - ${contractsList?.find((contract: any) => contract.id === item.contract)?.clientCompanyName}` }}
                                                containerClassName='w-[300px]'
                                            />
                                            <Input
                                                disabled
                                                required
                                                id={`lines[${index}].concept`}
                                                type="text"
                                                label="Concepto"
                                                className={cn('[&>label>span]:font-medium w-[300px]', { 'hidden': data?.lines?.some((l: any) => ((l.contract === item.contract)) && (l.contract !== null)) })}
                                                value={item.concept}
                                            />
                                            <Input
                                                disabled
                                                required
                                                id={`lines[${index}].amount`}
                                                type="number"
                                                min={0}
                                                label="Cantidad"
                                                className={cn('[&>label>span]:font-medium w-[100px]', { 'hidden': data?.lines?.some((l: any) => ((l.contract === item.contract)) && (l.contract !== null)) })}
                                                inputClassName="text-sm"
                                                value={item.amount}
                                                suffix={<FaEuroSign />}
                                            />
                                            <Input
                                                disabled
                                                required
                                                id={`lines[${index}].units`}
                                                type="number"
                                                min={0}
                                                step={1}
                                                label="Unidades"
                                                className={cn('[&>label>span]:font-medium w-[100px]', { 'hidden': data?.lines?.some((l: any) => ((l.contract === item.contract)) && (l.contract !== null)) })}
                                                inputClassName="text-sm"
                                                value={item.units}
                                            />
                                            <Input
                                                required
                                                id={`lines[${index}].totalWithoutTax`}
                                                type="number"
                                                label="Total sin IVA"
                                                className={cn('[&>label>span]:font-medium w-[130px]', { 'hidden': data?.lines?.some((l: any) => ((l.contract === item.contract)) && (l.contract !== null)) })}
                                                inputClassName="text-sm"
                                                value={item.totalWithoutTax}
                                                suffix={<FaEuroSign />}
                                            />
                                            <Input
                                                required
                                                id={`lines[${index}].totalWithTax`}
                                                type="number"
                                                min={0}
                                                step={0.01}
                                                label="Total con IVA"
                                                className={cn('[&>label>span]:font-medium w-[130px]', { 'hidden': data?.lines?.some((l: any) => ((l.contract === item.contract)) && (l.contract !== null)) })}
                                                inputClassName="text-sm"
                                                value={item.totalWithTax}
                                                suffix={<FaEuroSign />}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </FormGroup>

                </div>
            </div>
        </InvoiceProfileLayout>
    );
};

export default InvoiceInfo;