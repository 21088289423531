
import { InputProps } from "rizzui";
import { DatePicker } from "./DatePicker";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import SelectReact from "react-select";
import useContractStates from "../../hooks/api-calls/contract/useContractStates";
import useContractProductsStates from "../../hooks/api-calls/contract/useContractProductsStates";
import useContractInvoiceStates from "../../hooks/api-calls/contract/useContractInvoicingStates";
import { debounce } from "lodash";
import { CustomBadge } from "./CustomBadge";
import chroma from "chroma-js";

export const stateSearchOptions = [
    { value: 1, label: 'Estado actual' },
    { value: 0, label: 'Historial de estados' }
];

export const statesEntitiesOptions = [
    { value: "contract", label: "Contrato" },
    { value: "contract_product", label: "Producto del contrato" },
    { value: "contract_invoicing", label: "Facturación del contrato" }
    // { value: "contract_incidence", label: "Incidencia del contrato" }
];

export interface CustomStatusSelectProps {
    onChange: (value: any) => void;
    id?: string;
    label?: string;
    selectedStateSearchValue?: any;
    selectedStateEntityValues?: any;
    selectedStatesValues?: any;
    startDate?: any;
    endDate?: any;
    inputProps?: InputProps;
    filterValue?: any;
    // STATE ENTITY SELECTOR
    isStateEntityClearable?: boolean;
    // STATE SEARCH SELECTOR
    isStateSearchClearable?: boolean;
    // STATES SELECTOR
    isStatesClearable?: boolean;
};

export default function CustomStatusSelect({
    label = 'Estados',
    inputProps,
    filterValue,
    // STATE ENTITY SELECTOR
    selectedStateEntityValues = null,
    isStateEntityClearable = true,
    // STATE SEARCH SELECTOR
    selectedStateSearchValue = 1,
    isStateSearchClearable = true,
    // STATES SELECTOR
    selectedStatesValues = null,
    isStatesClearable = true,
    ...props
}: CustomStatusSelectProps) {

    const [statesOptions, setStatesOptions] = useState<any>([]);
    const { getContractStatesList } = useContractStates({action: 'view'});
    const { getContractProductsStatesList } = useContractProductsStates({action: 'view'});
    const { getContractInvoiceStatesList } = useContractInvoiceStates({action: 'view'});

    const [selectedStateSearch, setSelectedStateSearch] = useState<any>(selectedStateSearchValue);
    const [selectedStatesEntity, setSelectedStatesEntity] = useState<any>(selectedStateEntityValues);
    const [selectedStates, setSelectedStates] = useState<any>(selectedStatesValues);
    const [customStatus, setCustomStatus] = useState<any>({ startDate: props.startDate, endDate: props.endDate });

    //////////////////////////////////////////////////
    // SET INITIAL VALUES
    //////////////////////////////////////////////////
    useEffect(() => {
        if (filterValue === null || filterValue === undefined) {
            setSelectedStateSearch(null);
            setSelectedStatesEntity(null);
            setSelectedStates(null);
            setCustomStatus({ startDate: null, endDate: null });
        }
    }, [filterValue])

    const debouncedOnChange = useCallback(
        debounce((data) => {
            props.onChange(data);
        }, 1500), // 1000 ms delay
        [props.onChange]
    );

    //////////////////////////////////////////////////
    // SEND DATA TO PARENT COMPONENT TROUGHT ONCHANGE
    //////////////////////////////////////////////////
    useEffect(() => {
        const shouldUpdate =
            (selectedStateSearch === 0 &&
                customStatus.startDate !== null &&
                customStatus.endDate !== null &&
                selectedStatesEntity !== null &&
                selectedStates?.length >= 1) ||
            (selectedStateSearch !== 0 &&
                selectedStatesEntity !== null &&
                selectedStates?.length >= 1);

        if (shouldUpdate) {
            const data = {
                startDate: selectedStateSearch === 0 && customStatus.startDate && customStatus.endDate
                    ? moment(customStatus.startDate).format('YYYY-MM-DD')
                    : null,
                endDate: selectedStateSearch === 0 && customStatus.startDate && customStatus.endDate
                    ? moment(customStatus.endDate).format('YYYY-MM-DD')
                    : null,
                current: selectedStateSearch ?? 1,
                entity: selectedStatesEntity,
                statuses: selectedStates,
            };
            debouncedOnChange(data);
        }

        if (!selectedStates && filterValue != null) {
            debouncedOnChange(null);
        }
    }, [customStatus, selectedStateSearch, selectedStatesEntity, selectedStates]);


    //////////////////////////////////////////////////
    // UPDATE STATES OPTIONS ACCORDING TO ENTITY SELECTED
    //////////////////////////////////////////////////
    useEffect(() => {
        if (selectedStatesEntity) {
            switch (selectedStatesEntity) {
                case 'contract':
                    if (statesOptions !== getContractStatesList()) setStatesOptions(getContractStatesList());
                    break;
                case 'contract_product':
                    if (statesOptions !== getContractProductsStatesList()) setStatesOptions(getContractProductsStatesList());
                    break;
                case 'contract_invoicing':
                    if (statesOptions !== getContractInvoiceStatesList()) setStatesOptions(getContractInvoiceStatesList());
                    break;
                // TODO: case 'contract_incidence':
            }
        }
    }, [selectedStatesEntity, selectedStateEntityValues, getContractStatesList, getContractProductsStatesList, getContractInvoiceStatesList]);

    function renderDisplayValue(option: any) {
        return (
            <CustomBadge
                className="cursor-pointer w-full p-1.5"
                rounded={'md'}
                size={'lg'}
                customColor={option?.color || 'primary'}
            >
                <div className="flex flex-col justify-center items-center text-nowrap leading-relaxed">
                    {option.label} <br />
                </div>
            </CustomBadge>
        );
    };

    return (
        <div>
            <span className="rizzui-input-label block text-sm mb-1.5 font-medium">{label}</span>
            <SelectReact
                options={stateSearchOptions}
                onChange={(option: any) => setSelectedStateSearch(option.value)}
                value={stateSearchOptions.find((option: any) => option.value === (selectedStateSearch ?? 1) ) }
                className="mb-4"
                placeholder={`Elige el tipo de busqueda...`}
                styles={{
                    control: (base: any, state: any) => ({
                        ...base,
                        border: '1px !important',
                        borderRadius: '0.375rem',
                        borderColor: 'rgb(229 231 235) !important',
                        boxShadow: state.isFocused ? '0 0 0 2px rgb(0, 172, 216)' : '0 0 0 1px rgba(0,0,0, 0.2) !important',
                    }),
                    option: (provided: any, state: any) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? 'rgb(0, 172, 216)' : 'white',
                        color: state.isFocused ? 'white' : 'black',
                        '&:hover': {
                            backgroundColor: 'rgb(0, 172, 216)',
                            color: 'white',
                            borderColor: '#000000 !important'
                        }
                    }),
                }}
            />

            <SelectReact
                isMulti={false}
                options={statesEntitiesOptions}
                onChange={(option: any) => setSelectedStatesEntity(option.value)}
                value={statesEntitiesOptions.filter((option: any) => option.value === selectedStatesEntity && selectedStatesEntity !== null)}
                className="mb-4"
                placeholder={`Elige la entidad...`}
                noOptionsMessage={() => 'Sin Opciones'}
                styles={{
                    control: (base: any, state: any) => ({
                        ...base,
                        border: '1px !important',
                        borderRadius: '0.375rem',
                        borderColor: 'rgb(229 231 235) !important',
                        boxShadow: state.isFocused ? '0 0 0 2px rgb(0, 172, 216)' : '0 0 0 1px rgba(0,0,0, 0.2) !important',
                    }),
                    option: (provided: any, state: any) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? 'rgb(0, 172, 216)' : 'white',
                        color: state.isFocused ? 'white' : 'black',
                        '&:hover': {
                            backgroundColor: 'rgb(0, 172, 216)',
                            color: 'white',
                            borderColor: '#000000 !important'
                        }
                    }),
                }}
            />

            <SelectReact
                isMulti={true}
                options={statesOptions}
                onChange={(data: any) => setSelectedStates(data.map((element: any) => element.value))}
                value={statesOptions.filter((element: any) => selectedStates?.includes(element.value))}
                className="mb-4"
                placeholder={`Elige los estados...`}
                noOptionsMessage={() => 'Sin Opciones'}
                formatOptionLabel={renderDisplayValue}
                styles={{
                    control: (base: any, state: any) => ({
                        ...base,
                        border: '1px !important',
                        borderRadius: '0.375rem',
                        borderColor: 'rgb(229 231 235) !important',
                        boxShadow: state.isFocused ? '0 0 0 2px rgb(0, 172, 216)' : '0 0 0 1px rgba(0,0,0, 0.2) !important',
                    }),
                    option: (provided: any, state: any) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? 'rgb(0, 172, 216)' : 'white',
                        color: state.isFocused ? 'white' : 'black',
                        '&:hover': {
                            backgroundColor: 'rgb(0, 172, 216)',
                            color: 'white',
                            borderColor: '#000000 !important'
                        }
                    }),
                    multiValueLabel: (styles: any, { data }: any) => {
                        // @ts-ignore
                        const backgroundColor = chroma(data.color).tint(0.25).hex() || '#E6E5E5';
                        let textColor = 'black';
                        if (chroma.valid(backgroundColor)) {
                            textColor = chroma.contrast(backgroundColor, 'white') > 4.5 ? 'white' : 'black';
                        }

                        return {
                            ...styles,
                            backgroundColor,
                            color: textColor,
                        };
                    },
                }}
            />

            {selectedStateSearch === 0 && (
                <DatePicker
                    isClearable
                    id={props.id}
                    monthsShown={1}
                    placeholderText={'Elige las fechas...'}
                    selectsRange
                    inputProps={{
                        inputClassName: 'h-9 [&_input]:text-ellipsis w-full',
                    }}
                    className="w-full"
                    startDate={customStatus.startDate}
                    endDate={customStatus.endDate}
                    onChange={(date: any) => {
                        setCustomStatus({
                            startDate: date[0] ? date[0] : null,
                            endDate: date[1] ? date[1] : null,
                        });
                    }}
                    onClear={() => { setCustomStatus({ startDate: null, endDate: null }); }}
                />
            )}
        </div>
    );
}