import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Consultancy } from '../../type/entities/consultancy-type';
import { useFormik } from 'formik';
import Page from '../../layout/Page/Page';
import FormFooter from '../_layout/_footers/form-footer';
import FormGroup from '../../layout/shared/form-group';
import RequiredInput from '../../components/forms/InputHOC';
import { Textarea } from 'rizzui';
import PhoneInput from '../../components/forms/PhoneInputWrapper';

interface ConsultancyFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Consultancy | undefined;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    email: yup.string().email('Correo inválido').required('Campo obligatorio'),
    contact: yup.string().nullable(),
    telephone: yup.string().nullable(),
    description: yup.string().nullable(),
});

const ConsultancyForm: React.FC<ConsultancyFormProps> = ({ isLoading, submit, data }) => {

    const mode = data ? 'Editar' : 'Crear';
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            consultancyId: data?.id ?? '',
            name: data?.name ?? '',
            email: data?.email ?? '',
            contact: data?.contact ?? '',
            telephone: data?.telephone ?? '',
            description: data?.description ?? '',
        },
        validationSchema: schema,
        validateOnBlur: false,
        //validateOnChange: false,
        onSubmit: values => { submit(values) },
    });

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">
                        <FormGroup
                            title="Información General"
                            description="Datos principales de la asesoría."
                            className='pt-3 pb-4'
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-3"
                        >
                            <RequiredInput
                                id='name'
                                type="text"
                                label="Nombre"
                                formik={formik}
                            />

                            <RequiredInput
                                id='email'
                                type="email"
                                label="Correo Electrónico"
                                formik={formik}
                            />

                            <RequiredInput
                                id='contact'
                                type="text"
                                label="Persona de Contacto"
                                formik={formik}
                                required={false}
                            />

                            <PhoneInput
                                id='telephone'
                                label="Teléfono"
                                onChange={e => { formik.setFieldValue('telephone', e) }}
                                value={formik.values.telephone}
                                error={formik.errors.telephone}
                                required={false}
                            />

                            <Textarea
                                id='description'
                                label="Descripción"
                                className="[&>label>span]:font-medium md:col-span-2"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                error={formik.errors.description}
                            />
                        </FormGroup>
                    </div>
                </form>
            </div>
        )
    };

    return (
        <Fragment>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Asesoría'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm} isLoading={isLoading}
            />
        </Fragment>
    );
};

export default ConsultancyForm;