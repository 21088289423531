import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/contract-group';

export class ContractGroupService extends RestServiceConnection {

    listContractGroups = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getContractGroupById = async (contractGroupId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { contractGroupId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createContractGroup = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: entity,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    editContractGroup = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: entity,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    deleteContractGroup = async (contractGroupId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { contractGroupId },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getContractGroupList = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-list',
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

};